// import { ErrorWrap } from 'app/components';
import ErrorBoundryScreen from 'app/components/ErrorBoundry/ErrorBoundryScreen';
import React, { Component } from 'react';

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hasError: false,
        };
    }

    static getDerivedStateFromError(error) {
        // Update state to show an error occurred
        return { hasError: true };
    }

    render() {
        if (this.state.hasError) {
            return <ErrorBoundryScreen />; // Fallback UI
        }

        return this.props.children; // Render children as-is
    }
}

export default ErrorBoundary;
