import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import ExitToApp from '@material-ui/icons/ExitToApp';
import { Menu, createTheme, Hidden, ListItem, ListItemIcon } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import lightBlue from '@material-ui/core/colors/lightBlue';

import { ThemeProvider } from '@material-ui/styles';
import { modifyDate } from '../../redux/actions/DateAction';
import './style.scss';

import ASlogo from './../../../assets/images/asraLogo3.png';
import LOGO from './../../../assets/images/cattle-mandi/mandiLogoMain.webp';
import { NavLink } from 'react-router-dom';
import { logout } from 'app/redux/actions/loginAction';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import { showNflBrand } from 'app/helpers';

export const materialTheme = createTheme({
    overrides: {
        MuiPickersToolbar: {
            toolbar: {
                backgroundColor: '#7904d7',
            },
        },
        MuiPickersCalendarHeader: {
            switchHeader: {
                color: 'white',
            },
        },
        MuiPickersDay: {
            day: {
                color: '#7904d7',
            },
            daySelected: {
                backgroundColor: '#7904d7',
            },
            dayDisabled: {
                color: '#e0dcfe',
            },
            current: {
                color: '#7904d7',
            },
        },
        MuiPickersModal: {
            dialogAction: {
                color: 'red',
            },
        },
        MuiInputLabel: {
            root: {
                '&.Mui-focused': {
                    color: '#fff',
                },
            },
            formControl: {
                color: '#fff',
            },
        },
        MuiInputBase: {
            root: {
                color: '#fff',
            },
        },
        MuiInput: {
            underline: {
                borderBottom: '1px solid #fff',
                '&:before': {
                    borderBottom: '1px solid #fff',
                },
                '&:after': {
                    borderBottom: '1px solid #fff',
                    color: '#fff',
                },
            },
        },
    },
});

const UserMenu = () => {
    const start = useSelector((state) => state.getIn(['date', 'startDate']));
    const end = useSelector((state) => state.getIn(['date', 'endDate']));
    const dispatch = useDispatch();
    const [startDate, setStartDate] = useState(start);
    const [endDate, setEndDate] = useState(end);
    const [anchorEl, setAnchorEl] = useState(null);
    const [openMenu, setOpenMenu] = useState(null);
    const [open, setOpen] = useState({ shown: false, nameOfPicker: '' });

    const handleMenu = (menu) => (event) => {
        setOpenMenu(openMenu === menu ? null : menu);
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setOpenMenu(null);
    };

    const handleStartDateChange = (date) => {
        setStartDate(date);
    };

    const handleEndDateChange = (date) => {
        setEndDate(date);
    };

    const handleLogOut = (data) => {
        localStorage.clear();
        dispatch(logout(data));
    };

    const handleApplyDates = () => {
        dispatch(modifyDate({ startDate, endDate }));
    };

    const handleClosePicker = () => {
        setOpen({ shown: false, numOfPicker: '' });
    };

    useEffect(() => {
        setStartDate(start);
        setEndDate(end);
    }, [start, end]);

    return (
        <div className="UserMenu__container">
            <Hidden only={['sm', 'xs']}>
                <div className={'dateContainer'}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <ThemeProvider theme={materialTheme}>
                            <DatePicker
                                placeholder="From"
                                variant="inline"
                                views={['year', 'month', 'date']}
                                maxDate={new Date()}
                                // open={open?.shown && open.nameOfPicker === 'from'}
                                // onAccept={handleClosePicker}
                                className={'date'}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                            // onClick={() => {
                                            //     setOpen({ shown: true, nameOfPicker: 'from' });
                                            // }}
                                            >
                                                <CalendarTodayIcon className="calender-icon" />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                onChange={handleStartDateChange}
                                value={startDate}
                            />
                        </ThemeProvider>
                    </MuiPickersUtilsProvider>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <ThemeProvider theme={materialTheme}>
                            <DatePicker
                                placeholder="To"
                                variant="inline"
                                views={['year', 'month', 'date']}
                                maxDate={new Date()}
                                className={'date'}
                                // open={open?.shown && open.nameOfPicker === 'to'}
                                // onAccept={handleClosePicker}
                                onChange={handleEndDateChange}
                                value={endDate}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                            // onClick={() => {
                                            //     setOpen({ shown: true, nameOfPicker: 'to' });
                                            // }}
                                            >
                                                <CalendarTodayIcon className="calender-icon" />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </ThemeProvider>
                    </MuiPickersUtilsProvider>
                    <Button variant="contained" onClick={handleApplyDates} color="primary" className="search-btn">
                        <SearchIcon className="search-img" /> Search
                    </Button>
                </div>
            </Hidden>

            <div onClick={handleMenu('user-setting')} className="AS-logo-container">
                <img src={showNflBrand ? LOGO : ASlogo} alt="logo" className="AS-logo" />
            </div>

            <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={openMenu === 'user-setting'}
                onClose={handleClose}
            >
                <ListItem component={NavLink} to={'/'} onClick={handleLogOut}>
                    <ListItemIcon>
                        <ExitToApp />
                    </ListItemIcon>
                    Log Out
                </ListItem>
            </Menu>
        </div>
    );
};

export default UserMenu;
