import { brandName } from 'app/helpers';

export const brand = {
    name: brandName,
    desc: 'National Foods',
    prefix: 'boss',
    footerText: 'National Foods All Rights Reserved 2018',
    logoText: 'National Foods',
    needLogin: false,
};

// export const name = showNflBrand ? 'NFL' : 'Asra Soft';
// export const desc = 'National Foods';
// export const prefix = 'boss';
// export const footerText = 'National Foods All Rights Reserved 2018';
// export const logoText = 'National Foods';
// export const needLogin = false;
