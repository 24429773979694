import React, { useRef, useState } from 'react';
import { FormDialog } from '../UiElements';
import { IconButton } from '@material-ui/core';
import PublishIcon from '@material-ui/icons/Publish';
import ButtonGroup from '../ButtonGroup/ButtonGroup';
import Input from '../Input/Input';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { axiosInstance, errorHandler } from 'app/helpers';
import { setAlert } from 'app/redux/actions/alertAction';
import { setIncreament } from 'app/redux/actions/actionIncreament';

const Deposits = ({ open, handleClose, setViewOpenDeposit }) => {
    const fileInputRef = useRef(null);
    const [isLoad, setIsLoad] = useState();
    const dispatch = useDispatch();

    const formik = useFormik({
        initialValues: {
            deposited_by: '',
            deposited_amount: 0,
            date: '',
            ref_number: '',
            image: null,
        },
        validationSchema: Yup.object({
            deposited_by: Yup.string().required('required'),
            deposited_amount: Yup.string().required('required'),
            date: Yup.string().required('required'),
            ref_number: Yup.string().required('required'),
            image: Yup.mixed().required('Invoice is required'),
        }),
        onSubmit: async (values) => {
            const formData = new FormData();
            formData.append('deposited_by', values.deposited_by);
            formData.append('deposited_amount', values.deposited_amount);
            formData.append('ref_number', values.ref_number);
            formData.append('date', values.date);
            formData.append('image', values.image);
            setIsLoad(true);
            try {
                await axiosInstance.post('/finance/post-deposit', formData);
                setIsLoad(false);
                formik.resetForm();
                dispatch(
                    setAlert({
                        isOpen: true,
                        type: 'success',
                        message: 'Data Submited Successfully',
                    })
                );
                dispatch(setIncreament());
                setViewOpenDeposit(false);
            } catch (error) {
                setIsLoad(false);
                dispatch(
                    setAlert({
                        isOpen: true,
                        type: 'error',
                        message: errorHandler(error),
                    })
                );
            }
        },
    });

    const handleIconClick = () => {
        if (fileInputRef) {
            fileInputRef.current.click();
        }
    };

    const handleFileChange = (event) => {
        formik.setFieldValue('image', event.currentTarget.files[0]);
    };

    return (
        <FormDialog open={open} handleClose={handleClose} title="Add Deposit">
            <div className="Create_Deposit">
                <form onSubmit={formik.handleSubmit}>
                    <div className="Create_Deposit_item_Container_One">
                        <div className="Create_Deposit_Item">
                            <Input
                                type="text"
                                name="deposited_by"
                                label="Deposit By"
                                value={formik.values.deposited_by}
                                onChange={formik.handleChange}
                                size="small"
                            />
                            {formik.touched.deposited_by && formik.errors.deposited_by && (
                                <div className="error">{formik.errors.deposited_by}</div>
                            )}
                        </div>
                        <div className="Create_Deposit_Item">
                            <Input
                                type="number"
                                name="deposited_amount"
                                label="Deposit Amount"
                                value={formik.values.deposited_amount}
                                onChange={formik.handleChange}
                                size="small"
                            />
                            {formik.touched.deposited_amount && formik.errors.deposited_amount && (
                                <div className="error">{formik.errors.deposited_amount}</div>
                            )}
                        </div>
                    </div>
                    <div className="Create_Deposit_item_Container_Two">
                        <div className="Create_Deposit_Item">
                            <Input
                                type="date"
                                name="date"
                                value={formik.values.date}
                                onChange={formik.handleChange}
                                size="small"
                            />
                            {formik.touched.date && formik.errors.date && (
                                <div className="error">{formik.errors.date}</div>
                            )}
                        </div>
                        <div className="Create_Deposit_Item">
                            <Input
                                type="text"
                                name="ref_number"
                                label="Ref Number"
                                value={formik.values.ref_number}
                                onChange={formik.handleChange}
                                size="small"
                            />
                            {formik.touched.ref_number && formik.errors.ref_number && (
                                <div className="error">{formik.errors.ref_number}</div>
                            )}
                        </div>
                    </div>
                    <div className="Create_Deposit_Item">
                        <span className="uploadTxt">
                            {formik.values.image !== null ? formik.values.image.name : 'Upload Invoice'}
                        </span>
                        <IconButton>
                            <PublishIcon onClick={handleIconClick} />
                            <input
                                type="file"
                                name="image"
                                style={{ display: 'none' }}
                                ref={fileInputRef}
                                onChange={handleFileChange}
                                accept="image/*"
                            />
                        </IconButton>
                        {formik.touched.image && formik.errors.image && (
                            <div className="error">{formik.errors.image}</div>
                        )}
                    </div>
                    <ButtonGroup
                        type="submit"
                        value="Submit"
                        variant="contained"
                        color="primary"
                        isLoad={isLoad}
                        disabled={isLoad}
                    />
                </form>
            </div>
        </FormDialog>
    );
};

export default Deposits;
