import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import L from 'leaflet';
import useWebSocket from 'react-use-websocket';

import 'leaflet/dist/leaflet.css';

const LiveTrackingMap = ({ showAll = false }) => {
    const { userId: selectedUserId } = useParams();
    const [map, setMap] = useState(null);
    const [markers, setMarkers] = useState({});
    const [messageSent, setMessageSent] = useState(false);

    const socketUrl = 'wss://the-direct-sell.onrender.com/websocket';
    const { lastJsonMessage, sendJsonMessage } = useWebSocket(socketUrl, {
        onOpen: () => {
            console.log('WebSocket connected');
            if (!messageSent) {
                sendJsonMessage({ type: 'CLIENT_MESSAGE', data: 'CLIENT_CONNECTED' });
                setMessageSent(true);
            }
        },
        onError: (e) => {
            console.log('WebSocket error:', e);
        },
    });

    useEffect(() => {
        const initializeMap = () => {
            const leafletMap = L.map('map').setView([24.9551, 67.0584], 13);
            L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
                attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
            }).addTo(leafletMap);

            setMap(leafletMap);

            return () => {
                leafletMap.remove();
            };
        };

        initializeMap();
    }, []);

    useEffect(() => {
        // Handle incoming WebSocket messages
        const updateMarkerPosition = (userId, location) => {
            if (!map || !location) return;

            const { latitude, longitude } = location;
            const existingMarker = markers[userId];

            if (existingMarker) {
                existingMarker.setLatLng([latitude, longitude]);
            } else {
                // Create a custom icon for the marker
                const customIcon = L.icon({
                    iconUrl: '/userloc.png', // Replace with the path to your icon
                    iconSize: [42, 42],
                    iconAnchor: [16, 32],
                    popupAnchor: [0, -32],
                });

                // Add the marker with the custom icon to the map
                const newMarker = L.marker([latitude, longitude], { icon: customIcon }).addTo(map);
                setMarkers((prevMarkers) => ({
                    ...prevMarkers,
                    [userId]: newMarker,
                }));
            }
        };

        if (lastJsonMessage) {
            const { userId, location } = lastJsonMessage;
            console.log({ lastJsonMessage, selectedUserId });
            if (showAll || userId == selectedUserId) {
                updateMarkerPosition(userId, location);
            }
        }
    }, [lastJsonMessage, selectedUserId, showAll, map, markers]);

    return (
        <div>
            <div id="map" style={{ height: '500px', width: '100%', margin: 'auto' }}></div>
        </div>
    );
};

export default LiveTrackingMap;
