import React, { useEffect, useState } from 'react';
import { Page, View, Document, StyleSheet } from '@react-pdf/renderer';
import JsBarcode from 'jsbarcode';
import PDF_Helper from './helper';

const styles = StyleSheet.create({
    page: {
        backgroundColor: 'white',
        color: '#000',
        width: '100%',
        padding: 20,
        paddingTop: 5,
        paddingLeft: 2,
        paddingRight: 2,
        display: 'flex',
    },
    main_heading: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 15,
    },
    main_heading_textOne: {
        textTransform: 'capitalize',
        fontSize: 6,
        textDecoration: 'underline',
        fontWeight: 'bold',
    },
    main_heading_textTwo: {
        textTransform: 'uppercase',
        fontSize: 6,
    },
    main_heading_textThree: {
        textTransform: 'capitalize',
        fontSize: 6,
    },
    main: {
        flexDirection: 'column',
        justifyContent: 'space-between',
        // height: '90vh',
    },
    cattleMandi: {
        textTransform: 'capitalize',
        fontSize: 9,
        textDecoration: 'underline',
        textAlign: 'center',
    },
    pdf_first: {
        flexDirection: 'row',
        width: '100%',
        paddingTop: 20,
    },
    image: {
        width: '100%',
        height: '163px',
    },
    pdf_first_right: {
        width: '100%',
    },
    pdf_first_right_heading: {
        fontWeight: '900',
        marginBottom: 3,
        textDecoration: 'underline',
    },
    pdf_first_right_content: {
        padding: '5px 0',
        fontSize: '14px',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    column_line: {
        backgroundColor: '#000',
        width: 1,
        height: '100%',
    },
    table: {
        display: 'table',
        width: 'auto',
        borderStyle: 'solid',
        borderWidth: 1,
        borderRightWidth: 0,
        borderBottomWidth: 0,
    },
    tableRow: {
        flexDirection: 'row',
    },
    tableCol: {
        width: '33.33%',
        borderStyle: 'solid',
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
    },
    tableColHorizontal: {
        width: '50%',
        borderStyle: 'solid',
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
    },
    tableCell: {
        fontSize: 5,
        padding: 3,
        paddingLeft: 4,
    },
    detailsTableCell: {
        fontSize: 7,
        padding: 4,
    },
    tableHeader: {
        fontSize: 7,
        padding: 4,
        fontWeight: 'bold',
    },
    landTableHeader: {
        fontSize: 7,
        padding: 4,
        fontWeight: 'bold',
    },
    pdf_table: {
        padding: '5px 0',
    },
    heading: {
        fontSize: 8,
        marginBottom: 5,
    },
    pdf_total: {
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    pdf_underline: {
        borderBottom: '1px solid #000',
    },
    pdf_stamp_box: {
        border: '1px solid #000',
        padding: 23,
    },
    pdf_barcode: {
        // width: '33%',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: 10,
    },
    pdf_stamp_div: {
        // width: '50%',
        flexDirection: 'column',
        alignItems: 'center',
    },
    pdf_barcode_div: {
        flexDirection: 'row',
        marginTop: 15,
        marginBottom: 15,
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
    },
    signature: {
        marginTop: 10,
        flexDirection: 'row',
        justifyContent: 'center',
    },
    signature_underline: {
        borderBottom: '1px solid #000',
        width: '50%',
    },
    pdf_barcode_div_text: {
        fontSize: 5,
    },
    discription: {
        fontSize: 5,
    },
    tbl_head: {
        fontSize: 10,
    },
});

const PDF = ({ data, id }) => {
    const [total, setTotal] = useState(0);

    const canvas = document.createElement('canvas');

    JsBarcode(canvas, id, {
        displayValue: false,
    });

    const barcodeDataURL = canvas.toDataURL();

    useEffect(() => {
        if (data && data?.lands && data?.tblmarshalling) {
            // const areaAmount = amount_obj[data.tblLand.area] || 0;

            const landPrice = data?.lands?.reduce((prev, curr) => {
                if (curr?.status === 'on-hold') {
                    return prev + Number(curr.tblLand?.tblstate?.price);
                }
                return prev + 0;
            }, 0);

            const vehiclePrice = data?.tblmarshalling?.vehicles?.reduce((prev, curr) => {
                if (curr?.paymentStatus === 'unpaid') {
                    return prev + curr.tblVehicleCategory?.price;
                }
                return prev + 0;
            }, 0);

            const total =
                (data.noOfCamels - (data?.paidAutomations?.noOfCamels || 0) || 0) * 4250 +
                (data.noOfCows - (data?.paidAutomations?.noOfCows || 0) || 0) * 4250 +
                (data.noOfGoats - (data?.paidAutomations?.noOfGoats || 0) || 0) * 2000 +
                (data.drum_count - (data?.paidAutomations?.drum_count || 0) || 0) * 2500 +
                (vehiclePrice || 0) +
                (data?.lands[0]?.customer_type !== 'land_booker' ? landPrice : 0);

            setTotal(total);
        }
    }, [data]);

    return (
        <Document>
            <Page orientation="landscape" size="A4" style={styles.page}>
                <View style={{ display: 'flex', flexDirection: 'row' }}>
                    <PDF_Helper
                        slip_type={'Customer Copy'}
                        barcodeDataURL={barcodeDataURL}
                        data={data}
                        total={total}
                        styles={styles}
                        id={id}
                    />
                    <View style={styles.column_line}></View>
                    <PDF_Helper
                        slip_type={'Bank Copy'}
                        barcodeDataURL={barcodeDataURL}
                        data={data}
                        total={total}
                        styles={styles}
                        id={id}
                    />
                    <View style={styles.column_line}></View>
                    <PDF_Helper
                        slip_type={'office Copy'}
                        barcodeDataURL={barcodeDataURL}
                        data={data}
                        total={total}
                        styles={styles}
                        id={id}
                    />
                </View>
            </Page>
        </Document>
    );
};

export default PDF;
