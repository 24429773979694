import { TextField, makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';

const Input = ({
    name,
    onChange,
    label,
    value,
    size,
    type,
    style,
    className,
    error,
    inputProps,
    isShowUnderline,
    onKeyDown,
    disabled,
    id,
    placeholder,
    onClick,
}) => {
    const [maxDate, setMaxDate] = useState('');

    useEffect(() => {
        const dtToday = new Date();
        const month = dtToday.getMonth() + 1;
        const day = dtToday.getDate();
        const year = dtToday.getFullYear();
        const formattedMonth = month < 10 ? `0${month}` : month;
        const formattedDay = day < 10 ? `0${day}` : day;
        const currentDate = `${year}-${formattedMonth}-${formattedDay}`;
        setMaxDate(currentDate);
    }, []);

    const useStyles = makeStyles((theme) => ({
        inputField: {
            '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: '#7904d7 !important',
                outlineColor: '#7904d7 !important',
                fontWeight: '400',
            },
            '&:hover .MuiFormLabel-root': {
                color: '#7904d7',
                fontWeight: '400',
            },
            '.MuiInputBase-input': {
                color: 'black',
                fontWeight: '400',
            },
            '& .MuiInput-underline:hover:not($disabled):before': {
                borderBottomColor: '#7904d7',
            },

            '& input': {
                fontFamily: 'Poppins',
                color: 'black',
                fontWeight: '400',
            },
        },

        inputFieldErr: {
            '& input': {
                fontFamily: 'Poppins',
                color: 'red !important',
                fontWeight: '400',
            },
        },
    }));

    const classes = useStyles();

    return (
        <TextField
            id={id ? id : 'outlined-basic'}
            size={size}
            type={type}
            label={label}
            variant={isShowUnderline ? 'standard' : disabled ? 'filled' : 'outlined'}
            value={value}
            onChange={onChange}
            name={name}
            style={style}
            className={[className, error ? classes.inputFieldErr : classes.inputField]}
            error={error}
            inputProps={{ ...inputProps, max: maxDate }}
            onKeyDown={onKeyDown}
            disabled={disabled}
            autocomplete="off"
            placeholder={placeholder}
            onClick={onClick}
            maxRows={1}
        />
    );
};

export default Input;
