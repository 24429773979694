import React from 'react';
import { FormDialog } from 'app/components/UiElements';

export const DeleteRecord = ({ open, handleClose, handleDelete, loading }) => {
    return (
        <FormDialog
            open={open}
            handleClose={handleClose}
            actionName="Delete"
            title="Delete Confirmation"
            actionHandler={handleDelete}
            loading={loading}
        >
            <h7>Are you sure you want to delete this record?</h7>
        </FormDialog>
    );
};
