import bg from 'assets/images/material_bg.svg';

const appFrame = {
    position: 'relative',
    display: 'flex',
    width: '100%',
};

const styles = (theme) => ({
    root: {
        width: '100%',
        height: '100%',
        marginTop: 0,
        // zIndex: 1,
        overflow: 'auto',
    },
    appFrameInner: {
        ...appFrame,
        height: '100%',
    },
    appFrameOuter: {
        ...appFrame,
        minHeight: '100%',
    },

    content: {
        backgroundColor: theme.palette.background.default,
        width: '100%',
        // padding: theme.spacing.unit * 2,
        // paddingLeft: theme.spacing.unit,
        overflow: 'hidden',
        overflowY: 'scroll',
        [theme.breakpoints.down('lg')]: {
            // paddingRight: theme.spacing.unit * 1.5,
        },
    },
    outerContent: {
        background: `url(${bg}) no-repeat gainsboro left bottom`,
        width: '100%',
        backgroundSize: 'cover',
        flexDirection: 'column',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        [theme.breakpoints.down('md')]: {
            padding: '20px 0',
        },
    },
    bgbar: {
        // backgroundColor: "rgb(84, 110, 122)",
        width: '100%',
        position: 'absolute',
        height: 204,
        top: 0,
        left: 0,
    },
    mainWrap: {
        position: 'relative',
        marginTop: theme.spacing.unit * 6.3,
        [theme.breakpoints.down('md')]: {
            // marginTop: theme.spacing.unit * 13.5,
        },
        height: '100%',
        '& > div': {
            paddingBottom: theme.spacing(4),
            willChange: 'inherit !important', // hack for floating form issue whne expaded
        },
    },
    preloader: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        // zIndex: 1000,
        background: 'transparent',
        height: 3,
    },
    materialBg: {
        position: 'absolute',
        left: 0,
        bottom: 0,
        width: '100%',
        opacity: 0.5,
    },
    contentPadding: {
        paddingLeft: 62,
    },
    hideApp: {
        display: 'none',
    },
    circularProgress: {
        position: 'absolute',
        top: 'calc(50% - 100px)',
        left: 'calc(50% - 100px)',
    },
    brand: {
        display: 'flex',
        padding: '10px 10px 5px',
        position: 'relative',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        margin: '15px 0px',
        alignContent: 'center',

        '& h3': {
            margin: 0,
            fontSize: 16,
            fontWeight: 500,
            paddingLeft: 10,
            color: theme.palette.common.white,
        },
    },
});

export default styles;
