import React from 'react';
import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';
import JsBarcode from 'jsbarcode';

const styles = StyleSheet.create({
    page: {
        backgroundColor: 'white',
        color: '#000',
        width: '100%',
        padding: 20,
        paddingTop: 5,
        paddingLeft: 2,
        paddingRight: 2,
        display: 'flex',
    },
    main_heading: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 15,
    },
    main_heading_textOne: {
        textTransform: 'capitalize',
        fontSize: 6,
        textDecoration: 'underline',
        fontWeight: 'bold',
    },
    main_heading_textTwo: {
        textTransform: 'uppercase',
        fontSize: 6,
    },
    main_heading_textThree: {
        textTransform: 'capitalize',
        fontSize: 6,
    },
    main: {
        flexDirection: 'column',
        justifyContent: 'space-between',
        // height: '90vh',
    },

    cattleMandi: {
        textTransform: 'capitalize',
        fontSize: 9,
        textDecoration: 'underline',
        textAlign: 'center',
    },

    pdf_first: {
        flexDirection: 'row',
        width: '100%',
        paddingTop: 20,
    },
    image: {
        width: '100%',
        height: '163px',
    },
    pdf_first_right: {
        width: '100%',
    },
    pdf_first_right_heading: {
        fontWeight: '900',
        marginBottom: 3,
        textDecoration: 'underline',
    },
    pdf_first_right_content: {
        padding: '5px 0',
        fontSize: '14px',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },

    column_line: {
        backgroundColor: '#000',
        width: 1,
        height: '100%',
    },

    table: {
        display: 'table',
        width: 'auto',
        borderStyle: 'solid',
        borderWidth: 1,
        borderRightWidth: 0,
        borderBottomWidth: 0,
    },
    tableRow: {
        flexDirection: 'row',
    },
    tableCol: {
        width: '33.33%',
        borderStyle: 'solid',
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
    },
    tableColHorizontal: {
        width: '50%',
        borderStyle: 'solid',
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
    },
    tableCell: {
        fontSize: 5,
        padding: 3,
        paddingLeft: 4,
    },
    detailsTableCell: {
        fontSize: 7,
        padding: 4,
    },
    tableHeader: {
        fontSize: 7,
        padding: 4,
        fontWeight: 'bold',
    },
    landTableHeader: {
        fontSize: 7,
        padding: 4,
        fontWeight: 'bold',
    },
    pdf_table: {
        padding: '5px 0',
    },
    heading: {
        fontSize: 8,
        marginBottom: 5,
    },
    pdf_total: {
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    pdf_underline: {
        borderBottom: '1px solid #000',
    },

    pdf_stamp_box: {
        border: '1px solid #000',
        padding: 23,
    },
    pdf_barcode: {
        marginTop: 15,
        flexDirection: 'column',
        alignItems: 'center',
    },
    pdf_stamp_div: {
        flexDirection: 'column',
        alignItems: 'center',
    },
    pdf_barcode_div: {
        flexDirection: 'row',
        marginTop: 15,
        marginBottom: 15,
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
    },
    signature: {
        flexDirection: 'row',
        justifyContent: 'center',
        marginTop: 15,
    },
    signature_underline: {
        borderBottom: '1px solid #000',
        width: '50%',
    },
    pdf_barcode_div_text: {
        fontSize: 5,
    },
    discription: {
        fontSize: 5,
    },
    tbl_head: {
        fontSize: 10,
    },
});

const LandPDF = ({ data, id }) => {
    const currentDate = new Date(data.createdAt).toLocaleString();
    const updatedAt = new Date(data.updatedAt).toLocaleString();

    const canvas = document.createElement('canvas');

    JsBarcode(canvas, id, {
        displayValue: false,
    });

    function generateSerialNumber(prefix, number) {
        const paddedNumber = String(number).padStart(5, '0');
        return `${prefix}${paddedNumber}`;
    }

    const barcodeDataURL = canvas.toDataURL();

    const landPrice = data?.lands?.reduce((prev, curr) => {
        // if (curr?.status === 'on-hold') {
        return prev + Number(curr.tblLand?.tblstate?.price);
        // }
        // return prev + 0;
    }, 0);

    return (
        <Document>
            <Page orientation="landscape" size="A4" style={styles.page}>
                <View style={{ display: 'flex', flexDirection: 'row' }}>
                    {/* customer copy  */}
                    <View style={{ width: '35%', paddingRight: 19, paddingLeft: 19 }}>
                        <View style={styles.main_heading}>
                            <View>
                                <Text style={styles.main_heading_textOne}>{'Customer Copy'}</Text>
                            </View>

                            <View>
                                <Text style={styles.main_heading_textThree}>{data?.id || ''}</Text>
                                <Text style={styles.main_heading_textThree}>
                                    {data?.serial_number ? generateSerialNumber('LCV', data?.serial_number) : ''}
                                </Text>
                            </View>
                        </View>
                        <View style={styles.main}>
                            <View style={styles.section_one}>
                                <View style={{ widht: '100%' }}>
                                    <Text style={styles.cattleMandi}>
                                        Cattle Mandi 2024 Northern Bypass Land booking Slip
                                    </Text>
                                </View>
                                <View style={styles.pdf_first}>
                                    <View style={styles.pdf_first_right}>
                                        <View>
                                            <View style={styles.heading}>
                                                <Text>Personal Details</Text>
                                            </View>
                                            {/* Payment details table */}
                                            <View style={styles.table}>
                                                <View style={styles.tableRow}>
                                                    <View style={styles.tableColHorizontal}>
                                                        <Text style={styles.tableHeader}>Voucher ID</Text>
                                                    </View>
                                                    <View style={styles.tableColHorizontal}>
                                                        <Text style={styles.detailsTableCell}>
                                                            {data?.serial_number
                                                                ? generateSerialNumber('LCV', data?.serial_number)
                                                                : ''}
                                                        </Text>
                                                    </View>
                                                </View>
                                                <View style={styles.tableRow}>
                                                    <View style={styles.tableColHorizontal}>
                                                        <Text style={styles.tableHeader}>Name</Text>
                                                    </View>
                                                    <View style={styles.tableColHorizontal}>
                                                        <Text style={styles.detailsTableCell}>{data?.name || ''}</Text>
                                                    </View>
                                                </View>
                                                <View style={styles.tableRow}>
                                                    <View style={styles.tableColHorizontal}>
                                                        <Text style={styles.tableHeader}>CNIC</Text>
                                                    </View>
                                                    <View style={styles.tableColHorizontal}>
                                                        <Text style={styles.detailsTableCell}>{data?.cnic || ''}</Text>
                                                    </View>
                                                </View>
                                                <View style={styles.tableRow}>
                                                    <View style={styles.tableColHorizontal}>
                                                        <Text style={styles.tableHeader}>Phone Number</Text>
                                                    </View>
                                                    <View style={styles.tableColHorizontal}>
                                                        <Text style={styles.detailsTableCell}>
                                                            {data?.contact_number || 0}
                                                        </Text>
                                                    </View>
                                                </View>
                                                <View style={styles.tableRow}>
                                                    <View style={styles.tableColHorizontal}>
                                                        <Text style={styles.tableHeader}>Voucher Created Date</Text>
                                                    </View>
                                                    <View style={styles.tableColHorizontal}>
                                                        <Text style={styles.detailsTableCell}>{currentDate || ''}</Text>
                                                    </View>
                                                </View>
                                            </View>
                                            {/* Payment details table */}
                                        </View>
                                    </View>
                                </View>

                                <View style={styles.pdf_table}>
                                    <View style={styles.heading}>
                                        <Text>Land Details</Text>
                                    </View>
                                    {/* Payment details table */}
                                    <View style={styles.table}>
                                        {/* table header */}
                                        <View style={styles.tableRow}>
                                            <View style={styles.tableCol}>
                                                <Text style={styles.landTableHeader}>Block</Text>
                                            </View>
                                            <View style={styles.tableCol}>
                                                <Text style={styles.landTableHeader}>Plot No.</Text>
                                            </View>
                                            <View style={styles.tableCol}>
                                                <Text style={styles.landTableHeader}>Amount</Text>
                                            </View>
                                            <View style={styles.tableCol}>
                                                <Text style={styles.landTableHeader}>Status</Text>
                                            </View>
                                        </View>
                                        {/* table header */}

                                        {/* table body */}
                                        {data?.lands?.map((item) => {
                                            return (
                                                <View style={styles.tableRow}>
                                                    <View style={styles.tableCol}>
                                                        <Text style={styles.tableCell}>
                                                            {item?.tblLand?.category || ''}
                                                        </Text>
                                                    </View>
                                                    <View style={styles.tableCol}>
                                                        <Text style={styles.tableCell}>
                                                            {item?.tblLand?.block || ''}
                                                        </Text>
                                                    </View>

                                                    <View style={styles.tableCol}>
                                                        <Text style={styles.tableCell}>
                                                            PKR {item?.tblLand?.tblstate.price || 0}
                                                        </Text>
                                                    </View>
                                                    <View style={styles.tableCol}>
                                                        <Text style={styles.tableCell}>
                                                            {item?.status === 'on-hold' ? 'unpaid' : 'paid' || 0}
                                                        </Text>
                                                    </View>
                                                </View>
                                            );
                                        })}

                                        {/* table body */}
                                    </View>
                                    {/* Payment details table */}
                                </View>

                                <View style={styles.pdf_total}>
                                    <Text style={styles.tbl_head}>Total</Text>
                                    <Text style={styles.tbl_head}>PKR {landPrice}</Text>
                                </View>

                                <View style={styles.pdf_underline} />

                                <View style={styles.pdf_barcode_div}>
                                    <View style={styles.signature}>
                                        <Text style={styles.pdf_barcode_div_text}>Sign</Text>
                                        <View style={styles.signature_underline}></View>
                                    </View>
                                </View>
                                <View style={styles.pdf_barcode}>
                                    <View>
                                        <Image src={barcodeDataURL} style={{ height: 50, width: 190 }} />
                                    </View>
                                    <View>
                                        <Text style={styles.pdf_barcode_div_text}>Scan The Barcode</Text>
                                    </View>
                                </View>
                            </View>
                        </View>
                    </View>
                    <View style={styles.column_line}></View>
                    {/* bank copy */}
                    <View style={{ width: '35%', paddingRight: 19, paddingLeft: 19 }}>
                        <View style={styles.main_heading}>
                            <View>
                                <Text style={styles.main_heading_textOne}>{'Bank Copy'}</Text>
                            </View>

                            <View>
                                <Text style={styles.main_heading_textThree}>{data?.id || ''}</Text>
                                <Text style={styles.main_heading_textThree}>
                                    {data?.serial_number ? generateSerialNumber('LCV', data?.serial_number) : ''}
                                </Text>
                            </View>
                        </View>
                        <View style={styles.main}>
                            <View style={styles.section_one}>
                                <View style={{ widht: '100%' }}>
                                    <Text style={styles.cattleMandi}>
                                        Cattle Mandi 2024 Northern Bypass Land booking Slip
                                    </Text>
                                </View>
                                <View style={styles.pdf_first}>
                                    <View style={styles.pdf_first_right}>
                                        <View>
                                            <View style={styles.heading}>
                                                <Text>Personal Details</Text>
                                            </View>
                                            {/* Payment details table */}
                                            <View style={styles.table}>
                                                <View style={styles.tableRow}>
                                                    <View style={styles.tableColHorizontal}>
                                                        <Text style={styles.tableHeader}>Voucher ID</Text>
                                                    </View>
                                                    <View style={styles.tableColHorizontal}>
                                                        <Text style={styles.detailsTableCell}>
                                                            {data?.serial_number
                                                                ? generateSerialNumber('LCV', data?.serial_number)
                                                                : ''}
                                                        </Text>
                                                    </View>
                                                </View>
                                                <View style={styles.tableRow}>
                                                    <View style={styles.tableColHorizontal}>
                                                        <Text style={styles.tableHeader}>Name</Text>
                                                    </View>
                                                    <View style={styles.tableColHorizontal}>
                                                        <Text style={styles.detailsTableCell}>{data?.name || ''}</Text>
                                                    </View>
                                                </View>
                                                <View style={styles.tableRow}>
                                                    <View style={styles.tableColHorizontal}>
                                                        <Text style={styles.tableHeader}>CNIC</Text>
                                                    </View>
                                                    <View style={styles.tableColHorizontal}>
                                                        <Text style={styles.detailsTableCell}>{data?.cnic || ''}</Text>
                                                    </View>
                                                </View>
                                                <View style={styles.tableRow}>
                                                    <View style={styles.tableColHorizontal}>
                                                        <Text style={styles.tableHeader}>Phone Number</Text>
                                                    </View>
                                                    <View style={styles.tableColHorizontal}>
                                                        <Text style={styles.detailsTableCell}>
                                                            {data?.contact_number || 0}
                                                        </Text>
                                                    </View>
                                                </View>
                                                <View style={styles.tableRow}>
                                                    <View style={styles.tableColHorizontal}>
                                                        <Text style={styles.tableHeader}>Voucher Created Date</Text>
                                                    </View>
                                                    <View style={styles.tableColHorizontal}>
                                                        <Text style={styles.detailsTableCell}>{currentDate || ''}</Text>
                                                    </View>
                                                </View>
                                            </View>
                                            {/* Payment details table */}
                                        </View>
                                    </View>
                                </View>

                                <View style={styles.pdf_table}>
                                    <View style={styles.heading}>
                                        <Text>Land Details</Text>
                                    </View>
                                    {/* Payment details table */}
                                    <View style={styles.table}>
                                        {/* table header */}
                                        <View style={styles.tableRow}>
                                            <View style={styles.tableCol}>
                                                <Text style={styles.landTableHeader}>Block</Text>
                                            </View>
                                            <View style={styles.tableCol}>
                                                <Text style={styles.landTableHeader}>Plot No.</Text>
                                            </View>
                                            <View style={styles.tableCol}>
                                                <Text style={styles.landTableHeader}>Amount</Text>
                                            </View>
                                            <View style={styles.tableCol}>
                                                <Text style={styles.landTableHeader}>Status</Text>
                                            </View>
                                        </View>
                                        {/* table header */}

                                        {/* table body */}
                                        {data?.lands?.map((item) => {
                                            return (
                                                <View style={styles.tableRow}>
                                                    <View style={styles.tableCol}>
                                                        <Text style={styles.tableCell}>
                                                            {item?.tblLand?.category || ''}
                                                        </Text>
                                                    </View>
                                                    <View style={styles.tableCol}>
                                                        <Text style={styles.tableCell}>
                                                            {item?.tblLand?.block || ''}
                                                        </Text>
                                                    </View>

                                                    <View style={styles.tableCol}>
                                                        <Text style={styles.tableCell}>
                                                            PKR {item?.tblLand?.tblstate.price || 0}
                                                        </Text>
                                                    </View>
                                                    <View style={styles.tableCol}>
                                                        <Text style={styles.tableCell}>
                                                            {item?.status === 'on-hold' ? 'unpaid' : 'paid' || 0}
                                                        </Text>
                                                    </View>
                                                </View>
                                            );
                                        })}

                                        {/* table body */}
                                    </View>
                                    {/* Payment details table */}
                                </View>

                                <View style={styles.pdf_total}>
                                    <Text style={styles.tbl_head}>Total</Text>
                                    <Text style={styles.tbl_head}>PKR {landPrice}</Text>
                                </View>

                                <View style={styles.pdf_underline} />

                                <View style={styles.pdf_barcode_div}>
                                    <View style={styles.signature}>
                                        <Text style={styles.pdf_barcode_div_text}>Sign</Text>
                                        <View style={styles.signature_underline}></View>
                                    </View>
                                </View>
                                <View style={styles.pdf_barcode}>
                                    <View>
                                        <Image src={barcodeDataURL} style={{ height: 50, width: 190 }} />
                                    </View>
                                    <View>
                                        <Text style={styles.pdf_barcode_div_text}>Scan The Barcode</Text>
                                    </View>
                                </View>
                            </View>
                        </View>
                    </View>
                    <View style={styles.column_line}></View>
                    {/* office copy */}
                    <View style={{ width: '35%', paddingRight: 19, paddingLeft: 19 }}>
                        <View style={styles.main_heading}>
                            <View>
                                <Text style={styles.main_heading_textOne}>{'Office Copy'}</Text>
                            </View>

                            <View>
                                <Text style={styles.main_heading_textThree}>{data?.id || ''}</Text>
                                <Text style={styles.main_heading_textThree}>
                                    {data?.serial_number ? generateSerialNumber('LCV', data?.serial_number) : ''}
                                </Text>
                            </View>
                        </View>
                        <View style={styles.main}>
                            <View style={styles.section_one}>
                                <View style={{ widht: '100%' }}>
                                    <Text style={styles.cattleMandi}>
                                        Cattle Mandi 2024 Northern Bypass Land booking Slip
                                    </Text>
                                </View>
                                <View style={styles.pdf_first}>
                                    <View style={styles.pdf_first_right}>
                                        <View>
                                            <View style={styles.heading}>
                                                <Text>Personal Details</Text>
                                            </View>
                                            {/* Payment details table */}
                                            <View style={styles.table}>
                                                <View style={styles.tableRow}>
                                                    <View style={styles.tableColHorizontal}>
                                                        <Text style={styles.tableHeader}>Voucher ID</Text>
                                                    </View>
                                                    <View style={styles.tableColHorizontal}>
                                                        <Text style={styles.detailsTableCell}>
                                                            {data?.serial_number
                                                                ? generateSerialNumber('LCV', data?.serial_number)
                                                                : ''}
                                                        </Text>
                                                    </View>
                                                </View>
                                                <View style={styles.tableRow}>
                                                    <View style={styles.tableColHorizontal}>
                                                        <Text style={styles.tableHeader}>Name</Text>
                                                    </View>
                                                    <View style={styles.tableColHorizontal}>
                                                        <Text style={styles.detailsTableCell}>{data?.name || ''}</Text>
                                                    </View>
                                                </View>
                                                <View style={styles.tableRow}>
                                                    <View style={styles.tableColHorizontal}>
                                                        <Text style={styles.tableHeader}>CNIC</Text>
                                                    </View>
                                                    <View style={styles.tableColHorizontal}>
                                                        <Text style={styles.detailsTableCell}>{data?.cnic || ''}</Text>
                                                    </View>
                                                </View>
                                                <View style={styles.tableRow}>
                                                    <View style={styles.tableColHorizontal}>
                                                        <Text style={styles.tableHeader}>Phone Number</Text>
                                                    </View>
                                                    <View style={styles.tableColHorizontal}>
                                                        <Text style={styles.detailsTableCell}>
                                                            {data?.contact_number || 0}
                                                        </Text>
                                                    </View>
                                                </View>
                                                <View style={styles.tableRow}>
                                                    <View style={styles.tableColHorizontal}>
                                                        <Text style={styles.tableHeader}>Voucher Created Date</Text>
                                                    </View>
                                                    <View style={styles.tableColHorizontal}>
                                                        <Text style={styles.detailsTableCell}>{currentDate || ''}</Text>
                                                    </View>
                                                </View>
                                            </View>
                                            {/* Payment details table */}
                                        </View>
                                    </View>
                                </View>

                                <View style={styles.pdf_table}>
                                    <View style={styles.heading}>
                                        <Text>Land Details</Text>
                                    </View>
                                    {/* Payment details table */}
                                    <View style={styles.table}>
                                        {/* table header */}
                                        <View style={styles.tableRow}>
                                            <View style={styles.tableCol}>
                                                <Text style={styles.landTableHeader}>Block</Text>
                                            </View>
                                            <View style={styles.tableCol}>
                                                <Text style={styles.landTableHeader}>Plot No.</Text>
                                            </View>
                                            <View style={styles.tableCol}>
                                                <Text style={styles.landTableHeader}>Amount</Text>
                                            </View>
                                            <View style={styles.tableCol}>
                                                <Text style={styles.landTableHeader}>Status</Text>
                                            </View>
                                        </View>
                                        {/* table header */}

                                        {/* table body */}
                                        {data?.lands?.map((item) => {
                                            return (
                                                <View style={styles.tableRow}>
                                                    <View style={styles.tableCol}>
                                                        <Text style={styles.tableCell}>
                                                            {item?.tblLand?.category || ''}
                                                        </Text>
                                                    </View>
                                                    <View style={styles.tableCol}>
                                                        <Text style={styles.tableCell}>
                                                            {item?.tblLand?.block || ''}
                                                        </Text>
                                                    </View>

                                                    <View style={styles.tableCol}>
                                                        <Text style={styles.tableCell}>
                                                            PKR {item?.tblLand?.tblstate.price || 0}
                                                        </Text>
                                                    </View>
                                                    <View style={styles.tableCol}>
                                                        <Text style={styles.tableCell}>
                                                            {item?.status === 'on-hold' ? 'unpaid' : 'paid' || 0}
                                                        </Text>
                                                    </View>
                                                </View>
                                            );
                                        })}

                                        {/* table body */}
                                    </View>
                                    {/* Payment details table */}
                                </View>

                                <View style={styles.pdf_total}>
                                    <Text style={styles.tbl_head}>Total</Text>
                                    <Text style={styles.tbl_head}>PKR {landPrice}</Text>
                                </View>

                                <View style={styles.pdf_underline} />

                                <View style={styles.pdf_barcode_div}>
                                    <View style={styles.signature}>
                                        <Text style={styles.pdf_barcode_div_text}>Sign</Text>
                                        <View style={styles.signature_underline}></View>
                                    </View>
                                </View>
                                <View style={styles.pdf_barcode}>
                                    <View>
                                        <Image src={barcodeDataURL} style={{ height: 50, width: 190 }} />
                                    </View>
                                    <View>
                                        <Text style={styles.pdf_barcode_div_text}>Scan The Barcode</Text>
                                    </View>
                                </View>
                            </View>
                        </View>
                    </View>
                </View>
            </Page>
        </Document>
    );
};

export default LandPDF;
