import { fromJS } from 'immutable';
import { ACTIVE_MONTH, LOGOUT } from '../actions/actionTypes';
const currentDate = new Date();
const currentYear = currentDate.getFullYear();
const currentMonth = currentDate.getMonth() + 1; // Months are zero-based, so we add 1
const currentDay = currentDate.getDate();
const initialState = {
    startDate: '2023-06-01',
    endDate: `${currentYear}-${currentMonth}-${currentDay}`,
};

const initialImmutableState = fromJS(initialState);

export default function reducer(state = initialImmutableState, action = {}) {
    switch (action.type) {
        case ACTIVE_MONTH:
            return state.withMutations((mutableState) => {
                return mutableState.set('startDate', action.payload.startDate).set('endDate', action.payload.endDate);
            });
        case LOGOUT:
            return state.withMutations((mutableState) => {
                mutableState.set('startDate', '').set('endDate', '');
            });
        default:
            if (state && state.get('startDate') && state.get('endDate')) {
                return state;
            } else {
                return initialImmutableState;
            }
    }
}
