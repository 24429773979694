import { combineReducers } from 'redux-immutablejs';
import ui from './modules/ui';
import initval from './modules/initForm';
import login from './modules/login';
import calendar from './modules/calendar';
import date from './modules/date';
import compaign from './modules/compaign';
import alert from './modules/alert';
import Increament from './modules/Increament';

export default function createReducer() {
    return combineReducers({
        ui,
        initval,
        login,
        calendar,
        date,
        compaign,
        alert,
        Increament
    });
}
