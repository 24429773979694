import React, { useEffect, useState } from 'react';
import Heading from 'app/helpers/Heading';
import './style.scss';
import { axiosInstance, errorHandler } from 'app/helpers';
import CattleMandiTable from 'app/components/Tables/CattleMandiTable';
import { cattleMandiAutomationTableHead } from 'app/components/Tables/CattleMandiTable/helper';
import { useDispatch } from 'react-redux';
import { setAlert } from 'app/redux/actions/alertAction';
import Loader from 'app/components/loader/Loader';
import { Box, Collapse, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { reversedAndFormatedDate } from 'app/helpers/convertDate';

const AutomationRecord = () => {
    // paid states
    const [automationData, setAutomationData] = useState([]);
    const [automationPaidData, setAutomationPaidData] = useState([]);
    const [isLoad, setIsLoad] = useState(false);
    const [searchData, setSearchData] = useState([]);
    const [searchPaidData, setSearchPaidData] = useState([]);
    const [getId, setGetid] = useState('');
    const [getPaidId, setGetPaidId] = useState('');
    const [dataCount, setDataCount] = useState(0);

    // unpaid states
    const [unpaidAutomationData, setUnpaidAutomationData] = useState([]);
    const [isLoadUnpaid, setIsLoadUnpaid] = useState(false);
    const [searchDataUnpaid, setSearchDataUnpaid] = useState([]);
    const [unpaidGetId, setUnpaidGetid] = useState('');
    const [unpaidDataCount, setUnpaidDataCount] = useState(0);

    const dispatch = useDispatch();
    const userData = useSelector((state) => state.getIn(['login', 'data']));
    const userRole = userData.get('role');

    // paid functions
    const getAllAutomation = async (offset) => {
        if (offset === undefined) setIsLoad(true);
        try {
            const { data } = await axiosInstance.get('/cattleMandi/get-all-automation', {
                params: { offset: offset || 0, filter: 'paid' },
            });
            const resData = data?.data;
            if (resData !== undefined) setAutomationData(data?.data.data);
            setDataCount(data?.data?.count);
            return resData?.data;
        } catch (error) {
            dispatch(
                setAlert({
                    isOpen: true,
                    type: 'error',
                    message: errorHandler(error),
                })
            );
            setTimeout(() => {
                dispatch(setAlert({}));
            }, 5000);
        } finally {
            setIsLoad(false);
        }
    };

    const customerSearch = async () => {
        if (getId) {
            try {
                setIsLoad(true);
                const { data } = await axiosInstance.get(`/cattleMandi/search-automation`, {
                    params: {
                        query: getId.trim(),
                        filter: 'paid',
                    },
                });

                if (data?.data) {
                    setSearchData([data?.data]);
                    setDataCount(data?.data?.length);
                } else {
                    setGetid('');
                    dispatch(
                        setAlert({
                            isOpen: true,
                            type: 'error',
                            message: 'Record not exists',
                        })
                    );
                    setTimeout(() => {
                        dispatch(setAlert({}));
                    }, 5000);
                    getAllAutomation();
                    setSearchData([]);
                }
            } catch (error) {
                setGetid('');
                dispatch(
                    setAlert({
                        isOpen: true,
                        type: 'error',
                        message: errorHandler(error),
                    })
                );
                setTimeout(() => {
                    dispatch(setAlert({}));
                }, 5000);
                getAllAutomation();
                setSearchData([]);
            } finally {
                setIsLoad(false);
            }
        }
    };

    const clearSearch = () => {
        setSearchData([]);
        getAllAutomation();
        setGetid('');
    };
    // -- end

    // unpaid functions
    const getAllUnpaidAutomation = async (offset) => {
        if (offset === undefined) setIsLoadUnpaid(true);
        try {
            const { data } = await axiosInstance.get('/cattleMandi/get-all-automation', {
                params: { offset: offset || 0, filter: 'unpaid' },
            });
            const resData = data?.data;
            if (resData !== undefined) setUnpaidAutomationData(data?.data.data);
            setUnpaidDataCount(data?.data?.count);
            return resData?.data;
        } catch (error) {
            dispatch(
                setAlert({
                    isOpen: true,
                    type: 'error',
                    message: errorHandler(error),
                })
            );
            setTimeout(() => {
                dispatch(setAlert({}));
            }, 5000);
        } finally {
            setIsLoadUnpaid(false);
        }
    };

    const unpaidCustomerSearch = async () => {
        if (unpaidGetId) {
            try {
                setIsLoadUnpaid(true);
                const { data } = await axiosInstance.get(`/cattleMandi/search-automation`, {
                    params: {
                        query: unpaidGetId.trim(),
                        filter: 'unpaid',
                    },
                });

                if (data?.data) {
                    setSearchDataUnpaid([data?.data]);
                    setUnpaidDataCount(data?.data?.length);
                } else {
                    setUnpaidGetid('');
                    dispatch(
                        setAlert({
                            isOpen: true,
                            type: 'error',
                            message: 'Record not exists',
                        })
                    );
                    setTimeout(() => {
                        dispatch(setAlert({}));
                    }, 5000);

                    setSearchDataUnpaid([]);
                    getAllUnpaidAutomation();
                }
            } catch (error) {
                setUnpaidGetid('');
                dispatch(
                    setAlert({
                        isOpen: true,
                        type: 'error',
                        message: errorHandler(error?.response?.data?.error),
                    })
                );
                setTimeout(() => {
                    dispatch(setAlert({}));
                }, 5000);

                setSearchDataUnpaid([]);
                getAllUnpaidAutomation();
            } finally {
                setIsLoadUnpaid(false);
            }
        }
    };

    const unpaidClearSearch = () => {
        setSearchDataUnpaid([]);
        getAllUnpaidAutomation();
        setUnpaidGetid('');
    };
    // -- end

    const renderCollapse = (value, isOpen) => {
        return (
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={20}>
                <Collapse in={isOpen?.open && isOpen?.value?.id === value?.id} timeout="auto" unmountOnExit>
                    <Box margin={1}>
                        <Typography variant="h6" gutterBottom component="div">
                            Lands
                        </Typography>
                        <Table size="small" aria-label="purchases">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Block#</TableCell>
                                    <TableCell>Category</TableCell>
                                    <TableCell>status</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {value?.lands.map((item, i) => (
                                    <TableRow key={i}>
                                        <TableCell component="th" scope="row">
                                            {item?.tblLand?.block}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {item?.tblLand?.category}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {item.status}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Box>
                </Collapse>
            </TableCell>
        );
    };

    const renderAutomationTableCell = (value) => {
        const renderCell = (content, defaultValue = '--') => {
            console.log('value', value);

            const cellColor = content === 'paid' ? 'green' : content === 'unpaid' && 'red';
            return <TableCell style={{ color: cellColor }}>{content ? content : defaultValue}</TableCell>;
        };

        return (
            <>
                {renderCell(value?.id)}
                {renderCell(value?.name)}
                {renderCell(value?.contactNo)}
                {renderCell(value?.cnic)}
                {renderCell(reversedAndFormatedDate(value && value?.createdAt))}
                {renderCell(value?.land_block_id)}
                {renderCell(value?.noOfGoats || '0')}
                {renderCell(value?.noOfCows || '0')}
                {renderCell(value?.noOfCamels || '0')}
                {renderCell(value?.paymentStatus)}
            </>
        );
    };

    useEffect(() => {
        getAllAutomation();
        getAllUnpaidAutomation();
        setSearchData([]);
        setSearchDataUnpaid([]);
    }, []);

    const dataRes = !!searchData?.length ? searchData : automationData;
    const unpaidDataRes = !!searchDataUnpaid?.length ? searchDataUnpaid : unpaidAutomationData;

    return (
        <div className="CattleMandiTables_Container">
            <Heading heading="Automation Records" />

            <div className="CattleMandiTables_content">
                {isLoad ? (
                    <Loader color="#000" height="65vh" />
                ) : (
                    <>
                        {!!automationData?.length || !!searchData?.length ? (
                            <div className="CattleMandiTables_Item">
                                <CattleMandiTable
                                    dataCount={dataCount}
                                    page={1}
                                    rowsPerPage={5}
                                    defaultPerPage={5}
                                    data={dataRes}
                                    checkBox
                                    // mtCell
                                    CheckBox
                                    CheckBoxShow
                                    name="Paid Automation Records Table"
                                    cattleMandiTableHead={cattleMandiAutomationTableHead}
                                    isLoad={isLoad}
                                    renderCell={renderAutomationTableCell}
                                    actionCell={true}
                                    fetchDDSDetails={getAllAutomation}
                                    showAutmationUserButton={userRole === 'automation'}
                                    showAutmationAdminButtons={userRole === 'cattleAdmin'}
                                    showSearch={true}
                                    getId={getId}
                                    setGetid={setGetid}
                                    customerSearch={customerSearch}
                                    clearSearch={clearSearch}
                                    showClearIcon={!!searchData?.length}
                                    renderCollapse={renderCollapse}
                                    showCollapse={false}
                                />
                            </div>
                        ) : null}
                    </>
                )}

                {isLoadUnpaid ? (
                    <Loader color="#000" height="65vh" />
                ) : (
                    <>
                        {!!unpaidAutomationData?.length || !!searchDataUnpaid?.length ? (
                            <div className="CattleMandiTables_Item">
                                <CattleMandiTable
                                    dataCount={unpaidDataCount}
                                    page={1}
                                    rowsPerPage={5}
                                    defaultPerPage={5}
                                    data={unpaidDataRes}
                                    checkBox
                                    // mtCell
                                    CheckBox
                                    CheckBoxShow
                                    name="Unpaid Automation Records Table"
                                    cattleMandiTableHead={cattleMandiAutomationTableHead}
                                    isLoad={isLoadUnpaid}
                                    renderCell={renderAutomationTableCell}
                                    actionCell={true}
                                    fetchDDSDetails={getAllUnpaidAutomation}
                                    showAutmationUserButton={userRole === 'automation'}
                                    showAutmationAdminButtons={userRole === 'cattleAdmin'}
                                    showSearch={true}
                                    getId={unpaidGetId}
                                    setGetid={setUnpaidGetid}
                                    customerSearch={unpaidCustomerSearch}
                                    clearSearch={unpaidClearSearch}
                                    showClearIcon={!!searchDataUnpaid?.length}
                                    renderCollapse={renderCollapse}
                                    showCollapse={false}
                                />
                            </div>
                        ) : null}
                    </>
                )}
            </div>
        </div>
    );
};

export default AutomationRecord;
