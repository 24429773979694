import React from 'react';
import './styles.scss';
import Heading from 'app/helpers/Heading';
import Input from 'app/components/Input/Input';
import ReuseableSelect from 'app/components/Select/Select';
import ButtonGroup from 'app/components/ButtonGroup/ButtonGroup';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Grid } from '@material-ui/core';
import { listOfVehicals } from 'app/helpers';

const ExitRecordForm = () => {
    const formik = useFormik({
        initialValues: {
            name: '',
            numberOfCows: '',
            numberOfCamels: '',
            numberOfGoats: '',
            categoryOfVehicle: '4 Wheelers',
        },
        validationSchema: Yup.object({
            name: Yup.string().required('required'),
            numberOfCows: Yup.string().required('required'),
            numberOfCamels: Yup.string().required('required'),
            numberOfGoats: Yup.string().required('required'),
            categoryOfVehicle: Yup.string().required('required'),
        }),
        onSubmit: async (values) => {
            const formData = new FormData();
            formData.append('name', values.name);
            formData.append('categoryOfVehicle', values.categoryOfVehicle);

            // try {
            //     const { data } = await axiosInstance.post('/userProfile/post-profile', formData);
            //     setIsLoad(false);
            //     console.log({ data });
            //     formik.resetForm();
            //     setSelectedVideo(false);
            //     dispatch(
            //         setAlert({
            //             isOpen: true,
            //             type: 'success',
            //             message: data?.data?.message,
            //         })
            //     );

            //     setTimeout(() => {
            //         dispatch(setAlert({}));
            //     }, 5000);
            //     // run API
            // } catch (error) {
            //     console.log({ error });
            //     dispatch(
            //         setAlert({
            //             isOpen: true,
            //             type: 'error',
            //             message: errorHandler(error),
            //         })
            //     );
            //     setTimeout(() => {
            //         dispatch(setAlert({}));
            //     }, 5000);
            //     setIsLoad(false);
            //     // API error
            // }
        },
    });

    const handleNumberKeyDown = (e) => {
        if (e.key === 'e' || e.key === '-') {
            e.preventDefault();
        }
    };

    return (
        <div className="ExitRecordForm_Container">
            <Heading heading="Exit Record Form" />

            <div className="ExitRecordForm_Content_Container">
                <div className="ExitRecordForm_Content_card">
                    <form
                        onSubmit={(e) => {
                            e.preventDefault();
                            formik.submitForm();
                        }}
                    >
                        <div>
                            <Grid container spacing={3}>
                                <Grid item md={6} xs={12} sm={12} className="TextInput_Main">
                                    <label>Name</label>
                                    <Input
                                        type="text"
                                        name="name"
                                        value={formik.values.name}
                                        onChange={formik.handleChange}
                                        className="TextInput"
                                        error={formik.touched.name && formik.errors.name}
                                        placeholder="Type Here"
                                    />
                                    {formik.touched.name && formik.errors.name && (
                                        <div className="error">{formik.errors.name}</div>
                                    )}
                                </Grid>

                                <Grid item md={6} xs={12} sm={12} className="TextInput_Main">
                                    <label>Category Of Vehicle</label>
                                    <ReuseableSelect
                                        style={{ width: '100%' }}
                                        name="categoryOfVehicle"
                                        formControlClassName="demo-customized-select"
                                        className={`TextInput select_error`}
                                        formik={formik}
                                        value={formik.values.categoryOfVehicle}
                                        onChange={formik.handleChange}
                                        error={formik.touched.categoryOfVehicle && formik.errors.categoryOfVehicle}
                                        optionList={listOfVehicals}
                                    />
                                    {formik.touched.categoryOfVehicle && formik.errors.categoryOfVehicle && (
                                        <div className="error">{formik.errors.categoryOfVehicle}</div>
                                    )}
                                </Grid>

                                <Grid item md={4} xs={12} sm={12} className="TextInput_Main">
                                    <label>No. Of Cows</label>
                                    <Input
                                        type="number"
                                        name="numberOfCows"
                                        value={formik.values.numberOfCows}
                                        onChange={formik.handleChange}
                                        className="TextInput"
                                        error={formik.touched.numberOfCows && formik.errors.numberOfCows}
                                        placeholder="Type Here"
                                        onKeyDown={handleNumberKeyDown}
                                        inputProps={{
                                            inputMode: 'numeric',
                                        }}
                                    />

                                    {formik.touched.numberOfCows && formik.errors.numberOfCows && (
                                        <div className="error">{formik.errors.numberOfCows}</div>
                                    )}
                                </Grid>

                                <Grid item md={4} xs={12} sm={12} className="TextInput_Main">
                                    <label>No. Of Camels</label>
                                    <Input
                                        type="number"
                                        name="numberOfCamels"
                                        value={formik.values.numberOfCamels}
                                        onChange={formik.handleChange}
                                        className="TextInput"
                                        error={formik.touched.numberOfCamels && formik.errors.numberOfCamels}
                                        placeholder="Type Here"
                                        onKeyDown={handleNumberKeyDown}
                                        inputProps={{
                                            inputMode: 'numeric',
                                        }}
                                    />
                                    {formik.touched.numberOfCamels && formik.errors.numberOfCamels && (
                                        <div className="error">{formik.errors.numberOfCamels}</div>
                                    )}
                                </Grid>

                                <Grid item md={4} xs={12} sm={12} className="TextInput_Main">
                                    <label>No. Of Goats</label>
                                    <Input
                                        type="number"
                                        name="numberOfGoats"
                                        value={formik.values.numberOfGoats}
                                        onChange={formik.handleChange}
                                        className="TextInput"
                                        error={formik.touched.numberOfGoats && formik.errors.numberOfGoats}
                                        placeholder="Type Here"
                                        onKeyDown={handleNumberKeyDown}
                                        inputProps={{
                                            inputMode: 'numeric',
                                        }}
                                    />
                                    {formik.touched.numberOfGoats && formik.errors.numberOfGoats && (
                                        <div className="error">{formik.errors.numberOfGoats}</div>
                                    )}
                                </Grid>

                                <Grid item md={12} xs={12} sm={12} className="TextInput_Main">
                                    <div className="submit-btn">
                                        <ButtonGroup
                                            isLoad={false}
                                            disabled={false}
                                            value="Submit"
                                            type="submit"
                                            variant="contained"
                                            customBtnBGColor="#7904d7"
                                            textColor="#fff"
                                        />
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default ExitRecordForm;
