import { Text, View, Image } from '@react-pdf/renderer';
import React from 'react';

const hideFreeLandPlot = {
    kpk: 'kpk',
    punjab: 'punjab',
    balochistan: 'balochistan',
    sindh: 'sindh',
    sibbi: 'sibbi',
    GB: 'GB',
    kashmir: 'kashmir',
    gaddap: 'gaddap',
    karachi: 'karachi',
};

const PDF_Helper = ({ data, slip_type, barcodeDataURL, total, styles,id }) => {
    const currentDate = new Date(data?.createdAt).toLocaleString();

    function generateSerialNumber(prefix, number) {
        const paddedNumber = String(number).padStart(5, '0');
        return `${prefix}${paddedNumber}`;
    }
    console.log({ data });
    return (
        <View style={{ width: '35%', paddingRight: 19, paddingLeft: 19 }}>
            <View style={styles.main_heading}>
                <View>
                    <Text style={styles.main_heading_textOne}>{slip_type}</Text>
                </View>

                <View>
                    <Text style={styles.main_heading_textThree}>{data?.id || ''}</Text>
                    <Text style={styles.main_heading_textThree}>
                        {data?.serial_number ? generateSerialNumber('MCV', data?.serial_number) : ''}
                    </Text>
                </View>
            </View>
            <View style={styles.main}>
                <View style={styles.section_one}>
                    <View style={{ widht: '100%' }}>
                        <Text style={styles.cattleMandi}>
                            Cattle Mandi 2024 Northern Bypass Marshallling booking Slip
                        </Text>
                    </View>
                    <View style={styles.pdf_first}>
                        <View style={styles.pdf_first_right}>
                            <View>
                                <View style={styles.heading}>
                                    <Text>Personal Details</Text>
                                </View>
                                {/* Payment details table */}
                                <View style={styles.table}>
                                    <View style={styles.tableRow}>
                                        <View style={styles.tableColHorizontal}>
                                            <Text style={styles.tableHeader}>Voucher ID</Text>
                                        </View>
                                        <View style={styles.tableColHorizontal}>
                                            <Text style={styles.tableCell}>
                                                {data?.serial_number
                                                    ? generateSerialNumber('MCV', data?.serial_number)
                                                    : ''}
                                            </Text>
                                        </View>
                                    </View>
                                    <View style={styles.tableRow}>
                                        <View style={styles.tableColHorizontal}>
                                            <Text style={styles.tableHeader}>Id</Text>
                                        </View>
                                        <View style={styles.tableColHorizontal}>
                                            <Text style={styles.tableCell}>{id}</Text>
                                        </View>
                                    </View>
                                    <View style={styles.tableRow}>
                                        <View style={styles.tableColHorizontal}>
                                            <Text style={styles.tableHeader}>Name</Text>
                                        </View>
                                        <View style={styles.tableColHorizontal}>
                                            <Text style={styles.tableCell}>{data?.name || ''}</Text>
                                        </View>
                                    </View>
                                    <View style={styles.tableRow}>
                                        <View style={styles.tableColHorizontal}>
                                            <Text style={styles.tableHeader}>CNIC</Text>
                                        </View>
                                        <View style={styles.tableColHorizontal}>
                                            <Text style={styles.tableCell}>{data?.cnic || ''}</Text>
                                        </View>
                                    </View>
                                    <View style={styles.tableRow}>
                                        <View style={styles.tableColHorizontal}>
                                            <Text style={styles.tableHeader}>Phone Number</Text>
                                        </View>
                                        <View style={styles.tableColHorizontal}>
                                            <Text style={styles.tableCell}>{data?.contactNo || 0}</Text>
                                        </View>
                                    </View>
                                    <View style={styles.tableRow}>
                                        <View style={styles.tableColHorizontal}>
                                            <Text style={styles.tableHeader}>Voucher Created Date</Text>
                                        </View>
                                        <View style={styles.tableColHorizontal}>
                                            <Text style={styles.tableCell}>{currentDate || ''}</Text>
                                        </View>
                                    </View>
                                </View>

                                {/* Payment details table */}
                            </View>
                        </View>
                    </View>
                    <View style={styles.pdf_table}>
                        <View style={styles.heading}>
                            <Text>Vehicle Details</Text>
                        </View>
                        {/* Vehicle table */}

                        <View style={styles.table}>
                            {/* table header  */}
                            <View style={styles.tableRow}>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableHeader}>Veh category</Text>
                                </View>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableHeader}>Veh number</Text>
                                </View>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableHeader}>Amount</Text>
                                </View>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableHeader}>Status</Text>
                                </View>
                            </View>
                            {/* table header */}

                            {/* table body */}
                            {data?.tblmarshalling?.vehicles
                                ?.filter((item) => item?.paymentStatus === 'unpaid')
                                ?.map((item) => {
                                    return (
                                        <View style={styles.tableRow}>
                                            <View style={styles.tableCol}>
                                                <Text style={styles.tableCell}>
                                                    {item?.tblVehicleCategory?.category}
                                                </Text>
                                            </View>
                                            <View style={styles.tableCol}>
                                                <Text style={styles.tableCell}>{item?.vehicle_number}</Text>
                                            </View>
                                            <View style={styles.tableCol}>
                                                <Text style={styles.tableCell}>{item?.tblVehicleCategory?.price}</Text>
                                            </View>
                                            <View style={styles.tableCol}>
                                                <Text style={styles.tableCell}>{item?.paymentStatus}</Text>
                                            </View>
                                        </View>
                                    );
                                })}

                            {/* table body */}
                        </View>
                        {/* Vehicle table */}
                    </View>
                    <View style={styles.pdf_table}>
                        <View style={styles.heading}>
                            <Text>Payment Details</Text>
                        </View>
                        {/* Payment details table */}
                        <View style={styles.table}>
                            {/* table header */}
                            <View style={styles.tableRow}>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableHeader}>Items</Text>
                                </View>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableHeader}>Count</Text>
                                </View>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableHeader}>Entry Fee</Text>
                                </View>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableHeader}>Amount</Text>
                                </View>
                            </View>
                            {/* table header */}

                            {/* table body */}
                            <View style={styles.tableRow}>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>No of Camels</Text>
                                </View>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>
                                        {data?.noOfCamels - (data?.paidAutomations?.noOfCamels || 0)}
                                    </Text>
                                </View>

                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>
                                        {data?.noOfCamels - (data?.paidAutomations?.noOfCamels || 0)} x 4250
                                    </Text>
                                </View>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>
                                        PKR {(data?.noOfCamels - (data?.paidAutomations?.noOfCamels || 0)) * 4250 || 0}
                                    </Text>
                                </View>
                            </View>
                            <View style={styles.tableRow}>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>No of Cows</Text>
                                </View>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>
                                        {data?.noOfCows - (data?.paidAutomations?.noOfCows || 0)}
                                    </Text>
                                </View>

                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>
                                        {data?.noOfCows - (data?.paidAutomations?.noOfCows || 0)} x 4250
                                    </Text>
                                </View>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>
                                        PKR {(data?.noOfCows - (data?.paidAutomations?.noOfCows || 0)) * 4250 || 0}
                                    </Text>
                                </View>
                            </View>
                            <View style={styles.tableRow}>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>No of Goats</Text>
                                </View>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>
                                        {data?.noOfGoats - (data?.paidAutomations?.noOfGoats || 0)}
                                    </Text>
                                </View>

                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>
                                        {data?.noOfGoats - (data?.paidAutomations?.noOfGoats || 0)} x 2000
                                    </Text>
                                </View>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>
                                        PKR {(data?.noOfGoats - (data?.paidAutomations?.noOfGoats || 0)) * 2000 || 0}
                                    </Text>
                                </View>
                            </View>
                            <View style={styles.tableRow}>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>Drum</Text>
                                </View>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>
                                        {data?.drum_count - (data?.paidAutomations?.drum_count || 0)}
                                    </Text>
                                </View>

                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>
                                        {data?.drum_count - (data?.paidAutomations?.drum_count || 0)} x 2500
                                    </Text>
                                </View>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>
                                        PKR {(data?.drum_count - (data?.paidAutomations?.drum_count || 0)) * 2500}
                                    </Text>
                                </View>
                            </View>
                            {/* table body */}
                        </View>
                        {/* Payment details table */}
                    </View>

                    <View style={styles.pdf_table}>
                        <View style={styles.heading}>
                            <Text>Land Details</Text>
                        </View>
                        {/* Payment details table */}
                        <View style={styles.table}>
                            {/* table header */}
                            <View style={styles.tableRow}>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableHeader}>Block</Text>
                                </View>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableHeader}>Plot No.</Text>
                                </View>

                                <View style={styles.tableCol}>
                                    <Text style={styles.tableHeader}>Amount</Text>
                                </View>
                            </View>
                            {/* table header */}

                            {/* table body */}
                            {data?.lands?.map((item, index) => {
                                console.log(item?.status);
                                if (item?.status !== 'booked') {
                                    // console.log('abcd')
                                    return (
                                        <View key={index} style={styles.tableRow}>
                                            <View style={styles.tableCol}>
                                                <Text style={styles.tableCell}>{item?.tblLand?.category || ''}</Text>
                                            </View>
                                            <View style={styles.tableCol}>
                                                <Text style={styles.tableCell}>
                                                    {hideFreeLandPlot[item?.tblLand?.category]
                                                        ? ''
                                                        : item?.tblLand?.block || ''}
                                                </Text>
                                            </View>
                                            <View style={styles.tableCol}>
                                                <Text style={styles.tableCell}>
                                                    PKR {item?.tblLand?.tblstate?.price || 0}
                                                </Text>
                                            </View>
                                        </View>
                                    );
                                }
                                return null;
                            })}

                            {/* table body */}
                        </View>
                        {/* Payment details table */}
                    </View>

                    <View style={styles.pdf_total}>
                        <Text style={styles.tbl_head}>Total</Text>
                        <Text style={styles.tbl_head}>PKR {total || 0}</Text>
                    </View>

                    <View style={styles.pdf_underline} />

                    <View style={styles.pdf_barcode_div}>
                        <View style={styles.signature}>
                            <Text style={styles.pdf_barcode_div_text}>Sign</Text>
                            <View style={styles.signature_underline}></View>
                        </View>
                    </View>
                    <View style={styles.pdf_barcode}>
                        <View>
                            <Image src={barcodeDataURL} style={{ height: 50, width: 190 }} />
                        </View>
                        <View>
                            <Text style={styles.pdf_barcode_div_text}>Scan The Barcode</Text>
                        </View>
                    </View>
                </View>
            </View>
        </View>
    );
};

export default PDF_Helper;
