import React, { useState, useEffect } from 'react';
import Heading from 'app/helpers/Heading';
import './style.scss';
import { Grid, TableCell } from '@material-ui/core';
import { TwoSideWidget } from 'app/components/CounterIconsWidget/TwoSideWidget';
import { AnalyticsTabs, AverageStats, Carousel, PapperBlock } from 'app/components';
import { axiosInstance, errorHandler, shuffledCattleMandiImages } from 'app/helpers';
import { ReachTrial } from 'app/components/Charts/ReachTrial';
import { reachPaidUnPaid, reachTrial } from 'app/utils/conversions';
import { setAlert } from 'app/redux/actions/alertAction';
import { useDispatch } from 'react-redux';
import MarshallingSummaryModal from 'app/components/MarshallingSummaryModal/MarshallingSummaryModal';
import LandSummaryModal from 'app/components/LandSummaryModal/LandSummaryModal';
import OverAllSummaryModal from 'app/components/OverAllSummaryModal/OverAllSummaryModal';

const Dashboard = () => {
    const [isLoad, setIsLoad] = useState(false);
    const [getTotalData, setGetTotalData] = useState({
        total_animals: 0,
        total_cash: 0,
        total_users: 0,
        total_vehicle_count: 0,
        total_lands: 0,
        lands_details: {},
        animal_details: {},
        vehicle_details: {},
    });
    const [getStatsPerDayData, setGetStatsPerDayData] = useState({
        average_stats_per_day: 0,
        total_animals: 0,
        total_cash_per_day: 0,
        total_users: 0,
    });

    const [cattleAnalyticsData, setCattleAnalyticsData] = useState({
        today: {
            cow: 0,
            camel: 0,
            goat: 0,
            land: 0,
            drums: 0,
            vehicle: 0,
        },
        previous: {
            cow: 0,
            camel: 0,
            goat: 0,
            land: 0,
            drums: 0,
            vehicle: 0,
        },
        total: {
            cow: 0,
            camel: 0,
            goat: 0,
            land: 0,
            drums: 0,
            vehicle: 0,
        },
    });

    const [getAnimalPaidUnPaidPerDayData, setGetAnimalPaidUnPaidPerDayData] = useState({
        days: [],
        paid: [],
        unpaid: [],
    });
    const [marchallingOpen, setMarchallingOpen] = useState(false);
    const [landOpen, setLandOpen] = useState(false);
    const [summaryOpen, setSummaryOpen] = useState(false);

    const dispatch = useDispatch();

    const getCattleStats = async () => {
        try {
            const { data } = await axiosInstance.get('/cattleMandi/get-cattle-stats');

            setGetTotalData(data?.data);
        } catch (error) {
            dispatch(
                setAlert({
                    isOpen: true,
                    type: 'error',
                    message: errorHandler(error),
                })
            );
            setTimeout(() => {
                dispatch(setAlert({}));
            }, 5000);
        }
    };

    const getCattleStatsPerDay = async () => {
        try {
            const { data } = await axiosInstance.get('/cattleMandi/get-cattle-stats-per-day');

            setGetStatsPerDayData(data?.data);
        } catch (error) {
            dispatch(
                setAlert({
                    isOpen: true,
                    type: 'error',
                    message: errorHandler(error),
                })
            );
            setTimeout(() => {
                dispatch(setAlert({}));
            }, 5000);
        }
    };

    const getCattleAnalytics = async () => {
        try {
            const { data } = await axiosInstance.get('/cattleMandi/cattle-analytics');

            setCattleAnalyticsData(data?.data);
        } catch (error) {
            dispatch(
                setAlert({
                    isOpen: true,
                    type: 'error',
                    message: errorHandler(error),
                })
            );
            setTimeout(() => {
                dispatch(setAlert({}));
            }, 5000);
        }
    };

    const getCattleAnimalPaidUnPaidPerDay = async () => {
        try {
            const { data } = await axiosInstance.get('/cattleMandi/get-cattle-daily-animal-paid-unpaid');

            setGetAnimalPaidUnPaidPerDayData(data?.data);
        } catch (error) {
            dispatch(
                setAlert({
                    isOpen: true,
                    type: 'error',
                    message: errorHandler(error),
                })
            );
            setTimeout(() => {
                dispatch(setAlert({}));
            }, 5000);
        }
    };

    const allGetAPIs = async () => {
        setIsLoad(true);
        try {
            await Promise.all([
                getCattleStats(),
                // getCattleStatsPerDay(),
                getCattleAnimalPaidUnPaidPerDay(),
                getCattleAnalytics(),
            ]);
        } catch (error) {
            dispatch(
                setAlert({
                    isOpen: true,
                    type: 'error',
                    message: errorHandler(error),
                })
            );
            setTimeout(() => {
                dispatch(setAlert({}));
            }, 5000);
        } finally {
            setIsLoad(false);
        }
    };

    useEffect(() => {
        allGetAPIs();
    }, []);
    const totalMarshlings =
        (+getTotalData?.total_drums ?? 0) +
        (+getTotalData?.total_animals ?? 0) +
        (+getTotalData?.total_vehicle_count ?? 0);
    console.log({ totalMarshlings });
    console.log({getTotalData})
    return (
        <div className="Dashboard_Container">
            <MarshallingSummaryModal
                open={marchallingOpen}
                handleClose={() => setMarchallingOpen(false)}
                data={getTotalData ?? {}}
            />
            <LandSummaryModal
                open={landOpen}
                handleClose={() => setLandOpen(false)}
                data={getTotalData?.lands_details ?? {}}
            />
            <OverAllSummaryModal
                open={summaryOpen}
                handleClose={() => setSummaryOpen(false)}
                data={getTotalData ?? {}}
            />

            <Heading heading="Admin Cattle Mandi" />

            <div className="dashboard_content">
                <div style={{ paddingRight: '2.2rem' }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                            <div>
                                <TwoSideWidget
                                    loader={isLoad}
                                    color="pink"
                                    image="biryani"
                                    title="Total Marshallings"
                                    target=""
                                    achieved={totalMarshlings}
                                    onClick={() => setMarchallingOpen(true)}
                                    // topLeftHeading="Active"
                                    // toAchieve="100"

                                    toAchieveNotShow={true}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                            <TwoSideWidget
                                loader={isLoad}
                                color="pink"
                                image="animal"
                                title="Total Plots"
                                achieved={getTotalData?.total_lands}
                                onClick={() => setLandOpen(true)}
                                // topLeftHeading="Active"
                                // toAchieve="100"
                                toAchieveNotShow={true}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                            <TwoSideWidget
                                loader={isLoad}
                                color="pink"
                                image="money"
                                title="Summary"
                                toAchieveNotShow={true}
                                onClick={() => setSummaryOpen(true)}
                            />
                        </Grid>
                    </Grid>

                    <div className="Dashboard__item">
                        <PapperBlock title="Statistics" desc="" isCentered={true}>
                            {/* <Grid container style={{ gap: 20 }}> */}
                            <AnalyticsTabs data={cattleAnalyticsData} isLoad={isLoad} />
                            {/* </Grid> */}
                        </PapperBlock>
                    </div>

                    {/* <div className="Dashboard__item">
                        <PapperBlock title="Daily Automations" desc="">
                            <ReachTrial
                                data={reachPaidUnPaid(
                                    getAnimalPaidUnPaidPerDayData.days,
                                    getAnimalPaidUnPaidPerDayData.paid,
                                    getAnimalPaidUnPaidPerDayData.unpaid
                                )}
                                loader={false}
                            />
                        </PapperBlock>
                    </div> */}

                    {/* <div className="Dashboard__item">
                        <CattleMandiTable
                            page={1}
                            rowsPerPage={5}
                            defaultPerPage={5}
                            data={[
                                {
                                    id: '1',
                                    name: 'Usman',
                                    picture: 'yourImg.JPG',
                                    contact: '+92 313124313',
                                    animalKind: 'Bull',
                                    noOfAnimals: '4',
                                    drumRequired: '2',
                                },
                            ]}
                            checkBox
                            // mtCell
                            CheckBox
                            CheckBoxShow
                            name="Data Table"
                            cattleMandiTableHead={cattleMandiDashboardTableHead}
                            renderCell={renderTableCell}
                        />
                    </div> */}
                </div>
            </div>
        </div>
    );
};

export default Dashboard;
