import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import './style.scss';
import { Grid } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

const MultiLandComponent = ({ lands, showCloseButton, onClose, onDelete, isLandBooker = false, showDeleteButton }) => {
    return (
        <Grid container className="multi-lands">
            {lands.map((land, index) => (
                <Grid item xl={5} lg={5} md={6} sm={12} xs={12} className="multi-land-span">
                    {`Plot #${land?.tblLand?.block || land?.block}  ${land?.tblLand?.category || land?.category
                        }`}
                    {land?.status !== "on-hold" && land?.status !== "booked" && showCloseButton && <CloseIcon className="land-span-close" onClick={() => onClose(land)} />}
                    {land?.status === "on-hold" && showDeleteButton && <DeleteIcon className="land-span-close" onClick={() => onDelete(land)} />}

                </Grid>
            ))}
        </Grid>
    );
};

export default MultiLandComponent;
