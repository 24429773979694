import { fromJS } from 'immutable';
import { LOGIN, LOGOUT } from '../actions/actionTypes';

const initialState = {
    data: '',
    authenticated: false,
};

const initialImmutableState = fromJS(initialState);
export default function reducer(state = initialImmutableState, action = {}) {
    switch (action.type) {
        case LOGIN:
            return state.withMutations((mutableState) => {
                mutableState.set('data', action.payload?.data);
                mutableState.set('authenticated', true);
            });
        case LOGOUT:
            return state.withMutations((mutableState) => {
                mutableState.set('data', '');
                mutableState.set('authenticated', false);
            });
        default:
            return state;
    }
}
