const roleWise = localStorage.getItem('role');
const compaign = JSON.parse(localStorage.getItem('state'));

 if (roleWise === 'marshalling') {
    module.exports = [
        {
            key: 'entry_gate_marshalling',
            name: 'Create Marshalling',
            icon: 'create_new_folder_icon',
            link: '/entry-gate',
            showRoute: true,
        },
        {
            key: 'cattle_mandi_tables',
            name: 'Marshalling Records',
            icon: 'grid_on',
            link: '/marshalling-records',
            showRoute: true,
        },
    ];
} else if (roleWise === 'automation') {
    module.exports = [
        {
            key: 'data_Entry',
            name: 'Create Automation',
            icon: 'create_new_folder_icon',
            link: '/data-entry',
            showRoute: true,
        },
        {
            key: 'automation_records',
            name: 'Automation Records',
            icon: 'grid_on',
            link: '/automation-records',
            showRoute: true,
        },
        {
            key: 'home',
            name: 'Scan Receipt',
            icon: 'document_scanner_icon',
            link: '/scan-receipt',
            showRoute: true,
        },
        {
            key: 'land_booker_record',
            name: 'Land Booker Record',
            icon: 'grid_on',
            link: '/land-booker-records',
            showRoute: true,
        },
        {
            key: 'entry_gate_marshalling',
            name: 'Create Marshalling',
            icon: 'create_new_folder_icon',
            link: '/entry-gate',
            showRoute: true,
        },
        {
            key: 'cattle_mandi_tables',
            name: 'Marshalling Records',
            icon: 'grid_on',
            link: '/marshalling-records',
            showRoute: true,
        },
    ];
} else if (roleWise === 'cattleAdmin') {
    module.exports = [
        {
            key: 'dashboard',
            name: 'Dashboard',
            icon: 'dashboard',
            link: '/app',
            showRoute: true,
        },
        {
            key: 'cattle_mandi_tables',
            name: 'Marshalling Records',
            icon: 'grid_on',
            link: '/marshalling-records',
            showRoute: true,
        },
        {
            key: 'automation_records',
            name: 'Automation Records',
            icon: 'grid_on',
            link: '/automation-records',
            showRoute: true,
        },
        {
            key: 'bank_records',
            name: 'Bank Records',
            icon: 'grid_on',
            link: '/bank-records',
            showRoute: true,
        },
        {
            key: 'entry_gate_marshalling',
            name: 'Create Marshalling',
            icon: 'create_new_folder_icon',
            link: '/entry-gate',
            showRoute: true,
        },
        {
            key: 'data_Entry',
            name: 'Create Automation',
            icon: 'create_new_folder_icon',
            link: '/data-entry',
            showRoute: true,
        },
        {
            key: 'home',
            name: 'Scan Receipt',
            icon: 'document_scanner_icon',
            link: '/scan-receipt',
            showRoute: true,
        },
        {
            key: 'home',
            name: 'Land Booker',
            icon: 'insert_photo_icon',
            link: '/land-booker',
            showRoute: true,
        },
        {
            key: 'land_booker_record',
            name: 'Land Booker Record',
            icon: 'grid_on',
            link: '/land-booker-records',
            showRoute: true,
        },
    ];
} else if (roleWise === 'cattleBank') {
    module.exports = [
        {
            key: 'home',
            name: 'Scan Receipt',
            icon: 'document_scanner_icon',
            link: '/scan-receipt',
            showRoute: true,
        },
        {
            key: 'bank_records',
            name: 'Bank Records',
            icon: 'grid_on',
            link: '/bank-records',
            showRoute: true,
        },
    ];
} else if (roleWise === 'landBooker') {
    module.exports = [
        {
            key: 'home',
            name: 'Land Booker',
            icon: 'insert_photo_icon',
            link: '/land-booker',
            showRoute: true,
        },
        {
            key: 'land_booker_record',
            name: 'Land Booker Record',
            icon: 'grid_on',
            link: '/land-booker-records',
            showRoute: true,
        },
    ];
} else {
    module.exports = [];
}
