import React from 'react';
import { Grid } from '@material-ui/core';
import { TwoSideWidget } from './TwoSideWidget';
import { OneSideWidget } from './OneSideWidget';
// import { useSelector } from 'react-redux';
import './CounterIconsWidget.scss';

const CounterIconsWidget = ({ fixed, toAchieve, achieved, winners, dashboard, supervisor, loader, isTooltipShow }) => {
    // const compaign = useSelector((state) => state?.getIn(['compaign', 'data']))

    return (
        <Grid container className={'counterContainer'}>
            <Grid item xs={12} sm={12} md={12} lg={5} xl={3}>
                <TwoSideWidget
                    loader={loader}
                    color="pink"
                    target={fixed?.household}
                    toAchieve={toAchieve?.household}
                    achieved={achieved?.household}
                    title={dashboard?.interception}
                    image="Household"
                    topLeftHeading="Up Till Target"
                    isTooltipShow={isTooltipShow}
                    bottomRightHeading="Achieved"
                />
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={5} xl={3}>
                <TwoSideWidget
                    loader={loader}
                    color="purple"
                    target={fixed?.sales}
                    toAchieve={toAchieve?.sales}
                    achieved={achieved?.sales}
                    title={dashboard?.sales}
                    image="sales"
                    topLeftHeading="Up Till Target"
                    isTooltipShow={isTooltipShow}
                    bottomRightHeading="Achieved"
                />
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={5} xl={3}>
                <TwoSideWidget
                    loader={loader}
                    color="darkGreen"
                    target={fixed?.retailer}
                    toAchieve={toAchieve?.retailer}
                    achieved={achieved?.retailer}
                    title={dashboard?.retailer}
                    image="biryani"
                    topLeftHeading="Up Till Target"
                    isTooltipShow={isTooltipShow}
                    bottomRightHeading="Achieved"
                />
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={5} xl={3}>
                <TwoSideWidget
                    loader={loader}
                    color="purple"
                    target=""
                    toAchieve={''}
                    // achieved={achieved?.retailer}
                    achieved={achieved?.cash}
                    title={dashboard?.cash_collected || supervisor?.cash_collected}
                    // title={dashboard?.retailer}
                    image="file"
                    topLeftHeading="Total Cash Collected"
                    bottomRightHeading=""
                />
            </Grid>

            {/* {compaign?.getIn(['id']) !== 1 && ( */}
            <>
                {/* <Grid item xs={12} sm={12} md={4} lg={4}>
                    <OneSideWidget
                        loader={loader}
                        color="purple"
                        achieved={achieved?.cash}
                        title={dashboard?.cash_collected || supervisor?.cash_collected}
                        image="AttachMoney"
                        mainHeading="Collected"
                    />
                </Grid> */}
                {/* <Grid item xs={12} sm={6} md={3} lg={4}>
                        <TwoSideWidget
                            color="blue"
                            toAchieve={winners?.position_winners}
                            achieved={winners?.lucky_draw_winners}
                            title="WINNERS"
                            image="winners"
                            topLeftHeading="Daily Winners"
                            bottomRightHeading="Lucky Draw"
                        />
                    </Grid> */}
            </>
            {/* )} */}
        </Grid>
    );
};

export default CounterIconsWidget;
