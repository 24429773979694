import React, { useEffect, useState } from 'react';
import Heading from 'app/helpers/Heading';
import './style.scss';
import { axiosInstance, errorHandler } from 'app/helpers';
import CattleMandiTable from 'app/components/Tables/CattleMandiTable';
import { cattleMandiMarshallingTableHead } from 'app/components/Tables/CattleMandiTable/helper';
import { useDispatch } from 'react-redux';
import { setAlert } from 'app/redux/actions/alertAction';
import Loader from 'app/components/loader/Loader';
import { Box, Collapse, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { listOfVehicals } from 'app/helpers';
import { reversedAndFormatedDate } from 'app/helpers/convertDate';

const MarshallingRecord = () => {
    const [marshallingData, setMarshallingData] = useState([]);
    const [searchData, setSearchData] = useState([]);
    const [isLoad, setIsLoad] = useState(false);
    const [dataCount, setDataCount] = useState(0);
    const [getId, setGetid] = useState('');
    const dispatch = useDispatch();

    const userData = useSelector((state) => state.getIn(['login', 'data']));
    const userRole = userData.get('role');

    const getAllAutomation = async (offset) => {
        if (offset === undefined) setIsLoad(true);
        try {
            const { data } = await axiosInstance.get('/cattleMandi/get-all-marshalling', {
                params: { offset: offset },
            });
            const resData = data?.data;
            console.log({ resData });
            if (resData !== undefined) {
                setMarshallingData(resData.data);
            }
            setDataCount(resData.count);
            return resData?.data;
        } catch (error) {
            dispatch(
                setAlert({
                    isOpen: true,
                    type: 'error',
                    message: errorHandler(error),
                })
            );
            setTimeout(() => {
                dispatch(setAlert({}));
            }, 5000);
        } finally {
            setIsLoad(false);
        }
    };

    const customerSearch = async () => {
        if (getId) {
            try {
                setIsLoad(true);
                const { data } = await axiosInstance.get(`/cattleMandi/search-marshalling`, {
                    params: {
                        id: getId.trim(),
                    },
                });

                setSearchData([data?.data]);
                setDataCount(data?.data?.length);
            } catch (error) {
                setGetid('');
                dispatch(
                    setAlert({
                        isOpen: true,
                        type: 'error',
                        message: errorHandler(error),
                    })
                );
                setTimeout(() => {
                    dispatch(setAlert({}));
                }, 5000);
                setSearchData([]);
                getAllAutomation();
            } finally {
                setIsLoad(false);
            }
        }
    };

    const clearSearch = () => {
        setSearchData([]);
        getAllAutomation();
        setGetid('');
    };

    const renderTableCell = (value) => {
        const renderCell = (content, defaultValue = '--') => <TableCell>{content ? content : '--'}</TableCell>;

        return (
            <>
                {renderCell(value?.id)}
                {renderCell(value?.name_of_owner)}
                {renderCell(value?.cnic)}
                {renderCell(reversedAndFormatedDate(value?.createdAt))}
                {renderCell(value?.no_of_camels || '0')}
                {renderCell(value?.no_of_cows || '0')}
                {renderCell(value?.no_of_goats || '0')}
            </>
        );
    };

    const filterCategoryId = (vehicle_category_id) => {
        let category_id = listOfVehicals.filter((item, i) => item.value === vehicle_category_id);

        return category_id[0]?.heading;
    };

    const renderCollapse = (value, isOpen) => {
        return (
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={20}>
                <Collapse in={isOpen?.open && isOpen?.value?.id === value?.id} timeout="auto" unmountOnExit>
                    <Box margin={1}>
                        <Typography variant="h6" gutterBottom component="div">
                            Vehicles
                        </Typography>
                        <Table size="small" aria-label="purchases">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Vehicle Number</TableCell>
                                    <TableCell>Kind Of Vehicle</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {value?.vehicles &&
                                    value?.vehicles.map((item, i) => (
                                        <TableRow key={i}>
                                            <TableCell component="th" scope="row">
                                                {item.vehicle_number}
                                            </TableCell>
                                            <TableCell>{filterCategoryId(item.vehicle_category_id)}</TableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </Box>
                </Collapse>
            </TableCell>
        );
    };

    useEffect(() => {
        setSearchData([]);
        getAllAutomation();
    }, []);

    const dataRes = !!searchData?.length ? searchData : marshallingData;

    return (
        <div className="CattleMandiTables_Container">
            <Heading heading="Marshalling Records" />

            <div className="CattleMandiTables_content">
                {isLoad ? (
                    <Loader color="#000" height="65vh" />
                ) : (
                    <>
                        {!!marshallingData?.length || !!searchData?.length ? (
                            <div className="CattleMandiTables_Item">
                                <CattleMandiTable
                                    page={1}
                                    rowsPerPage={5}
                                    defaultPerPage={5}
                                    data={dataRes}
                                    checkBox
                                    // mtCell
                                    dataCount={dataCount}
                                    CheckBox
                                    CheckBoxShow
                                    name="Marshalling Records Table"
                                    cattleMandiTableHead={cattleMandiMarshallingTableHead}
                                    isLoad={isLoad}
                                    renderCell={renderTableCell}
                                    actionCell={true}
                                    showMarshallingAdminButtons={userRole === 'cattleAdmin'}
                                    showSearch={true}
                                    getId={getId}
                                    fetchDDSDetails={getAllAutomation}
                                    setGetid={setGetid}
                                    customerSearch={customerSearch}
                                    clearSearch={clearSearch}
                                    showClearIcon={!!searchData?.length}
                                    renderCollapse={renderCollapse}
                                />
                            </div>
                        ) : null}
                    </>
                )}
            </div>
        </div>
    );
};

export default MarshallingRecord;
