import React from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

export const FormDialog = ({ handleClose, children, open, title, classes, actionName, actionHandler, loading }) => {
    return (
        <Grid container justify="center" direction="column">
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" maxWidth="lg">
                <DialogTitle id="form-dialog-title">{title}</DialogTitle>
                <DialogContent>{children}</DialogContent>

                <div style={sytles}>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    {actionName && (
                        <Button onClick={actionHandler} disabled={loading} color="secondary" variant="contained">
                            {loading ? 'Deleting...' : actionName}
                        </Button>
                    )}
                </div>
            </Dialog>
        </Grid>
    );
};

const sytles = {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '20px',
    columnGap: 10,
};
