import React from 'react';
import { Backdrop, Modal } from '@material-ui/core';

function CustomModal({ open, handleClose, children }) {
    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            {children}
        </Modal>
    );
}

export default CustomModal;
