import { fromJS, Map } from 'immutable';
import { COMPAIGN, LOGOUT } from '../actions/actionTypes';

const initialState = {
    data: {
        id: 2,
        name: 'Karachi 360 Attack-KPI 2021-22',
    },
};

const initialImmutableState = fromJS(initialState);
export default function reducer(state = initialImmutableState, action = {}) {
    switch (action.type) {
        case COMPAIGN:
            return state.withMutations((mutableState) => {
                mutableState.set('data', action.payload);
            });

        case LOGOUT:
            return state.withMutations((mutableState) => {
                mutableState.set('data', initialState.data);
            });

        default:
            return state;
    }
}
