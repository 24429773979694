import activity1 from 'assets/images/nfl-images/nflStaff1.jpg';
import activity2 from 'assets/images/nfl-images/nflStaff2.jpg';
import activity3 from 'assets/images/nfl-images/nflStaff3.jpg';
import activity4 from 'assets/images/nfl-images/nflStaff4.jpg';
import activity5 from 'assets/images/nfl-images/nflStaff5.jpg';
import activity6 from 'assets/images/nfl-images/nflStaff6.jpg';
import activity7 from 'assets/images/nfl-images/nflStaff7.jpg';
import activity8 from 'assets/images/nfl-images/nflStaff8.jpg';
import activity9 from 'assets/images/nfl-images/nflStaff9.jpg';
import activity10 from 'assets/images/nfl-images/nflStaff10.jpg';
import activity11 from 'assets/images/nfl-images/nflStaff11.jpg';
import activity12 from 'assets/images/nfl-images/nflStaff12.jpg';
import activity13 from 'assets/images/nfl-images/nflStaff13.jpg';
import activity14 from 'assets/images/nfl-images/nflStaff14.jpg';
import activity15 from 'assets/images/nfl-images/nflStaff15.jpg';
import activity16 from 'assets/images/nfl-images/nflStaff16.jpg';

import cow1 from 'assets/images/cattle-mandi/cow-one.jpeg';
import cow2 from 'assets/images/cattle-mandi/cow-two.jpeg';
import cow3 from 'assets/images/cattle-mandi/cow-three.webp';
import cow5 from 'assets/images/cattle-mandi/cow-five.jpeg';
import cow6 from 'assets/images/cattle-mandi/cow-six.jpeg';

import Axios from 'axios';
// import config from '../config';
const config = require('../config/featureFlag/index');

export const axiosInstance = Axios.create({
    baseURL: config.default?.baseURL?.BASE_URL,
    // baseURL: 'http://localhost:4000/api',
    headers: { Authorization: localStorage.getItem('token'), config: { 'Content-Type': 'application/json' } },
});

export const parseError = (error) => (error.message ? error.message : error?.response?.data?.error?.message);

export const showNflBrand = config?.default?.featureFlags?.ShowNflBrand;
export const brandName = config?.default?.featureFlags?.brandName;

// export const brandName = showNflBrand ? 'NFL' : 'Asra Soft';

export const supervisorSearchParam = (supervisorId, activityId, name) =>
    new URLSearchParams({
        'supervisor-id': supervisorId,
        'activity-id': activityId,
        name: name,
    });

export const userRoles = {
    marshalling: 'marshalling',
    automation: 'automation',
    cattleAdmin: 'cattleAdmin',
    cattleBank: 'cattleBank',
    landBooker: 'landBooker',
};

export const errorHandler = (error) => {
    if (error?.response?.data?.error?.message) {
        console.log('1');
        return error?.response?.data?.error?.message;
    } else if (error?.message) {
        console.log('2');
        return error?.message;
    } else if (error) {
        return error;
    }
};

const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
};

const images = [
    activity1,
    activity2,
    activity3,
    activity4,
    activity5,
    activity6,
    activity7,
    activity8,
    activity9,
    activity10,
    activity11,
    activity12,
    activity13,
    activity14,
    activity15,
    activity16,
];

const cowImgs = [cow1, cow2, cow3, cow5, cow6, cow3, cow5, cow6];

export const shuffledNFLImages = shuffleArray([...images]);

export const shuffledCattleMandiImages = shuffleArray([...cowImgs]);

export const dataURItoBlob = (dataURI) => {
    const byteString = atob(dataURI.split(',')[1]);
    const mimeString = dataURI
        .split(',')[0]
        .split(':')[1]
        .split(';')[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
};

export const listOfVehicals = [
    { id: 0, value: 'Select Vehicle', heading: 'Select Vehicle' },
    { id: 1, value: 1, heading: 'Pick up (1500)' },
    { id: 2, value: 2, heading: '4-6 Wheelers (2200)' },
    { id: 3, value: 3, heading: '8-16 Wheelers (3500)' },
    { id: 4, value: 4, heading: '18-22 Wheelers (4500)' },
];
