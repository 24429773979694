import React, { useEffect, useRef, useState } from 'react';
import './ImagePreview.scss';

const ImagePreview = ({ imageUrl }) => {
    const [showPreview, setShowPreview] = useState(false);
    const containerRef = useRef(null);

    const togglePreview = () => setShowPreview(!showPreview);

    const closePreview = (e) => {
        if (containerRef.current && !containerRef.current.contains(e.target)) {
            setShowPreview(false);
        }
    };

    useEffect(() => {
        if (showPreview) {
            document.addEventListener('click', closePreview);
        } else {
            document.removeEventListener('click', closePreview);
        }

        return () => {
            document.removeEventListener('click', closePreview);
        };
    }, [showPreview]);

    return (
        <div className="image-preview-container" ref={containerRef}>
            {showPreview ? (
                <img src={imageUrl} alt="Preview" className={`image-preview ${showPreview ? 'show' : ''}`} />
            ) : (
                <img src={imageUrl} alt="" className="image-preview-before" onClick={togglePreview} />
            )}
        </div>
    );
};

export default ImagePreview;
