import React from 'react';
import { withGoogleMap, GoogleMap, Marker } from 'react-google-maps';
import withScriptjs from 'react-google-maps/lib/withScriptjs';
import markerOne from 'assets/images/marker/marker1.png';
import markerTwo from 'assets/images/marker/marker2.png';
import markerThree from 'assets/images/marker/marker5.png';

const MapWithAMarker = withScriptjs(
    withGoogleMap((props) => (
        <GoogleMap defaultCenter={{ lat: 24.8607, lng: 67.0011 }} defaultZoom={10}>
            {props.markers.map((marker) => (
                <Marker
                    key={marker?.id}
                    position={{
                        lat: parseFloat(marker.coordinates.latitude),
                        lng: parseFloat(marker.coordinates.longitude),
                    }}
                    icon={{
                        url: props.getMarkerColor(),
                        scaledSize: new window.google.maps.Size(35, 35), // Adjust the size as needed
                    }}
                />
            ))}
        </GoogleMap>
    ))
);

export const PolygonMarker = ({ pointers }) => {
    const getMarkerColor = () => {
        const randomColor = () => {
            const colors = [markerOne, markerTwo, markerThree];
            const randomIndex = Math.floor(Math.random() * colors.length);
            return colors[randomIndex];
        };

        return randomColor();
    };
    // const markers = [
    //     { id: 1, coordinates: { latitude: 24.8607, longitude: 67.0011 } },
    //     { id: 2, coordinates: { latitude: 24.9295, longitude: 67.1251 } },
    //     { id: 3, coordinates: { latitude: 24.9263, longitude: 67.0225 } },
    //     { id: 4, coordinates: { latitude: 24.860735, longitude: 67.0225 } },
    //     { id: 5, coordinates: { latitude: 37.421881, longitude: -122.0840263 } },
    // ];
    return (
        <MapWithAMarker
            googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_OTP_GOOGLE_MAP_KEY}`}
            loadingElement={<div style={{ height: '100%' }} />}
            containerElement={<div style={{ height: '400px' }} />}
            mapElement={<div style={{ height: '100%' }} />}
            markers={pointers}
            getMarkerColor={getMarkerColor}
        />
    );
};
