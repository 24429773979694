import { lighten } from '@material-ui/core/styles/colorManipulator';
const styles = (theme) => ({
    root: {
        paddingRight: theme.spacing.unit,
    },
    tableWrapper: {
        overflowX: 'auto',
    },
    image: {
        height: '50px',
        width: '50px',
    },
    check: {
        display: 'table-cell',
    },
    style: {
        wordBreak: 'break-word',

        width: '500',
        // overflowX: 'auto',
        zIndex: 9,
    },

    closeIcon: {
        position: 'fixed',
        top: '45px',
        left: '693px',
        zIndex: 9999,
    },

    rootTable: {
        width: '100%',
        marginTop: theme.spacing.unit * 3,
        overflowX: 'auto',
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                  color: theme.palette.secondary.main,
                  backgroundColor: lighten(theme.palette.secondary.light, 0.85),
              }
            : {
                  color: theme.palette.text.primary,
                  backgroundColor: theme.palette.secondary.dark,
              },
    spacer: {
        flex: '1 1 100%',
    },
    actionsToolbar: {
        color: theme.palette.text.secondary,
        display: 'flex',
    },
    titleToolbar: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        '& span': {
            fontSize: 30,
            fontWeight: 700,
        },

        '@media (max-width: 550px)': {
            flexDirection: 'column',
        },
    },
    filterBtn: {
        top: -5,
    },
    download: {
        top: -30,
    },
    selected: {
        marginLeft: 900,
    },
    textField: {
        flexBasis: 200,
        width: 300,
    },
    table: {
        minWidth: 900,
    },
    actions: {
        color: theme.palette.text.secondary,
        margin: 10,
        display: 'flex',
        justifyContent: 'right',
        alignItems: 'center',
        alignContent: 'center',
        width: '60%',
    },
    toolbar: {
        backgroundColor: theme.palette.grey[800],
    },
    title: {
        flex: '0 0 auto',
        '& h2': {
            color: theme.palette.common.white,
        },
    },
    button: {
        margin: theme.spacing.unit,
        paddingRight: -15,
    },
    iconSmall: {
        fontSize: 20,
    },
    leftIcon: {
        marginRight: theme.spacing.unit,
    },
    mainHeading: {
        color: '#fff',
        textTransform: 'uppercase',
        fontSize: 24,
        marginBottom: '1em',
    },
    selectedContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        alignItems: 'center',
    },
    toolbarRoot: {
        padding: '10px 0px 10px 10px',
    },
    noRecordContainer: {
        position: 'relative',
        top: 'auto',
        right: 'auto',
        bottom: 'auto',
        left: '-25px',
        marginTop: '10px',
    },
});

export default styles;
