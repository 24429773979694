import { useLocation } from 'react-router-dom';

export const salesStats = (fixedTargets, toAchieveTargets, achievedTargets) => [
    {
        name: 'Sales (100%)',
        Target: fixedTargets?.sales,
        AchieveToDate: toAchieveTargets?.sales,
        Achieved: achievedTargets?.sales,
    },
    {
        name: 'Deal 1 (65%)',
        Target: fixedTargets?.sales * 0.65,
        AchieveToDate: toAchieveTargets?.deal1 + toAchieveTargets?.deal3,
        Achieved: achievedTargets?.deal1 + achievedTargets?.deal3,
    },
    {
        name: 'Deal 2 (35%)',
        Target: fixedTargets?.sales * 0.35,
        AchieveToDate: toAchieveTargets?.deal2 + toAchieveTargets?.deal4,
        Achieved: achievedTargets?.deal2 + achievedTargets?.deal4,
    },
    {
        name: 'Rs 150/- (42%)',
        Target: fixedTargets?.sales * 0.42,
        AchieveToDate: toAchieveTargets?.deal1,
        Achieved: achievedTargets?.deal1,
    },
    {
        name: 'Rs 70/- (23%)',
        Target: fixedTargets?.sales * 0.23,
        AchieveToDate: toAchieveTargets?.deal3,
        Achieved: achievedTargets?.deal3,
    },
    {
        name: 'Rs 100/- (23%)',
        Target: fixedTargets?.sales * 0.23,
        AchieveToDate: toAchieveTargets?.deal2,
        Achieved: achievedTargets?.deal2,
    },
    {
        name: 'Rs 40/- (12%)',
        Target: fixedTargets?.sales * 0.12,
        AchieveToDate: toAchieveTargets?.deal4,
        Achieved: achievedTargets?.deal4,
    },
];

export const sumDeals = (data) =>
    parseInt(data?.deal1) + parseInt(data?.deal2) + parseInt(data?.deal3) + parseInt(data?.deal4);

export const KpiTargetAchForBE = (data) => [
    {
        name: 'House hold',
        Target: data?.household?.target,
        Achieved: data?.household?.achieved,
    },
    {
        name: 'Sales',
        Target: data?.sales?.target,
        Achieved: data?.sales?.achieved,
    },
    {
        name: 'Retailer',
        Target: data?.retailer?.target,
        Achieved: data?.retailer?.achieved,
    },
];

// reach trial data
// const months = ['Aug', 'Oct', 'Nov', 'Dec', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'];
// const Reach = [58296, 61142, 60906, 63479, 63322, 54541, 75672, 76343, 87061, 80931];
// const Trial = [35020, 30734, 34384, 27663, 47780, 45747, 51581, 42815, 45485, 79633];

// export const reachTrial = (months, Reach, Trial) => {
export const reachTrial = (months, Reach, Trial) => {
    const reach = Reach?.map((ele) => Number(ele));
    const trial = Trial?.map((ele) => Number(ele));
    const finalRezult = months?.map((item, index) => {
        return {
            name: item || '',
            Trial: trial?.[index] || 0,
            Interception: reach?.[index] || 0,
        };
    });
    return !!finalRezult?.length ? finalRezult : [];
};
export const reachPaidUnPaid = (months, Paid, UnPaid) => {
    const paid = Paid?.map((ele) => Number(ele));
    const unPaid = UnPaid?.map((ele) => Number(ele));
    const finalRezult = months?.map((item, index) => {
        return {
            name: item || '',
            Paid: paid?.[index] || 0,
            UnPaid: unPaid?.[index] || 0,
        };
    });
    return !!finalRezult?.length ? finalRezult : [];
};

export const ReachVstrial = (months, Reach, trial) => ({
    chart: {
        zoomType: 'xy',
    },
    title: {
        text: '',
    },

    colors: ['rgb(0, 150, 136)', 'rgb(156, 39, 176)'],
    xAxis: [
        {
            categories: months,
            crosshair: true,
        },
    ],
    yAxis: [
        {
            // Primary yAxis
        },
        {
            // secondary axis
            opposite: true,
        },
    ],
    tooltip: {
        shared: true,
    },
    series: [
        {
            name: 'Reach',
            type: 'column',
            // yAxis: 1,
            data: Reach,
        },
        {
            name: 'trial',
            type: 'spline',
            data: trial,
        },
    ],
    credits: {
        enabled: false,
    },
    responsive: {
        rules: [
            {
                condition: {
                    maxWidth: 768,
                },
                // Make the labels less space demanding on mobile
                chartOptions: {
                    xAxis: {
                        labels: {
                            formatter: function() {
                                return this.value.charAt(0);
                            },
                        },
                    },
                },
            },
        ],
    },
});

export const SnakeCaseToTiltleCase = (key) => {
    let i,
        value = key?.split('_');
    for (i = 0; i < value?.length; i++) {
        value[i] = value[i].charAt(0).toUpperCase() + value[i].slice(1);
    }
    return value?.join(' ');
};

export const useQuery = () => new URLSearchParams(useLocation().search);
//export const useQuery = (props) => new URLSearchParams(props.Location.push().search);

// {
//     name: 'Aug',
//     Reach: Reach,
//     Trial: Trial,
// },

// {
//     name: 'Oct',
//     Reach: Reach,
//     Trial: Trial,
// },
// {
//     name: 'Nov',
//     Reach: Reach,
//     Trial: Trial,
// },
// {
//     name: 'Dec',
//     Reach: Reach,
//     Trial: Trial,
// },
// {
//     name: 'Jan',
//     Reach: Reach,
//     Trial: Trial,
// },
// {
//     name: 'Feb',
//     Reach: Reach,
//     Trial: Trial,
// },
// {
//     name: 'Mar',
//     Reach: Reach,
//     Trial: Trial,
// },
// {
//     name: 'Apr',
//     Reach: Reach,
//     Trial: Trial,
// },
// {
//     name: 'May',
//     Reach: Reach,
//     Trial: Trial,
// },
// {
//     name: 'Jun',
//     Reach: Reach,
//     Trial: Trial,
// },
