export const viewDetailTableHead = ['Date', 'Deposited Amount', 'Deposited By', 'Invoice', 'Ref #'];

export const tableHead = {
    AllUsers: [
        {
            id: 1,
            name: 'Id',
        },
        {
            id: 2,
            name: 'Number',
        },
        {
            id: 3,
            name: 'Created At',
        },
        {
            id: 4,
            name: 'Name',
        },
        {
            id: 5,
            name: 'Phone Number',
        },
        {
            id: 6,
            name: 'Gender',
        },
        {
            id: 7,
            name: 'DOB',
        },
        {
            id: 8,
            name: 'NICNumber',
        },
        {
            id: 9,
            name: 'Actions',
        },
    ],
    Finance: [
        { id: 1, name: 'Year' },
        { id: 2, name: 'Month' },
        { id: 3, name: 'Collected' },
        { id: 4, name: 'Deposited' },
        { id: 5, name: 'Balance' },
        { id: 6, name: 'Actions' },
    ],
    DailyStats: [
        { id: 1, name: 'Id' },
        { id: 2, name: 'Number' },
        { id: 3, name: 'Activity Name' },
        { id: 4, name: 'Name' },
        { id: 5, name: 'Phone Number' },
        { id: 6, name: 'Actions' },
    ],
    BADailyUpdate: [
        { id: 1, name: 'Id' },
        { id: 2, name: 'Number' },
        { id: 3, name: 'Activity Name' },
        { id: 4, name: 'Ba Name' },
        { id: 5, name: 'Phone Number' },
        { id: 6, name: 'Interception' },
        { id: 7, name: 'No Response' },
        { id: 8, name: 'Trials' },
        { id: 9, name: 'Sales' },
        { id: 10, name: 'Quantity' },
    ],
    BAWiseTotal: [
        { id: 1, name: 'Id' },
        { id: 2, name: 'Number' },
        { id: 3, name: 'Activity Name' },
        { id: 4, name: 'Name' },
        { id: 5, name: 'Phone Number' },
        { id: 6, name: 'Interception' },
        { id: 7, name: 'No Response' },
        { id: 8, name: 'Trials' },
        { id: 9, name: 'Sales' },
        { id: 10, name: 'Quantity' },
        { id: 11, name: 'Actions' },
    ],
};
