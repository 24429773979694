export const data1 = [
  {
    name: 'Page A',
    uv: 4000,
    pv: 2400,
    amt: 2400
  },
  {
    name: 'Page B',
    uv: 3000,
    pv: 1398,
    amt: 2210
  },
  {
    name: 'Page C',
    uv: 2000,
    pv: 5800,
    amt: 2290
  },
  {
    name: 'Page D',
    uv: 2780,
    pv: 3908,
    amt: 2000
  },
  {
    name: 'Page E',
    uv: 1890,
    pv: 4800,
    amt: 2181
  },
  {
    name: 'Page F',
    uv: 2390,
    pv: 3800,
    amt: 2500
  },
  {
    name: 'Page G',
    uv: 3490,
    pv: 4300,
    amt: 2100
  },
];

export const data2 = [
  {
    name: 'Page A',
    uv: 4000,
    female: 2400,
    male: 2400
  }, {
    name: 'Page B',
    uv: 3000,
    female: 1398,
    male: 2210
  }, {
    name: 'Page C',
    uv: 2000,
    female: 9800,
    male: 2290
  }, {
    name: 'Page D',
    uv: 2780,
    female: 3908,
    male: 2000
  }, {
    name: 'Page E',
    uv: 1890,
    female: 4800,
    male: 2181
  }, {
    name: 'Page F',
    uv: 2390,
    female: 3800,
    male: 2500
  }, {
    name: 'Page G',
    uv: 3490,
    female: 4300,
    male: 2100
  },
];

export const data3 = [
  {
    name: 'Page A',
    uv: 4000,
    pv: 2400,
    amt: 2400
  }, {
    name: 'Page B',
    uv: -3000,
    pv: 1398,
    amt: 2210
  }, {
    name: 'Page C',
    uv: -2000,
    pv: -9800,
    amt: 2290
  }, {
    name: 'Page D',
    uv: 2780,
    pv: 3908,
    amt: 2000
  }, {
    name: 'Page E',
    uv: -1890,
    pv: 4800,
    amt: 2181
  }, {
    name: 'Page F',
    uv: 2390,
    pv: -3800,
    amt: 2500
  }, {
    name: 'Page G',
    uv: 3490,
    pv: 4300,
    amt: 2100
  },
];

export const data4 = [
  {
    name: 'Group A',
    value: 400
  }, {
    name: 'Group B',
    value: 300
  },
  {
    name: 'Group C',
    value: 300
  }, {
    name: 'Group D',
    value: 200
  },
  {
    name: 'Group E',
    value: 278
  }, {
    name: 'Group F',
    value: 189
  }
];

export const data5 = [
  {
    name: 'Group A',
    value: 2400
  }, {
    name: 'Group B',
    value: 4567
  },
  {
    name: 'Group C',
    value: 1398
  }, {
    name: 'Group D',
    value: 9800
  },
  {
    name: 'Group E',
    value: 3908
  }, {
    name: 'Group F',
    value: 4800
  }
];

export const data6 = [
  {
    name: 'Group A',
    value: 400
  }, {
    name: 'Group B',
    value: 300
  },
  {
    name: 'Group C',
    value: 300
  }, {
    name: 'Group D',
    value: 200
  }
];

export const data7 = [
  {
    subject: 'Math',
    A: 120,
    B: 110,
    fullMark: 150
  }, {
    subject: 'Chinese',
    A: 98,
    B: 130,
    fullMark: 150
  }, {
    subject: 'English',
    A: 86,
    B: 130,
    fullMark: 150
  }, {
    subject: 'Geography',
    A: 99,
    B: 100,
    fullMark: 150
  }, {
    subject: 'Physics',
    A: 85,
    B: 90,
    fullMark: 150
  }, {
    subject: 'History',
    A: 65,
    B: 85,
    fullMark: 150
  },
];

export const data8 = [
  {
    x: 10,
    y: 30
  }, {
    x: 30,
    y: 200
  }, {
    x: 45,
    y: 100
  }, {
    x: 50,
    y: 400
  }, {
    x: 70,
    y: 150
  }, {
    x: 100,
    y: 250
  }
];

export const data9 = [
  {
    x: 30,
    y: 20
  }, {
    x: 50,
    y: 180
  }, {
    x: 75,
    y: 240
  }, {
    x: 100,
    y: 100
  }, {
    x: 120,
    y: 190
  }
];
