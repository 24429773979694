import React, { useEffect } from 'react';
import { FormControl, InputLabel, Select, makeStyles } from '@material-ui/core';

const ReuseableSelect = ({
    label,
    name,
    value,
    onChange,
    optionList,
    className,
    id,
    style,
    formControlClassName,
    error,
    disabled,
    formik,
    placeholder,
    showOption,
}) => {
    const useStyles = makeStyles((theme) => ({
        inputField: {
            '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: '#7904d7 !important',
                outlineColor: '#7904d7 !important',
            },
            '&:hover .MuiFormLabel-root': {
                color: '#7904d7',
            },
        },
    }));

    const classes = useStyles();
    // useEffect(() => {
    //     console.log({ value });
    // }, [value]);
    return (
        <FormControl
            variant={disabled ? 'filled' : 'outlined'}
            className={[classes.formControl, formControlClassName, error ? {} : classes.inputField]}
            style={style}
        >
            <InputLabel htmlFor="outlined-age-native-simple" style={error ? { color: 'red' } : {}}>
                {label}
            </InputLabel>
            <Select
                placeholder={placeholder}
                native
                value={formik?.values?.[name] || value}
                defaultValue={formik?.values?.[name] || value}
                onChange={formik?.handleChange || onChange}
                className={className}
                disabled={disabled}
                label={label}
                error={error}
                id={id}
                inputProps={{
                    name: name,
                    id: 'outlined-age-native-simple',
                }}
            >
                {showOption && <option>{showOption}</option>}
                {!!optionList.length ? (
                    optionList.map((item, index) => {
                        return (
                            <option
                                className={classes.option}
                                key={index}
                                value={item.hasOwnProperty('value') ? item?.value : item?.id}
                            >
                                {item.heading ||
                                    item?.name ||
                                    `${item?.block} (${item.category ? item.area : item.area})`}
                            </option>
                        );
                    })
                ) : (
                    <option>Loading...</option>
                )}
            </Select>
        </FormControl>
    );
};

export default ReuseableSelect;
