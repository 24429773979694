import React, { useState, useEffect } from 'react';
import { brand } from 'app/utils/brand';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import logo from 'assets/images/loginPageLogo.png';
import { LoginForm } from 'app/components';
import { useDispatch, useSelector } from 'react-redux';
import { axiosInstance } from 'app/helpers';
import { LoginAction } from 'app/redux/actions/loginAction';
import { useHistory } from 'react-router-dom';
import './Login.scss';

const Login = () => {
    const [loading, setLoading] = useState(false);
    const [valueForm, setValueForm] = useState({
        id: null,
        password: null,
    });
    const [error, setError] = useState(null);
    const authenticated = useSelector((state) => state.getIn(['login', 'authenticated']));

    const title = brand.name + ' - Login';
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        if (authenticated && roleWise === 'marshalling') {
            history.push('/entry-gate');
        } else if (authenticated && roleWise === 'automation') {
            history.push('/data-entry');
        } else if (authenticated && roleWise === 'cattleAdmin') {
            history.push('/app');
        } else if (authenticated && roleWise === 'cattleBank') {
            history.push('/scan-receipt');
        } else if (authenticated && roleWise === 'landBooker') {
            history.push('/land-booker');
        }
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (!(valueForm.id && valueForm.password)) throw new Error('Please enter ID and Password');
            setLoading(true);
            const { data } = await axiosInstance.get('/auth/signin-portal', {
                params: {
                    number: valueForm.id,
                    password: valueForm.password,
                },
            });
            localStorage.setItem('token', data?.data?.token);
            localStorage.setItem('role', data?.data?.role);
            setLoading(false);
            dispatch(LoginAction(data));
            if (data?.data?.role === 'marshalling') {
                window.location.href = '/entry-gate';
            } else if (data?.data?.role === 'automation') {
                window.location.href = '/data-entry';
            } else if (data?.data?.role === 'cattleAdmin') {
                window.location.href = '/app';
            } else if (data?.data?.role === 'cattleBank') {
                window.location.href = '/scan-receipt';
            } else if (data?.data?.role === 'landBooker') {
                window.location.href = '/land-booker';
            }
        } catch (error) {
            setLoading(false);
            setError(error?.response.data?.error?.message);
        }
    };

    const handleChange = (e) => {
        setValueForm({ ...valueForm, [e.target.name]: e.target.value });
        setError(null);
    };

    const roleWise = localStorage.getItem('role');

    return (
        <div className="root">
            <title>{title}</title>

            <div className={'container'}>
                <Grid container alignItems="center" direction="row" justify="center">
                    <Grid item container justify="center" className={'loginWrap'}>
                        <Hidden smDown>
                            <Grid item md={6} className={'welcomeWrap'}>
                                <div className={'welcome'}>
                                    <div className={'welcomeContent'}>
                                        <div className={'brand'}>
                                            <img src={logo} alt={brand.name} height="230px" />
                                        </div>
                                        <div className="brandText">
                                            Welcome to&nbsp;
                                            <strong>Asra Soft Cattle Mandi 2024&nbsp;</strong>
                                            KPI Dashboard
                                        </div>
                                    </div>
                                </div>
                            </Grid>
                        </Hidden>
                        <Grid item md={6} sm={8} xs={11}>
                            <LoginForm
                                error={error}
                                onSubmit={handleSubmit}
                                valueForm={valueForm}
                                onChange={handleChange}
                                loading={loading}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
};

export default Login;
