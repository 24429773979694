import { Button, CircularProgress, makeStyles } from '@material-ui/core';
import React from 'react';
import './ButtonGroup.scss';

const ButtonGroup = ({
    value,
    isLoad,
    customBtnBGColor,
    textColor,
    onClick,
    type,
    disabled,
    width,
    height,
    ...props
}) => {
    const useStyles = makeStyles((theme) => ({
        inputField: {
            '& .MuiButtonBase-root': {
                minWidth: width,
            },
        },
    }));

    const classes = useStyles();

    return (
        <div className="Button_Group">
            <Button
                type={type}
                disabled={disabled}
                {...props}
                onClick={onClick}
                className={[classes.inputField]}
                style={
                    customBtnBGColor
                        ? {
                              backgroundColor: customBtnBGColor,
                              color: textColor ? textColor : '#000',
                              width: width,
                              minWidth: width,
                              height: height,
                              fontFamily: 'Poppins',
                              borderTopLeftRadius: 0,
                              borderBottomLeftRadius: 0,
                          }
                        : {}
                }
            >
                {value}
                {isLoad && <CircularProgress color="inherit" size={20} />}
            </Button>
        </div>
    );
};

export default ButtonGroup;
