import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { AppBar, Button, Icon, InputAdornment } from '@material-ui/core';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
// import MenuIcon from '@material-ui/icons/Menu';
import UserMenu from './UserMenu';
import { Hidden } from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import SearchIcon from '@material-ui/icons/Search';

import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import { materialTheme } from './UserMenu';
import { ThemeProvider } from '@material-ui/styles';
import { useDispatch, useSelector } from 'react-redux';
import './style.scss';
import activeMEdiaLogo from 'assets/images/asraLogo2.png';
import logo from 'assets/images/asraLogo.png';

import { modifyDate } from '../../redux/actions/DateAction';
import MenuIcon from '../../../assets/images/menuIcon.svg';

const Header = ({ toggleDrawerOpen, margin, turnDarker, windowWidth }) => {
    const start = useSelector((state) => state.getIn(['date', 'startDate']));
    const end = useSelector((state) => state.getIn(['date', 'endDate']));
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [startDate, setStartDate] = useState(start);
    const [endDate, setEndDate] = useState(end);
    const [openPicker, setOpenPicker] = useState({ shown: false, nameOfPicker: '' });

    const handleStartDateChange = (date) => {
        setStartDate(date);
    };

    const handleEndDateChange = (date) => {
        setEndDate(date);
    };

    const handleApplyDates = () => {
        dispatch(modifyDate({ startDate, endDate }));
    };

    const handleClosePicker = () => {
        setOpenPicker({ shown: false, numOfPicker: '' });
    };

    return (
        <AppBar className={classNames('appBar', margin && 'appBarShift', 'appbar', turnDarker && 'darker')}>
            <Toolbar disableGutters={!open} className={'toolbarContainer'}>
                <div className={'toolbar'}>
                    <div className={'btnContainer'} style={!margin ? { marginLeft: 66 } : {}}>
                        <div className="menuButton" onClick={toggleDrawerOpen}>
                            <img src={MenuIcon} className="menu-icon" />
                        </div>
                        {/* <Hidden lgUp only={['sm', 'lg', 'md']}>
                            <div className="logo-container">
                                <img src={activeMEdiaLogo} className={'activeMediaBtn'} alt="active media btn" />
                            </div>
                        </Hidden> */}

                        {/* <Hidden lgUp only={['xs']}>
                            <div>
                                <img src={logo} className={'activeMediaBtn'} alt="active media btn" />
                            </div>
                        </Hidden> */}
                    </div>
                    <UserMenu />
                </div>
                <Hidden only={['xl', 'lg', 'md']}>
                    <div className={'dateContainer'}>
                        <div className={'dateColor'}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <ThemeProvider theme={materialTheme}>
                                    <DatePicker
                                        placeholder="From"
                                        variant="inline"
                                        views={['year', 'month', 'date']}
                                        maxDate={new Date()}
                                        // open={openPicker?.shown && openPicker?.nameOfPicker === 'from'}
                                        // onAccept={handleClosePicker}
                                        className={'date'}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                    // onClick={() => {
                                                    //     setOpenPicker({ shown: true, nameOfPicker: 'from' });
                                                    // }}
                                                    >
                                                        <CalendarTodayIcon className="calender-icon" />
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                        onChange={handleStartDateChange}
                                        value={startDate}
                                    />
                                </ThemeProvider>
                            </MuiPickersUtilsProvider>
                        </div>
                        <div className={'dateColor'}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <ThemeProvider theme={materialTheme}>
                                    <DatePicker
                                        placeholder="To"
                                        variant="inline"
                                        views={['year', 'month', 'date']}
                                        maxDate={new Date()}
                                        // open={openPicker?.shown && openPicker?.nameOfPicker === 'to'}
                                        // onAccept={handleClosePicker}
                                        className={'date'}
                                        onChange={handleEndDateChange}
                                        value={endDate}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                    // onClick={() => {
                                                    //     setOpenPicker({ shown: true, nameOfPicker: 'to' });
                                                    // }}
                                                    >
                                                        <CalendarTodayIcon className="calender-icon" />
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </ThemeProvider>
                            </MuiPickersUtilsProvider>
                        </div>
                        <Button variant="contained" onClick={handleApplyDates} className="search-btn">
                            <SearchIcon className="search-img" /> Search
                        </Button>
                    </div>
                </Hidden>
            </Toolbar>
        </AppBar>
    );
};

export default Header;
