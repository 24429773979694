import React, { useState } from 'react';
import { useEffect } from 'react';
import { PDFViewer, PDFDownloadLink } from '@react-pdf/renderer';
import PDF from 'app/components/PDF/PDF';
import { axiosInstance } from 'app/helpers';
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import { isMobile, isBrowser } from 'react-device-detect';
import Loader from 'app/components/loader/Loader';
import { Box } from '@material-ui/core';

const ViewReceipt = () => {
    const { id } = useParams();
    const [data, setData] = useState({});
    const [loader, setLoader] = useState(true);

    useEffect(() => {
        (async () => {
            try {
                const { data } = await axiosInstance.get('/cattleMandi/get-automation', {
                    params: { id },
                });
                setData(data.data);
            } catch (error) {
                console.log(error);
            } finally {
                setLoader(false);
            }
        })();
    }, []);

    if (loader) {
        return (
            <Box height="600px" display="flex" alignItems="center" justifyContent="center">
                <Loader />;
            </Box>
        );
    }

    return (
        <React.Fragment>
            {data !== null ? (
                <React.Fragment>
                    {isBrowser && (
                        <PDFViewer style={{ width: '100%', height: '100vh' }}>
                            <PDF data={data} id={id} />
                        </PDFViewer>
                    )}
                    {isMobile && (
                        <PDFDownloadLink style={{ width: '100%', height: '100vh' }}>
                            <PDF data={data} id={id} />
                        </PDFDownloadLink>
                    )}
                </React.Fragment>
            ) : (
                <h1 style={{ textAlign: 'center' }}>No Entry Found</h1>
            )}
        </React.Fragment>
    );
};

export default ViewReceipt;
