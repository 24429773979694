import React from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { Fade } from '@material-ui/core';
import CustomModal from '../Modal';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #7904d7',
    boxShadow: 24,
    p: 4,
    borderRadius: '10px',
};

const MarshallingSummaryModal = ({ open, handleClose, data }) => {
    const keysArray = [];
    const array = !!data?.vehicle_details?.length ? data?.vehicle_details : [];

    array?.forEach((obj) => {
        const keys = Object.keys(obj);
        keysArray.push(keys[0]);
    });

    return (
        <div>
            <CustomModal open={open} onClose={handleClose}>
                <Fade in={open}>
                    <Box sx={style}>
                        <Typography variant="h6" component="h3" align="center">
                            Marshalling Summary
                        </Typography>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Typography id="spring-modal-title" variant="h6" component="h4" sx={{ fontWeight: '500' }}>
                                Total Cow
                            </Typography>
                            <Typography id="spring-modal-title" variant="h6" component="h4">
                                {data?.animal_details?.total_cows ?? 0}
                            </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Typography id="spring-modal-title" variant="h6" component="h4" sx={{ fontWeight: '500' }}>
                                Total goat
                            </Typography>
                            <Typography id="spring-modal-title" variant="h6" component="h4">
                                {data?.animal_details?.total_goats ?? 0}
                            </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Typography id="spring-modal-title" variant="h6" component="h4" sx={{ fontWeight: '500' }}>
                                Total camel
                            </Typography>
                            <Typography id="spring-modal-title" variant="h6" component="h4">
                                {data?.animal_details?.total_camels ?? 0}
                            </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Typography id="spring-modal-title" variant="h6" component="h4" sx={{ fontWeight: '500' }}>
                                Total drums
                            </Typography>
                            <Typography id="spring-modal-title" variant="h6" component="h4">
                                {data?.total_drums ?? 0}
                            </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Typography id="spring-modal-title" variant="h6" component="h4" sx={{ fontWeight: '500' }}>
                                Total vehicle
                            </Typography>
                            <Typography id="spring-modal-title" variant="h6" component="h4">
                                {data?.total_vehicle_count ?? 0}
                            </Typography>
                        </Box>
                        {keysArray.map((item, index) => (
                            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Typography
                                    id="spring-modal-title"
                                    variant="h6"
                                    component="h4"
                                    sx={{ fontWeight: '500' }}
                                >
                                    {item}
                                </Typography>
                                <Typography id="spring-modal-title" variant="h6" component="h4">
                                    {array[index][item]}
                                </Typography>
                            </Box>
                        ))}
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                            <Button variant="contained" color="primary" onClick={handleClose}>
                                Close
                            </Button>
                        </Box>
                    </Box>
                </Fade>
            </CustomModal>
        </div>
    );
};

export default MarshallingSummaryModal;
