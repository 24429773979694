import React from 'react';
import { Page, Text, View, Document, StyleSheet, Image, Svg, Font } from '@react-pdf/renderer';

import JsBarcode from 'jsbarcode';
import logo from '../../../assets/images/cattle-mandi/logo.png';
import mandi from '../../../assets/images/cattle-mandi/mandi.png';
import asraLogo from '../../../assets/images/asraLogo2.png'
Font.register({
    family: 'Open Sans',
    fonts: [
        { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf' },
        { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf', fontWeight: 600 }
    ]
});
const styles = StyleSheet.create({
    page: {
        backgroundColor: '#fff',
        color: '#000',
        width: '100%',
        position: 'relative',
    },
    watermark: {
        position: 'absolute',
        width: '100px',
        height: '100px',
        opacity: 0.3,
        top: '55px',
        left: '98px'
    },
    logo: {
        height: 60,
        width: 60,
    },
    cvcHeader: {
        flexDirection: 'row',
        alignItems: 'center',
        padding: '0px 10px'
    },
    headerText: {
        flexDirection: 'column',
        alignItems: 'center',
        marginLeft: '5px'
    },
    text1: {
        fontFamily: 'Open Sans',
        fontWeight: 700,
        fontSize: '20px',
    },
    text2: {
        fontFamily: 'Open Sans',
        fontWeight: 700,
        fontSize: '18px',
    },
    lineBlack: {
        backgroundColor: '#000',
    },
    lineGrey: {
        backgroundColor: '#636363',
    },
    lineLightGrey: {
        backgroundColor: '#ccc',
    },
    line: {
        height: 2,
        width: '100%',
        marginVertical: 0.5,
    },
    pvc: {
        flexDirection: 'column',
        backgroundColor: '#ffffff',
    },
    backgroundImage: {
        position: 'absolute',
        width: '100%',
        alignItems: 'start',
    },
    picture: {
        padding: '0px 5px',
        width: 85,
        height: 80,
        marginBottom: 5,
    },
    dateView: {
        padding: '0px 5px',
        alignItems: 'flex-end'
    },
    normalText: {
        fontFamily: 'Open Sans',
        fontWeight: 700,
        fontSize: '12px'
    },
    cvcBody: {
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    asarLogo: {
        width: 70,
        height: 73,
        marginRight: '5px'
    },
    picAndText: {
        flexDirection: 'row'
    },
    barcodeViewStyle:{
        display:'flex',
        alignItems:'center',
        alignContent:'center'
    }
});

const PvcCard = ({ data, id }) => {
    const canvas = document.createElement('canvas');

    JsBarcode(canvas, id, {
        displayValue: false,
    });

    const barcodeDataURL = canvas.toDataURL();
    // mix-blend-mode: multiply;
    return (
        <Document >
            <Page size="A7" orientation='landscape' style={styles.page}>
                <Image src={mandi} style={styles.watermark} />
                <View style={styles.pvc}>
                    <View style={styles.cvcHeader}>
                        <Image src={logo} style={styles.logo} />
                        <View style={styles.headerText}>
                            <Text style={styles.text1}>Cattle mandi 2024</Text>
                            <Text style={styles.text2}>Northern Bypass</Text>
                        </View>
                        <View></View>
                    </View>
                    <View style={[styles.line, styles.lineBlack]} />
                    <View style={[styles.line, styles.lineGrey]} />
                    <View style={[styles.line, styles.lineLightGrey]} />
                    <View style={styles.dateView}>
                        <Text style={styles.normalText}>Date: 15-05-2024</Text>
                    </View>
                    <View style={styles.cvcBody}>
                        <View style={styles.picAndText}>
                            <Image src={data.picture} style={styles.picture} alt="picture" cache={false} />
                            <View>
                                <Text style={styles.normalText}>{`Name: ${'Saddam Hussain'}`}</Text>
                                <Text style={styles.normalText}>{`CNIC: ${data.cnic}`}</Text>
                                <Text style={styles.normalText}>{`Phone: ${data.contactNo}`}</Text>
                                <Text style={styles.normalText}>{`Block: ${'Sapphire (VIP)'}`}</Text>
                                <Text style={styles.normalText}>{`Plot: ${'88'}`}</Text>
                            </View>
                        </View>
                        <Image src={asraLogo} style={styles.asarLogo} />
                    </View>
                    <View style={styles.barcodeViewStyle}>
                        <Image src={barcodeDataURL} style={{ width: '150px', height: 35 }} />
                    </View>
                </View> 
            </Page>
        </Document>
    );
};

export default PvcCard;
