import React from 'react';
import classNames from 'classnames';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Fade from '@material-ui/core/Fade';
import { Header, Sidebar } from './../../components';
import { toggleAction, openAction, playTransitionAction } from '../../redux/actions/UiActions';
import styles from './appStyles-jss';

class Dashboard extends React.Component {
    state = {
        transform: 0,
        windowWidth: window.innerWidth,
    };

    componentDidMount = () => {
        // Scroll content to top
        const mainContent = document.getElementById('mainContent');
        mainContent.addEventListener('scroll', this.handleScroll);

        // Play page transition
        this.props.loadTransition(true);
    };

    componentWillUnmount() {
        const mainContent = document.getElementById('mainContent');
        mainContent.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll = (event) => {
        const scoll = event.target.scrollTop;
        this.setState({
            transform: scoll,
        });
    };

    componentDidMount() {
        window.addEventListener('resize', this.handleResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize = () => {
        this.setState({
            windowWidth: window.innerWidth,
        });
    };

    render() {
        const { classes, toggleDrawer, sidebarOpen, loadTransition, pageLoaded, children } = this.props;

        const darker = true;

        return (
            <div className={classes.appFrameInner}>
                <Header
                    toggleDrawerOpen={toggleDrawer}
                    turnDarker={this.state.transform > 30 && darker}
                    margin={sidebarOpen}
                    windowWidth={this.state.windowWidth}
                />
                <Sidebar
                    open={sidebarOpen}
                    loadTransition={loadTransition}
                    turnDarker={this.state.transform > 30 && darker}
                    toggleDrawerOpen={toggleDrawer}
                    windowWidth={this.state.windowWidth}
                />
                <main className={classNames(classes.content, !sidebarOpen && classes.contentPadding)} id="mainContent">
                    <div className={classes.bgbar} />
                    <section className={classes.mainWrap}>
                        {!pageLoaded && (
                            <img src="/images/spinner.gif" alt="spinner" className={classes.circularProgress} />
                        )}
                        <Fade in={pageLoaded} mountOnEnter unmountOnExit {...(pageLoaded ? { timeout: 700 } : {})}>
                            <div className={!pageLoaded ? classes.hideApp : ''}>{children}</div>
                        </Fade>
                    </section>
                </main>
            </div>
        );
    }
}

const reducer = 'ui';
const mapStateToProps = (state) => ({
    sidebarOpen: state.getIn([reducer, 'sidebarOpen']),
    pageLoaded: state.getIn([reducer, 'pageLoaded']),
});

const mapDispatchToProps = (dispatch) => ({
    toggleDrawer: () => dispatch(toggleAction),
    initialOpen: bindActionCreators(openAction, dispatch),
    loadTransition: bindActionCreators(playTransitionAction, dispatch),
});

const DashboardMaped = connect(mapStateToProps, mapDispatchToProps)(Dashboard);

export default withStyles(styles)(DashboardMaped);

// const Dashboard=()=>{
//   const[tranform,setTransform]=useState(0)

//   useEffect(() => {
//     const mainContent = document.getElementById("mainContent");
//     mainContent.addEventListener('scroll',handleScroll);
//     props.loadTransition(true)
//   });

//  const handleScroll = (event) => {
//     const scoll = event.target.scrollTop;
//     this.setState({
//       transform: scoll,
//     });
//   };

//   const {
//     classes,
//     toggleDrawer,
//     sidebarOpen,
//     loadTransition,
//     pageLoaded,
//     children,
//   } = props;

//   const darker = true;
//   return(
//     <div className={classes.appFrameInner}>
//         <Header
//           toggleDrawerOpen={toggleDrawer}
//           turnDarker={transform > 30 && darker}
//           margin={sidebarOpen}
//         />
//         <Sidebar
//           open={sidebarOpen}
//           loadTransition={loadTransition}
//           turnDarker={transform > 30 && darker}
//           toggleDrawerOpen={toggleDrawer}
//         />
//         <main
//           className={classNames(
//             classes.content,
//             !sidebarOpen && classes.contentPadding
//           )}
//           id="mainContent"
//         >
//           <div className={classes.bgbar} />
//           <section className={classes.mainWrap}>
//             {!pageLoaded && (
//               <img
//                 src="/images/spinner.gif"
//                 alt="spinner"
//                 className={classes.circularProgress}
//               />
//             )}
//             <Fade
//               in={pageLoaded}
//               mountOnEnter
//               unmountOnExit
//               {...(pageLoaded ? { timeout: 700 } : {})}
//             >
//               <div className={!pageLoaded ? classes.hideApp : ""}>
//                 {children}
//               </div>
//             </Fade>
//           </section>
//         </main>
//       </div>

//   )
//   }
