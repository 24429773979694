import React, { useState } from 'react';
import Heading from 'app/helpers/Heading';
import './style.scss';
import Input from 'app/components/Input/Input';
import ReuseableSelect from 'app/components/Select/Select';
import ButtonGroup from 'app/components/ButtonGroup/ButtonGroup';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Grid } from '@material-ui/core';
import { listOfVehicals } from 'app/helpers';
import { axiosInstance, errorHandler } from 'app/helpers';
import { setAlert } from 'app/redux/actions/alertAction';
import { useDispatch } from 'react-redux';

const EntryGateMarshalling = () => {
    const [isLoad, setIsLoad] = useState(false);
    const dispatch = useDispatch();

    const formik = useFormik({
        initialValues: {
            name: '',
            numberOfCows: 0,
            numberOfCamels: 0,
            numberOfGoats: 0,
            NicNo: '',
            categoryOfVehicle: [],
            vehicleCount: 0,
            NoOfVehicle: [],
        },
        validationSchema: Yup.object().shape({
            name: Yup.string().required('required'),
            numberOfCows: Yup.string().required('required'),
            numberOfCamels: Yup.string().required('required'),
            numberOfGoats: Yup.string().required('required'),
            categoryOfVehicle: Yup.array()
                .min(1, 'required')
                .required(),
            NicNo: Yup.string().required('required'),
            vehicleCount: Yup.string().required('required'),
            NoOfVehicle: Yup.array()
                .min(1, 'required')
                .required(),
        }),
        onSubmit: async (values) => {
            const categoryVehicleArray = totalCountVehicle.map((item, index) => {
                return {
                    vehicle_category_id: formik.values.categoryOfVehicle[index] || '',
                    vehicle_number: formik.values.NoOfVehicle[index] || '',
                };
            });
            setIsLoad(true);
            try {
                const { data } = await axiosInstance.post('/cattleMandi/create-marshalling', {
                    name_of_owner: values.name,
                    vehicles: categoryVehicleArray,
                    no_of_cows: values.numberOfCows,
                    no_of_camels: values.numberOfCamels,
                    no_of_goats: values.numberOfGoats,
                    cnic: values.NicNo,
                });
                setIsLoad(false);
                formik.resetForm();
                dispatch(
                    setAlert({
                        isOpen: true,
                        type: 'success',
                        message: data?.data?.message,
                    })
                );

                setTimeout(() => {
                    dispatch(setAlert({}));
                }, 5000);
            } catch (error) {
                dispatch(
                    setAlert({
                        isOpen: true,
                        type: 'error',
                        message: errorHandler(error),
                    })
                );
                setTimeout(() => {
                    dispatch(setAlert({}));
                }, 5000);
                setIsLoad(false);
            }
        },
    });
    const formatNIC = (value) => {
        if (value && value.length === 13) {
            return `${value.substring(0, 5)}-${value.substring(5, 12)}-${value.charAt(12)}`;
        }
        return value;
    };
    const handleNumberKeyDown = (e) => {
        if (e.key === 'e' || e.key === '-') {
            e.preventDefault();
        }
    };

    const totalCountVehicle = Array.from({ length: parseInt(formik.values.vehicleCount) }, (_, index) => index + 1);

    return (
        <div className="DataEntry_Container">
            <Heading heading={'Entry Gate Marshalling'} />

            <div className="DataEntry_Content_Container">
                <div className="DataEntry_Content_card">
                    <form
                        onSubmit={(e) => {
                            e.preventDefault();
                            formik.submitForm();
                        }}
                    >
                        <div>
                            <Grid container spacing={3}>
                                <Grid item xl={6} lg={6} md={6} xs={12} sm={12} className="TextInput_Main">
                                    <label>Name</label>
                                    <Input
                                        type="text"
                                        name="name"
                                        value={formik.values.name}
                                        onChange={formik.handleChange}
                                        className="TextInput"
                                        error={formik.touched.name && formik.errors.name}
                                        placeholder="Type Here"
                                    />
                                    {formik.touched.name && formik.errors.name && (
                                        <div className="error">{formik.errors.name}</div>
                                    )}
                                </Grid>
                                <Grid item xl={6} lg={6} md={6} xs={12} sm={12} className="TextInput_Main">
                                    <label>CNIC No.</label>
                                    <Input
                                        id="NicNo"
                                        type="text"
                                        onKeyDown={handleNumberKeyDown}
                                        inputProps={{
                                            inputMode: 'numeric',
                                        }}
                                        placeholder="Type Here"
                                        name="NicNo"
                                        value={formik.values.NicNo}
                                        onChange={formik.handleChange}
                                        className="TextInput"
                                        error={formik.touched.NicNo && formik.errors.NicNo}
                                    />
                                </Grid>

                                <Grid item xl={6} lg={6} md={4} xs={12} sm={12} className="TextInput_Main">
                                    <label>No. Of Cows</label>
                                    <Input
                                        type="number"
                                        name="numberOfCows"
                                        value={formik.values.numberOfCows}
                                        onChange={formik.handleChange}
                                        className="TextInput"
                                        error={formik.touched.numberOfCows && formik.errors.numberOfCows}
                                        placeholder="Type Here"
                                        onKeyDown={handleNumberKeyDown}
                                        inputProps={{
                                            inputMode: 'numeric',
                                        }}
                                    />

                                    {formik.touched.numberOfCows && formik.errors.numberOfCows && (
                                        <div className="error">{formik.errors.numberOfCows}</div>
                                    )}
                                </Grid>

                                <Grid item xl={6} lg={6} md={4} xs={12} sm={12} className="TextInput_Main">
                                    <label>No. Of Camels</label>
                                    <Input
                                        type="number"
                                        name="numberOfCamels"
                                        value={formik.values.numberOfCamels}
                                        onChange={formik.handleChange}
                                        className="TextInput"
                                        error={formik.touched.numberOfCamels && formik.errors.numberOfCamels}
                                        placeholder="Type Here"
                                        onKeyDown={handleNumberKeyDown}
                                        inputProps={{
                                            inputMode: 'numeric',
                                        }}
                                    />
                                    {formik.touched.numberOfCamels && formik.errors.numberOfCamels && (
                                        <div className="error">{formik.errors.numberOfCamels}</div>
                                    )}
                                </Grid>

                                <Grid item xl={6} lg={6} md={4} xs={12} sm={12} className="TextInput_Main">
                                    <label>Vehicle Count</label>
                                    <Input
                                        type="number"
                                        name="vehicleCount"
                                        value={formik.values.vehicleCount}
                                        onChange={formik.handleChange}
                                        className="TextInput"
                                        error={formik.touched.vehicleCount && formik.errors.vehicleCount}
                                        placeholder="Type Here"
                                        onKeyDown={handleNumberKeyDown}
                                        inputProps={{
                                            inputMode: 'numeric',
                                        }}
                                    />
                                    {formik.touched.vehicleCount && formik.errors.vehicleCount && (
                                        <div className="error">{formik.errors.vehicleCount}</div>
                                    )}
                                </Grid>

                                <Grid item xl={6} lg={6} md={4} xs={12} sm={12} className="TextInput_Main">
                                    <label>No. Of Goats</label>
                                    <Input
                                        type="number"
                                        name="numberOfGoats"
                                        value={formik.values.numberOfGoats}
                                        onChange={formik.handleChange}
                                        className="TextInput"
                                        error={formik.touched.numberOfGoats && formik.errors.numberOfGoats}
                                        placeholder="Type Here"
                                        onKeyDown={handleNumberKeyDown}
                                        inputProps={{
                                            inputMode: 'numeric',
                                        }}
                                    />
                                    {formik.touched.numberOfGoats && formik.errors.numberOfGoats && (
                                        <div className="error">{formik.errors.numberOfGoats}</div>
                                    )}
                                </Grid>

                                {totalCountVehicle.map((item, index) => {
                                    return (
                                        <>
                                            <Grid
                                                key={index}
                                                item
                                                xl={6}
                                                lg={6}
                                                md={4}
                                                xs={12}
                                                sm={12}
                                                className="TextInput_Main"
                                            >
                                                <label>Category of Vehicle</label>
                                                <ReuseableSelect
                                                    key={index}
                                                    style={{ width: '100%' }}
                                                    name="categoryOfVehicle"
                                                    formControlClassName="demo-customized-select"
                                                    className={`TextInput select_error`}
                                                    // formik={formik}
                                                    id={index}
                                                    value={formik.values.categoryOfVehicle[index]}
                                                    onChange={(e) => {
                                                        const newValue = e.target.value;
                                                        const newCategoryOfVehicle = [
                                                            ...formik.values.categoryOfVehicle,
                                                        ];
                                                        newCategoryOfVehicle[index] = newValue;
                                                        console.log({ newCategoryOfVehicle });
                                                        formik.setFieldValue('categoryOfVehicle', newCategoryOfVehicle);
                                                    }}
                                                    error={
                                                        formik.touched.categoryOfVehicle &&
                                                        formik.errors.categoryOfVehicle
                                                    }
                                                    optionList={listOfVehicals}
                                                />
                                                {formik.touched.categoryOfVehicle &&
                                                    formik.errors.categoryOfVehicle && (
                                                        <div className="error">{formik.errors.categoryOfVehicle}</div>
                                                    )}
                                            </Grid>

                                            <Grid
                                                key={index}
                                                item
                                                xl={6}
                                                lg={6}
                                                md={4}
                                                xs={12}
                                                sm={12}
                                                className="TextInput_Main"
                                            >
                                                <label>No. Of Vehicle</label>
                                                <Input
                                                    type="text"
                                                    key={index}
                                                    id={index}
                                                    name="NoOfVehicle"
                                                    value={formik.values.NoOfVehicle[index]}
                                                    onChange={(e) => {
                                                        const newValue = e.target.value;
                                                        const currentIndex = index;
                                                        let newNoOfVehicle = formik.values.NoOfVehicle || [];
                                                        newNoOfVehicle[currentIndex] = newValue;
                                                        console.log({ newNoOfVehicle });
                                                        formik.setFieldValue('NoOfVehicle', newNoOfVehicle);
                                                    }}
                                                    className="TextInput"
                                                    error={formik.touched.NoOfVehicle && formik.errors.NoOfVehicle}
                                                    placeholder="Type Here"
                                                    // onKeyDown={handleNumberKeyDown}
                                                    inputProps={{
                                                        inputMode: 'numeric',
                                                    }}
                                                />
                                                {formik.touched.NoOfVehicle && formik.errors.NoOfVehicle && (
                                                    <div className="error">{formik.errors.NoOfVehicle}</div>
                                                )}
                                            </Grid>
                                        </>
                                    );
                                })}

                                <Grid item xl={12} md={12} xs={12} sm={12} className="TextInput_Main">
                                    <div className="submit-btn">
                                        <ButtonGroup
                                            isLoad={isLoad}
                                            disabled={isLoad}
                                            value="Submit"
                                            type="submit"
                                            variant="contained"
                                            customBtnBGColor="#7904d7"
                                            textColor="#fff"
                                        />
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default EntryGateMarshalling;
