import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import 'app/styles/Tables.scss';
import {
    Table,
    TableBody,
    TableCell,
    TableRow,
    Paper,
    TableHead,
    Checkbox,
} from '@material-ui/core';
import tableStyles from 'app/styles/components/Table.scss';
import EnhancedTableToolbar from '../TableToolbar';
import { EditRecord } from '../EditRecord';
import { DeleteRecord } from '../DeleteRecord';
import { axiosInstance, errorHandler, supervisorSearchParam } from 'app/helpers';
import { setAlert } from 'app/redux/actions/alertAction';
import { useDispatch } from 'react-redux';
import ViewMonthlyRecord from '../ViewMonthlyRecord';
import Deposits from '../Deposits';
import Loader from '../../loader/Loader';
import { useHistory } from 'react-router-dom';
import RenderTableRow from '../RenderTableRow';
import TablePagination from '../Pagination';


const styles = (theme) => ({
    root: {
        width: '100%',
        marginTop: theme.spacing.unit * 3,
    },
    table: {
        minWidth: 1020,
    },
    tableWrapper: {
        overflowX: 'auto',
    },

    image: {
        height: '50px',
        width: '50px',
    },
});

const editableFields = ['phone_number', 'name'];
const imageFields = ['shop_image', 'product_image'];

const findName = [
    'Marshalling Records Table',
    'Paid Marshalling Records Table',
    'unpaid Marshalling Records Table',
    'Paid Automation Records Table',
    'Unpaid Automation Records Table',
    'Bank Records Table',
    'Unpaid Land Booker Record Table',
    'Paid Land Booker Record Table',
];

const CattleMandiTable = ({
    data,
    page,
    rowsPerPage,
    classes,
    name,
    download,
    actionCell,
    filterText,
    showDetails,
    link,
    CheckBox,
    selected,
    setSelected,
    trialUser,
    editRecord,
    fetchDDSDetails,
    supervisorId,
    dataCount,
    financeAction,
    depositPerMonth,
    viewOpen,
    setViewOpen,
    viewDetail,
    viewLoading,
    uploadDeposit,
    isLoad,
    section,
    isDontTriggered,
    CheckBoxShow,
    mtCell,
    customerSearch,
    getId,
    setGetid,
    showSearch,
    downloadLoading,
    tableName,
    showLiveTracking,
    cattleMandiTableHead,
    renderCell,
    showAutmationUserButton,
    showAutmationAdminButtons,
    showMarshallingAdminButtons,
    clearSearch,
    showClearIcon,
    showLandBookerViewButton,
    renderCollapse,
    showCollapse,
}) => {
    const [open, setOpen] = useState(false);
    const [pageState, setPage] = useState(isDontTriggered ? page + 1 : page);
    const [rowsPerPageState, setRowsPerPage] = useState(rowsPerPage, data.length);
    const [editUserIndex, setEditUserIndex] = useState(null);
    const [allData, setAllData] = useState(data);
    const [editUpdateData, setEditUpdateData] = useState(data);
    const [indexState, setIndexState] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [ddsCount, setDDSCount] = useState(0);
    const [viewOpenDeposit, setViewOpenDeposit] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [selectedDataForDelete, setSelectedDataForDelete] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isNotShowCheck, setIsNotShowCheck] = useState(false);
    const [isDelete, setIsDelete] = useState(false);
    const dispatch = useDispatch();
    const count = dataCount ? dataCount : allData?.length;
    const history = useHistory();
    const [speed, setSpeed] = useState(1.0);

    const handleSpeedChange = (speed) => {
        setSpeed(speed);
    };

    const tableHiddenData = {
        deals: 'deals',
        updated_at: 'updated_at',
        otp: 'otp',
        coordinates: 'coordinates',
        activity_id: 'activity_id',
        password: 'password',
        image: 'image',
        role: 'role',
    };

    function formatDate(date) {
        const hours = date.getHours();
        const minutes = date.getMinutes();
        const ampm = hours >= 12 ? 'PM' : 'AM';

        // Convert hours from 24-hour format to 12-hour format
        const formattedHours = hours % 12 || 12;

        // Add leading zero if minutes is less than 10
        const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;

        return `${formattedHours}:${formattedMinutes} ${ampm}`;
    }

    useEffect(() => {
        if (
            !supervisorId &&
            findName.includes(name) &&
            rowsPerPageState * (pageState + 1) >= ddsCount + 25 &&
            ddsCount + 25 <= count
        ) {
            (async () => {
                try {
                    setIsLoading(true);
                    setDDSCount((prev) => prev + 25);

                    const data = await fetchDDSDetails(ddsCount + 25);
                    if (data?.state || data?.state === 'error') {
                        throw new Error(data.msg);
                    }

                    data && setAllData((prev) => [...prev, ...data]);
                } catch (error) {
                    dispatch(
                        setAlert({
                            isOpen: true,
                            type: 'error',
                            message: errorHandler(error),
                        })
                    );
                } finally {
                    setIsLoading(false);
                }
            })();
        }
    }, [rowsPerPageState, pageState, ddsCount]);

    const handleChangePage = (e, page) => {
        const end = rowsPerPageState * pageState;
        !isLoading && setPage(page);
        if (end === count) {
            setRowsPerPage(5);
        }
    };

    const handleChangeRowsPerPage = (event) => {
        setPage(1);
        !isLoading && setRowsPerPage(event.target.value);
    };

    const toggleDeleteModal = () => setShowModal(!showModal);

    const changeForMateOfCreatedAtAllUsers = (key, value) => {
        if (value === '') {
            return '';
        }

        if (key === 'createdAt' && value) {
            const created_at = new Date(value)?.toISOString()?.split('T')[0];
            return <div dangerouslySetInnerHTML={{ __html: `${created_at}<br>${formatDate(new Date(value))}` }} />;
        }

        if (key === 'updatedAt' && value) {
            const DOB = new Date(value)?.toISOString()?.split('T')[0];
            return DOB;
        }
    };

    const handleClickOpen = (event, value) => {
        setEditUserIndex(value.id);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setViewOpen(false);
        setViewOpenDeposit(false);
    };

    const handleChangeEditUser = (event) => {
        let cloneAllData = [...editUpdateData];
        const selectedDataIndex = cloneAllData?.findIndex((item) => item?.id === editUserIndex);

        setIndexState(selectedDataIndex);

        cloneAllData[selectedDataIndex] = {
            ...cloneAllData[selectedDataIndex],
            [event.target.name]: event.target.value,
        };
        setEditUpdateData(cloneAllData);
    };

    const handleUpdateUser = async () => {
        const user = editUpdateData[indexState];
        try {
            await axiosInstance.put('/users/update-user', {
                id: user.id,
                name: user.name,
                phone_number: user.phone_number,
            });
            setAllData(editUpdateData);
            dispatch(
                setAlert({
                    isOpen: true,
                    type: 'success',
                    message: 'User updated Successfully',
                })
            );
            handleClose();
        } catch (error) {
            dispatch(
                setAlert({
                    isOpen: true,
                    type: 'error',
                    message: errorHandler(error),
                })
            );
            handleClose();
        }
    };

    const handleCheck = (e, value) => {
        if (!selected) return;
        const selectedIndex = selected?.indexOf(value);
        let newSelected = [];

        // item not already present
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, value);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected?.slice(1));
        } else if (selectedIndex === selected?.length - 1) {
            newSelected = newSelected.concat(selected?.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected?.slice(0, selectedIndex), selected?.slice(selectedIndex + 1));
        }

        setSelected(newSelected);
    };

    const handleDelete = async () => {
        try {
            setIsDelete(true);
            let cloneAllData = [...allData];
            const deletes = cloneAllData.filter((val) => !selected.includes(val));
            await axiosInstance.delete('/targets/delete-trial', {
                data: {
                    trial_data: selectedDataForDelete,
                },
            });
            setAllData(deletes);
            setSelected([]);
            dispatch(
                setAlert({
                    isOpen: true,
                    type: 'success',
                    message: 'Deleted Successfully',
                })
            );
            toggleDeleteModal();
        } catch (error) {
            dispatch(
                setAlert({
                    isOpen: true,
                    type: 'error',
                    message: errorHandler(error),
                })
            );
            toggleDeleteModal();
            handleClose();
        } finally {
            setIsDelete(false);
        }
    };

    const handleViewProfile = (data) => {
        history.push(`/update-profile/${data?.id}`);
    };
    const handleViewLocation = (data) => {
        history.push(`/live-tracking/${data?.id}`);
    };

    const checkBoxHandler = (item) => {
        const { audio, id } = item;

        if (!selected?.includes(item)) {
            if (!selectedDataForDelete.some((ele) => ele.id === id)) {
                selectedDataForDelete.push({ audio, id });
            }
        }

        if (selected?.includes(item) && selectedDataForDelete.some((ele) => ele.id === id)) {
            let res = selectedDataForDelete.filter((ele, i) => ele.id !== id);
            setSelectedDataForDelete(res);
        }
    };

    useEffect(() => {
        const end = rowsPerPageState * pageState;
        const start = rowsPerPageState * (pageState - 1) + 1;
        if (allData) {
            setTableData([]);
            let filterData = allData.filter(
                (i, index) =>
                    index + 1 > (pageState - 1) * rowsPerPageState && index + 1 <= pageState * rowsPerPageState
            );
            setTableData(filterData);
        }
    }, [pageState * rowsPerPageState, (pageState + 1) * rowsPerPageState, allData]);

    const getPaginationInfo = () => {
        const start = rowsPerPageState * (pageState - 1) + 1;

        const end = rowsPerPageState * pageState;

        return (
            <span style={{ fontFamily: 'Poppins' }}>
                Showing {start} to {end > count ? count : end} of {count} items
            </span>
        );
    };

    const openDeposit = () => setViewOpenDeposit(true);

    return (
        <Paper className={classes.root}>
            {isLoad ? (
                <div>
                    <Loader color="#000" height="50vh" />
                </div>
            ) : (
                <>
                    <EnhancedTableToolbar
                        name={name}
                        download={download}
                        numSelected={selected?.length}
                        toggleDeleteModal={toggleDeleteModal}
                        uploadDeposit={uploadDeposit}
                        openDeposit={openDeposit}
                        showSearch={showSearch}
                        getId={getId}
                        setGetid={setGetid}
                        customerSearch={customerSearch}
                        handleSpeedChange={handleSpeedChange}
                        speed={speed}
                        downloadLoading={downloadLoading}
                        clearSearch={clearSearch}
                        showClearIcon={showClearIcon}
                    />
                    <div className={classes.tableWrapper}>
                        <Table sx={{ minWidth: 1000 }} className={classNames(classes.table, tableStyles.stripped)}>
                            <TableHead>
                                <TableRow>
                                    {mtCell && <TableCell></TableCell>}

                                    {cattleMandiTableHead.map((item, index) => {
                                        return (
                                            <TableCell style={{ fontFamily: 'Poppins' }} key={index}>
                                                {item.name}
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {!!tableData?.length ? (
                                    tableData?.map((value, index) => {
                                        return (
                                            <>
                                                <RenderTableRow
                                                    key={index}
                                                    index={index}
                                                    selected={selected}
                                                    isNotShowCheck={isNotShowCheck}
                                                    Checkbox={Checkbox}
                                                    value={value}
                                                    classes={classes}
                                                    handleCheck={handleCheck}
                                                    name={name}
                                                    setIsNotShowCheck={setIsNotShowCheck}
                                                    editUserIndex={editUserIndex}
                                                    renderCell={renderCell}
                                                    supervisorSearchParam={supervisorSearchParam}
                                                    link={link}
                                                    handleClickOpen={handleClickOpen}
                                                    showDetails={showDetails}
                                                    editRecord={editRecord}
                                                    financeAction={financeAction}
                                                    actionCell={actionCell}
                                                    handleViewProfile={handleViewProfile}
                                                    depositPerMonth={depositPerMonth}
                                                    checkBoxHandler={checkBoxHandler}
                                                    CheckBoxShow={CheckBoxShow}
                                                    checkBox={CheckBox}
                                                    showLiveTracking
                                                    handleViewLocation={handleViewLocation}
                                                    showAutmationButton={value?.paymentStatus === 'paid'}
                                                    showAutmationUserButton={showAutmationUserButton}
                                                    showAutmationAdminButtons={showAutmationAdminButtons}
                                                    showMarshallingAdminButtons={showMarshallingAdminButtons}
                                                    showLandBookerViewButton={showLandBookerViewButton}
                                                    renderCollapse={renderCollapse}
                                                    showCollapse={showCollapse}
                                                />
                                            </>
                                        );
                                    })
                                ) : (
                                    <></>
                                )}
                            </TableBody>
                        </Table>
                    </div>
                    <TablePagination
                        getPaginationInfo={getPaginationInfo}
                        data={allData}
                        rowsPerPageState={rowsPerPageState}
                        handleChangeRowsPerPage={handleChangeRowsPerPage}
                        handleChangePage={handleChangePage}
                        pageState={pageState}
                    />
                    {!!editUserIndex && (
                        <EditRecord
                            open={open}
                            data={editUpdateData}
                            handleChangeEditUser={handleChangeEditUser}
                            editableFields={editableFields}
                            handleUpdateUser={handleUpdateUser}
                            editUserIndex={editUserIndex}
                            handleClose={handleClose}
                        />
                    )}
                    {!!showModal && (
                        <DeleteRecord
                            open={() => setOpen(true)}
                            handleClose={toggleDeleteModal}
                            handleDelete={handleDelete}
                            loading={isDelete}
                        />
                    )}
                    {viewOpen && (
                        <ViewMonthlyRecord
                            open={viewOpen}
                            handleClose={handleClose}
                            viewDetail={viewDetail}
                            viewLoading={viewLoading}
                        />
                    )}
                    {viewOpen && (
                        <ViewMonthlyRecord
                            open={viewOpen}
                            handleClose={handleClose}
                            viewDetail={viewDetail}
                            viewLoading={viewLoading}
                        />
                    )}
                    {viewOpenDeposit && (
                        <Deposits
                            open={viewOpenDeposit}
                            handleClose={handleClose}
                            setViewOpenDeposit={setViewOpenDeposit}
                        />
                    )}
                </>
            )}
        </Paper>
    );
};

export default withStyles(styles)(CattleMandiTable);
