import React, { Fragment, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { Hidden, Drawer, SwipeableDrawer, List, Divider } from '@material-ui/core';
import brand from 'app/utils/brand';
import ASlogo from 'assets/images/asraLogo3.png';
import ASLogoWithName from 'assets/images/asraLogo2.png';
import logo from 'assets/images/cattle-mandi/mandiLogoMain.webp';
import logo_two from 'assets/images/asraLogo3.png';
import MainMenu from './MainMenu';
import { OtherMenu } from './OtherMenu';
import styles from './sidebar-jss';
import Dialog from '@material-ui/core/Dialog';
import { showNflBrand } from 'app/helpers';

const MenuContent = ({ classes, drawerPaper, toggleDrawerOpen, loadTransition, windowWidth }) => (
    <div className={classNames(classes.drawerInner, !drawerPaper ? classes.drawerPaperClose : '')}>
        <div className={!!drawerPaper ? classes.drawerHeader : classes.drawerHeader_two}>
            <img
                src={showNflBrand ? logo : ASLogoWithName}
                alt={'asrasoft'}
                className={!!drawerPaper ? classes.activeMediaLogo : classes.activeMediaLogo_two}
            />
        </div>
        <div className={classes.menuContainer}>
            <div>
                <MainMenu loadTransition={loadTransition} toggleDrawerOpen={toggleDrawerOpen} />
                <Divider className={classes.divider} />
                <List>
                    <OtherMenu toggleDrawerOpen={toggleDrawerOpen} windowWidth={windowWidth} />
                </List>
            </div>
        </div>
        <div className={!!drawerPaper ? classes.heading : classes.menuClosed}>
            {!!drawerPaper && <p className={classes.headingText}>Powered by Asra Soft</p>}
            <div>
                <img src={ASlogo} style={{ height: '25px' }} />
            </div>
        </div>
    </div>
);

const MenuContentStyle = withStyles(styles)(MenuContent);

const Sidebar = ({ classes, open, toggleDrawerOpen, turnDarker, windowWidth }) => {
    const [anchor] = useState('left');
    return (
        <Fragment>
            <Hidden lgUp>
                <SwipeableDrawer onClose={toggleDrawerOpen} onOpen={toggleDrawerOpen} open={!open} anchor={anchor}>
                    <MenuContentStyle drawerPaper toggleDrawerOpen={toggleDrawerOpen} windowWidth={windowWidth} />
                </SwipeableDrawer>
            </Hidden>
            <Hidden mdDown>
                <Drawer
                    variant="permanent"
                    onClose={toggleDrawerOpen}
                    classes={{
                        paper: classNames(classes.drawer, classes.drawerPaper, !open ? classes.drawerPaperClose : ''),
                    }}
                    open={open}
                    anchor={anchor}
                >
                    <MenuContentStyle
                        drawerPaper={open}
                        turnDarker={turnDarker}
                        toggleDrawerOpen={toggleDrawerOpen}
                        windowWidth={windowWidth}
                    />
                </Drawer>
            </Hidden>
        </Fragment>
    );
};

export default withStyles(styles)(Sidebar);
