import React from 'react';
import Hidden from '@material-ui/core/Hidden';
import { brand } from 'app/utils/brand';
import logo from 'assets/images/loginPageLogo.png';
import Login from './../Users/Login';
import './style.scss';

const LoginDedicated = () => {
    return (
        <div className="appFrameOuter">
            <main className="outerContent" id="mainContent">
                <Hidden mdUp>
                    <div className="brand">
                        <img src={logo} alt={brand.name} height={130} />
                        <div className="brandText" style={{ textAlign: 'center' }}>
                            Welcome to &nbsp;
                            <strong>Asrasoft Cattle Mandi&nbsp;</strong>
                            KPI Dashboard
                        </div>
                    </div>
                </Hidden>
                <Login />
            </main>
        </div>
    );
};

export default LoginDedicated;
