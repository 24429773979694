import React from 'react';
import CountUp from 'react-countup';
import './TwoSideWidget.scss';
import { customColors } from '../../../../assets/colors';
import CardGiftcardIcon from '@material-ui/icons/CardGiftcard';
import Loader from 'app/components/loader/Loader';
import SignalCellularAltIcon from '@material-ui/icons/SignalCellularAlt';
import PieChartIcon from '@material-ui/icons/PieChart';
import PersonIcon from '@material-ui/icons/Person';
import CaseIcon from '../../../../assets/images/case.svg';
import AttachMoney from '@material-ui/icons/AttachMoney';
import { Tooltip } from '@material-ui/core';
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';
import { showNflBrand } from 'app/helpers';

export const TwoSideWidget = ({
    color,
    toAchieve,
    achieved,
    title,
    image,
    topLeftHeading,
    bottomRightHeading,
    target,
    loader,
    isTooltipShow,
    toAchieveNotShow,
    onClick = () => { }
}) => {
    return (
        <div className={'TwoSideWidget__container'}>
            {/* <h3 className={'TwoSideWidget__mainHeading'}>{`${title} ${target ? target : ''}`}</h3> */}

            <div
                className={'TwoSideWidget__root'}
                style={{
                    backgroundColor: '#fff',
                }}
            >
                {loader ? (
                    <Loader color="#000" />
                ) : (
                    <React.Fragment>
                        <div className={'TwoSideWidget__leftSide'} onClick={onClick}>
                            <div className={'TwoSideWidget__toAchieve'}>
                                <h2 className={'TwoSideWidget__figure'}>{title}</h2>
                                <div className="TwoSideWidget_Tooltip">
                                    {topLeftHeading !== 'Total Cash Collected' && image.toLowerCase() !== 'money' && (
                                        <h3 className={'TwoSideWidget__figureHeading'}>
                                            {target && (
                                                <CountUp
                                                    start={0}
                                                    end={target ? target : 0}
                                                    duration={3}
                                                    useEasing
                                                    separator=","
                                                />
                                            )}
                                        </h3>
                                    )}
                                    {isTooltipShow && (
                                        <div
                                            style={{ marginLeft: 3, fontFamily: 'Poppins', fontSize: 8, marginTop: 5 }}
                                        >
                                            {showNflBrand ? (
                                                '(Total Activity Target)'
                                            ) : (
                                                <Tooltip title={'Total activity target'} arrow placement="top-end">
                                                    <HelpOutlineOutlinedIcon className="tooltip-icon" />
                                                </Tooltip>
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div
                                className={'TwoSideWidget__toAchieve to_Achieve_container'}
                                style={{ alignItems: 'end' }}
                            >
                                <h2 className={'TwoSideWidget__figure achieved_count'}>
                                    {achieved ? <CountUp
                                        start={0}
                                        end={achieved ? achieved : 0}
                                        duration={3}
                                        useEasing
                                        separator=","
                                    /> : ''}
                                </h2>
                                <h3 className={'TwoSideWidget__figureHeading'} style={{ paddingBottom: 6 }}>
                                    {bottomRightHeading
                                        ? bottomRightHeading
                                        : topLeftHeading ||
                                            image.toLowerCase() === 'money' ||
                                            image.toLowerCase() === 'animal' ||
                                            image.toLowerCase() === 'biryani'
                                            ? ''
                                            : 'Achieved'}{' '}
                                </h3>
                            </div>

                            <div className={'TwoSideWidget__toAchieve to_Achieve_container'}>
                                {!toAchieveNotShow && topLeftHeading !== 'Total Cash Collected' ? (
                                    <h2
                                        className={'TwoSideWidget__figure'}
                                        style={{ fontWeight: 'bold', color: '#454F5B' }}
                                    >
                                        <CountUp
                                            start={0}
                                            end={toAchieve ? toAchieve : 0}
                                            duration={3}
                                            useEasing
                                            separator=","
                                        />
                                    </h2>
                                ) : null}
                                {!toAchieveNotShow && topLeftHeading !== 'Total Cash Collected' ? (
                                    <h3 className={'TwoSideWidget__figureHeading'}>
                                        {topLeftHeading ? topLeftHeading : 'To Achieve'}{' '}
                                    </h3>
                                ) : null}
                            </div>
                        </div>
                        <div className={'TwoSideWidget__rightSide'}>
                            {image.toLowerCase() === 'household' ? (
                                <div className="icon_container">
                                    <PieChartIcon className={'TwoSideWidget__image'} />
                                </div>
                            ) : image.toLowerCase() === 'biryani' ? (
                                <div className="icon_container">
                                    <PersonIcon className={'TwoSideWidget__image'} />
                                </div>
                            ) : image.toLowerCase() === 'sales' ? (
                                <div className="icon_container">
                                    <SignalCellularAltIcon className={'TwoSideWidget__image'} />
                                </div>
                            ) : image.toLowerCase() === 'file' ? (
                                <img src={CaseIcon} className="icon_container" />
                            ) : image.toLowerCase() === 'money' ? (
                                <div className="icon_container">
                                    <AttachMoney className={'TwoSideWidget__image'} />
                                </div>
                            ) : image.toLowerCase() === 'animal' ? (
                                <div className="icon_container">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="TwoSideWidget__image"
                                        width="1em"
                                        height="1em"
                                        viewBox="0 0 24 24"
                                    >
                                        <path
                                            fill="currentColor"
                                            d="M10.5 18a.5.5 0 0 1 .5.5a.5.5 0 0 1-.5.5a.5.5 0 0 1-.5-.5a.5.5 0 0 1 .5-.5m3 0a.5.5 0 0 1 .5.5a.5.5 0 0 1-.5.5a.5.5 0 0 1-.5-.5a.5.5 0 0 1 .5-.5M10 11a1 1 0 0 1 1 1a1 1 0 0 1-1 1a1 1 0 0 1-1-1a1 1 0 0 1 1-1m4 0a1 1 0 0 1 1 1a1 1 0 0 1-1 1a1 1 0 0 1-1-1a1 1 0 0 1 1-1m4 7c0 2.21-2.69 4-6 4s-6-1.79-6-4c0-.9.45-1.73 1.2-2.4c-.75-1-1.2-2.25-1.2-3.6l.12-1.22c-.54.15-1.19.15-1.72 0c-1.02-.28-2.56-1.43-2.33-2.23c.23-.8 2.14-.95 3.16-.65c.59.17 1.22.6 1.59 1.06l.57-.81C6.79 7.05 7 4 10 3l-.09.14c-.28.44-1 1.83-.24 3.33a6.02 6.02 0 0 1 4.66 0c.76-1.5.04-2.89-.24-3.33L14 3c3 1 3.21 4.05 2.61 5.15l.57.81c.37-.46 1-.89 1.59-1.06c1.02-.3 2.93-.15 3.16.65c.23.8-1.31 1.95-2.33 2.23c-.53.15-1.18.15-1.72 0L18 12c0 1.35-.45 2.6-1.2 3.6c.75.67 1.2 1.5 1.2 2.4m-6-2c-2.21 0-4 .9-4 2s1.79 2 4 2s4-.9 4-2s-1.79-2-4-2m0-2c1.12 0 2.17.21 3.07.56c.58-.69.93-1.56.93-2.56a4 4 0 0 0-4-4a4 4 0 0 0-4 4c0 1 .35 1.87.93 2.56c.9-.35 1.95-.56 3.07-.56m2.09-10.86"
                                        ></path>
                                    </svg>
                                </div>
                            ) : (
                                // <SignalCellularAltIcon className={'TwoSideWidget__image'} />
                                image.toLowerCase() === 'winners' && (
                                    <CardGiftcardIcon
                                        className={'TwoSideWidget__image'}
                                        style={{ width: 50, height: 50 }}
                                    />
                                )
                            )}
                        </div>
                    </React.Fragment>
                )}
            </div>
        </div>
    );
};
