import React from 'react';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';
import './PaperBlock.scss';
import { Tooltip } from '@material-ui/core';
const pageClass = 'PaperBlock__';

export const PapperBlock = ({ title, desc, children, download, style, isShowQuestionIcon, isCentered }) => {
    return (
        <Paper className={`${pageClass}root`} elevation={4} style={style}>
            <div
                className="PaperBlock__title-container"
                style={isCentered ? { display: 'flex', justifyContent: 'center' } : {}}
            >
                <Typography variant="title" component="h2" className={'PaperBlock__title'} style={{ display: 'flex' }}>
                    {title}{' '}
                    {isShowQuestionIcon && (
                        <div style={{ marginLeft: 3 }}>
                            <Tooltip title="Number of Activity days" arrow placement="top-end">
                                <HelpOutlineOutlinedIcon className="help-icon" />
                            </Tooltip>
                        </div>
                    )}
                </Typography>

                <div>{download}</div>
            </div>

            <Typography component="p" className={'PaperBlock__description'}>
                {desc}
            </Typography>
            <div className="PaperBlock__content">{children}</div>
        </Paper>
    );
};
