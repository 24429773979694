import React from 'react';
import logo from 'assets/images/asraLogo3.png';
import './mainPageComponents.scss';

const MainPageComponents = () => {
    return (
        <div className="mainPageComponents">
            <img src={logo} alt="MainLogo" />
        </div>
    );
};

export default MainPageComponents;
