import React, { useEffect, useState } from 'react';
import Heading from 'app/helpers/Heading';
import './style.scss';
import { axiosInstance, errorHandler } from 'app/helpers';
import CattleMandiTable from 'app/components/Tables/CattleMandiTable';
import {
    cattleMandiLandBookerTableHead,
    cattleMandiMarshallingTableHead,
} from 'app/components/Tables/CattleMandiTable/helper';
import { useDispatch } from 'react-redux';
import { setAlert } from 'app/redux/actions/alertAction';
import Loader from 'app/components/loader/Loader';
import { Box, Collapse, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { reversedAndFormatedDate } from 'app/helpers/convertDate';

const LandBookerRecord = () => {
    // paid states
    const [marshallingData, setMarshallingData] = useState([]);
    const [searchData, setSearchData] = useState();
    const [isLoad, setIsLoad] = useState(false);
    const [dataCount, setDataCount] = useState(0);
    const [getId, setGetid] = useState('');

    // unpaid states
    const [unpaidMarshallingData, setUnpaidMarshallingData] = useState([]);
    const [unpaidSearchData, setUnpaidSearchData] = useState([]);
    const [isLoadUnpaid, setIsLoadUnpaid] = useState(false);
    const [unpaidDataCount, setUnpaidDataCount] = useState(0);
    const [unpaidGetId, setUnpaidGetid] = useState('');

    const dispatch = useDispatch();

    const userData = useSelector((state) => state.getIn(['login', 'data']));
    const userRole = userData.get('role');

    // paid functions
    const getAllAutomation = async (offset) => {
        if (offset === undefined) setIsLoad(true);
        try {
            const { data } = await axiosInstance.get('/cattleMandi/get-land-bookers', {
                params: { offset: offset, filter: 'paid' },
            });
            const resData = data?.data;
            console.log({ resData });
            if (resData !== undefined) {
                setMarshallingData(resData.data);
            }
            setDataCount(resData.count);
            return resData?.data;
        } catch (error) {
            dispatch(
                setAlert({
                    isOpen: true,
                    type: 'error',
                    message: errorHandler(error),
                })
            );
            setTimeout(() => {
                dispatch(setAlert({}));
            }, 5000);
        } finally {
            setIsLoad(false);
        }
    };

    const customerSearch = async () => {
        if (getId) {
            try {
                setIsLoad(true);
                const { data } = await axiosInstance.get(`/cattleMandi/search-land-booker`, {
                    params: {
                        query: getId.trim(),
                        filter: 'paid',
                    },
                });

                setSearchData([data?.data]);
                setDataCount(data?.data?.length);
            } catch (error) {
                setGetid('');
                dispatch(
                    setAlert({
                        isOpen: true,
                        type: 'error',
                        message: errorHandler(error?.response?.data?.error),
                    })
                );
                setTimeout(() => {
                    dispatch(setAlert({}));
                }, 5000);
                setSearchData([]);
                getAllAutomation();
            } finally {
                setIsLoad(false);
            }
        }
    };

    const clearSearch = () => {
        setSearchData([]);
        getAllAutomation();
        setGetid('');
    };
    // -- end --

    // unpaid functions
    const getAllAutomationUnpaid = async (offset) => {
        if (offset === undefined) setIsLoadUnpaid(true);
        try {
            const { data } = await axiosInstance.get('/cattleMandi/get-land-bookers', {
                params: { offset: offset, filter: 'unpaid' },
            });
            const resData = data?.data;
            console.log({ resData });
            if (resData !== undefined) {
                setUnpaidMarshallingData(resData.data);
            }
            setUnpaidDataCount(resData.count);
            return resData?.data;
        } catch (error) {
            dispatch(
                setAlert({
                    isOpen: true,
                    type: 'error',
                    message: errorHandler(error),
                })
            );
            setTimeout(() => {
                dispatch(setAlert({}));
            }, 5000);
        } finally {
            setIsLoadUnpaid(false);
        }
    };

    const customerSearchUnpaid = async () => {
        if (unpaidGetId) {
            try {
                setIsLoadUnpaid(true);
                const { data } = await axiosInstance.get(`/cattleMandi/search-land-booker`, {
                    params: {
                        query: unpaidGetId.trim(),
                        filter: 'unpaid',
                    },
                });

                setUnpaidSearchData([data?.data]);
                setUnpaidDataCount(data?.data?.length);
            } catch (error) {
                setUnpaidGetid('');
                dispatch(
                    setAlert({
                        isOpen: true,
                        type: 'error',
                        message: errorHandler(error?.response?.data?.error),
                    })
                );
                setTimeout(() => {
                    dispatch(setAlert({}));
                }, 5000);
                setUnpaidSearchData([]);
                getAllAutomationUnpaid();
            } finally {
                setIsLoadUnpaid(false);
            }
        }
    };

    const clearSearchUnpaid = () => {
        setUnpaidSearchData([]);
        getAllAutomationUnpaid();
        setUnpaidGetid('');
    };

    // -- end --

    const renderTableCell = (value) => {
        const renderCell = (content, defaultValue = '--') => {
            const cellColor = content === 'paid' ? 'green' : content === 'unpaid' && 'red';
            return <TableCell style={{ color: cellColor }}>{content ? content : ''}</TableCell>;
        };

        return (
            <>
                {renderCell(value?.id)}
                {renderCell(value?.name)}
                {renderCell(value?.contact_number || '0')}
                {renderCell(value?.cnic || '0')}
                {renderCell(reversedAndFormatedDate(value?.createdAt))}
                {renderCell(value?.paymentStatus || '0')}
            </>
        );
    };

    const renderCollapse = (value, isOpen) => {
        return (
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={20}>
                <Collapse in={isOpen?.open && isOpen?.value?.id === value?.id} timeout="auto" unmountOnExit>
                    <Box margin={1}>
                        <Typography variant="h6" gutterBottom component="div">
                            Lands
                        </Typography>
                        <Table size="small" aria-label="purchases">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Block#</TableCell>
                                    <TableCell>Category</TableCell>
                                    <TableCell>status</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {value?.lands.map((item, i) => (
                                    <TableRow key={i}>
                                        <TableCell component="th" scope="row">
                                            {item?.tblLand?.block}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {item?.tblLand?.category}
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {item.status}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Box>
                </Collapse>
            </TableCell>
        );
    };

    useEffect(() => {
        setSearchData([]);
        getAllAutomation();
        getAllAutomationUnpaid();
    }, []);

    const dataRes = !!searchData?.length ? searchData : marshallingData;
    const unPaiddataRes = !!unpaidSearchData?.length ? unpaidSearchData : unpaidMarshallingData;

    return (
        <div className="CattleMandiTables_Container">
            <Heading heading="Land Booker Records" />

            <div className="CattleMandiTables_content">
                {isLoad ? (
                    <Loader color="#000" height="65vh" />
                ) : (
                    <>
                        {!!marshallingData?.length || !!searchData?.length ? (
                            <div className="CattleMandiTables_Item">
                                <CattleMandiTable
                                    page={1}
                                    rowsPerPage={5}
                                    defaultPerPage={5}
                                    data={dataRes}
                                    checkBox
                                    // mtCell
                                    dataCount={dataCount}
                                    CheckBox
                                    CheckBoxShow
                                    name="Paid Land Booker Record Table"
                                    cattleMandiTableHead={cattleMandiLandBookerTableHead}
                                    isLoad={isLoad}
                                    showLandBookerViewButton={true}
                                    renderCell={renderTableCell}
                                    actionCell={true}
                                    showSearch={true}
                                    getId={getId}
                                    fetchDDSDetails={getAllAutomation}
                                    setGetid={setGetid}
                                    customerSearch={customerSearch}
                                    clearSearch={clearSearch}
                                    showClearIcon={!!searchData?.length}
                                    renderCollapse={renderCollapse}
                                />
                            </div>
                        ) : null}
                    </>
                )}

                {isLoadUnpaid ? (
                    <Loader color="#000" height="65vh" />
                ) : (
                    <>
                        {!!unpaidMarshallingData?.length || !!unpaidSearchData?.length ? (
                            <div className="CattleMandiTables_Item">
                                <CattleMandiTable
                                    page={1}
                                    rowsPerPage={5}
                                    defaultPerPage={5}
                                    data={unPaiddataRes}
                                    checkBox
                                    // mtCell
                                    dataCount={unpaidDataCount}
                                    CheckBox
                                    CheckBoxShow
                                    name="Unpaid Land Booker Record Table"
                                    cattleMandiTableHead={cattleMandiLandBookerTableHead}
                                    isLoad={isLoadUnpaid}
                                    showLandBookerViewButton={true}
                                    renderCell={renderTableCell}
                                    actionCell={true}
                                    showSearch={true}
                                    getId={unpaidGetId}
                                    fetchDDSDetails={getAllAutomationUnpaid}
                                    setGetid={setUnpaidGetid}
                                    customerSearch={customerSearchUnpaid}
                                    clearSearch={clearSearchUnpaid}
                                    showClearIcon={!!unpaidSearchData?.length}
                                    renderCollapse={renderCollapse}
                                />
                            </div>
                        ) : null}
                    </>
                )}
            </div>
        </div>
    );
};

export default LandBookerRecord;
