import React from 'react';
import { ComposedChart, ResponsiveContainer, CartesianGrid, Bar, Area, XAxis, YAxis, Tooltip, Legend } from 'recharts';
import Loader from '../loader/Loader';
import LegendContent from '../LegendContent';

export const ReachTrial = ({ data, width, loader }) => {
    const colors = ['', '#7904D7', '#7904D7', '#7904D7'];
    console.log(data);
    return (
        <div style={{ height: 320, width: width }}>
            {loader ? (
                <Loader color="#000" height="50vh" />
            ) : (
                <ResponsiveContainer>
                    <ComposedChart width={width} height={390} data={data || []}>
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip />
                        <CartesianGrid stroke="#f5f5f5" />

                        <Legend
                            verticalAlign="bottom"
                            align="center"
                            height={3}
                            margin={35}
                            content={<LegendContent />}
                        />
                        {!!data?.length &&
                            Object.keys(data?.[0] || []).map(
                                (item, index) =>
                                    item !== 'name' &&
                                    (item === 'Trial' || item === 'Paid' ? (
                                        <Bar key={item} dataKey={item} fill={colors[index]} barSize={40} />
                                    ) : (
                                        <Area
                                            key={item}
                                            type="monotone"
                                            dataKey={item}
                                            fill="#F59E0B"
                                            stroke="#F59E0B"
                                        />
                                    ))
                            )}
                    </ComposedChart>
                </ResponsiveContainer>
            )}
        </div>
    );
};
