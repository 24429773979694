module.exports = {
    env: 'ASStage',
    baseURL: {
        BASE_URL: process.env.REACT_APP_STAGE_BASE_URL,
    },
    featureFlags: {
        ShowNflBrand: false,
        brandName: 'Asra Soft',
    },
};
