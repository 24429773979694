import {
    Box,
    Tab,
    Tabs,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
} from '@material-ui/core';
import * as React from 'react';
import Loader from '../loader/Loader';

function CustomTabPanel({ value, index, data, isHideFieldText }) {
    return (
        <div style={{ width: '100%' }}>
            <Box>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {!isHideFieldText && <TableCell style={{ fontWeight: 'bold' }}>Field</TableCell>}
                                <TableCell style={{ fontWeight: 'bold' }} align="right">
                                    {value}
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {Object.entries(data).map(([key, value]) => (
                                <TableRow key={key}>
                                    {!isHideFieldText && (
                                        <TableCell component="th" scope="row">
                                            {key}
                                        </TableCell>
                                    )}
                                    <TableCell align="right">{value}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function AnalyticsTabs({ data, isLoad }) {
    return isLoad ? (
        <Box display="flex" alignItems="center" justifyContent="center">
            <Loader color="#000" />
        </Box>
    ) : (
        <Box sx={{ width: '100%' }}>
            {/* <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs>
                    <Tab label="Previous" />
                    <Tab label="Today" />
                    <Tab label="Total" />
                </Tabs>
            </Box> */}

            <Box sx={{ display: 'flex', width: '100%' }}>
                <CustomTabPanel value="Today Value" data={data?.today} isHideFieldText={false} />
                <CustomTabPanel value="Previous Value" data={data?.previous} isHideFieldText={true} />
                <CustomTabPanel value="Total Value" data={data?.total} isHideFieldText={true} />
            </Box>
        </Box>
    );
}
