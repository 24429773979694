import React, { PureComponent } from "react";

import Badge from "@material-ui/core/Badge";

class LimitedBadges extends PureComponent {
  render() {
    const { children, limit, value, ...rest } = this.props;
    return (
      <Badge badgeContent={value > limit ? limit + "+" : value} {...rest}>
        {children}
      </Badge>
    );
  }
}

export default LimitedBadges;
