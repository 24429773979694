import React from 'react';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import {
    Toolbar,
    Typography,
    IconButton,
    Tooltip,
    FormControl,
    Input,
    InputAdornment,
    InputLabel,
    Select,
    MenuItem,
} from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';
import SearchIcon from '@material-ui/icons/Search';
import Delete from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
// import { Link } from 'react-router-dom';
import { SvgIcon } from '@material-ui/core';
import styles from './tableStyle-jss';
import ClearIcon from '@material-ui/icons/Clear';
import Loader from '../loader/Loader';

const TableToolbar = (props) => {
    const {
        classes,
        name,
        download,
        numSelected,
        toggleDeleteModal,
        uploadDeposit,
        openDeposit,
        showSearch,
        getId,
        setGetid,
        customerSearch,
        downloadLoading,
        handleSpeedChange,
        speed,
        clearSearch,
        showClearIcon,
    } = props;

    return (
        <Toolbar className={classes.toolbarRoot}>
            {numSelected > 0 ? (
                <div className={classes.selectedContainer}>
                    <Typography sx={{ flex: '1 1 100%' }} color="inherit" variant="subtitle1" component="div">
                        <b>{numSelected} selected</b>
                    </Typography>
                    <Tooltip title="Delete">
                        <IconButton>
                            <Delete onClick={toggleDeleteModal} />
                        </IconButton>
                    </Tooltip>
                </div>
            ) : (
                <div className={classNames(classes.titleToolbar)}>
                    <Typography variant="title" style={{ fontFamily: 'Poppins', fontWeight: 600 }}>
                        {name}
                    </Typography>

                    <div className={classes.actionsToolbar}>
                        {download && showSearch && (
                            <FormControl variant="standard" style={{ width: '30%', marginBottom: '14.533px' }}>
                                <InputLabel id="demo-simple-select-standard-label">Audio Speed</InputLabel>
                                <Select
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    value={speed}
                                    onChange={(e) => handleSpeedChange(e.target.value)}
                                    label="Speed"
                                >
                                    <MenuItem value={0.5}>0.5x</MenuItem>
                                    <MenuItem value={1.0}>1x</MenuItem>
                                    <MenuItem value={1.5}>1.5x</MenuItem>
                                    <MenuItem value={2.0}>2x</MenuItem>
                                </Select>
                            </FormControl>
                        )}
                        <div className={classes.actions}>
                            {showSearch && (
                                <FormControl className={classNames(classes.textField)}>
                                    <Input
                                        id="search_filter"
                                        type="text"
                                        placeholder="Search here..."
                                        value={getId}
                                        onChange={(event) => setGetid(event.target.value)}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton aria-label="Search filter" onClick={customerSearch}>
                                                    <SearchIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>
                            )}

                            {showSearch && showClearIcon ? (
                                <Tooltip title="Clear search">
                                    <IconButton onClick={clearSearch}>
                                        <ClearIcon />
                                    </IconButton>
                                </Tooltip>
                            ) : null}
                            {/* <Tooltip title="Filter list">
                                <IconButton
                                    aria-label="Filter list"
                                    className={classes.filterBtn}
                                    onClick={() => toggleSearch()}
                                >
                                    <FilterListIcon />
                                </IconButton>
                            </Tooltip> */}
                            {download && (
                                <>
                                    <InputAdornment position="end">
                                        <IconButton onClick={download} disabled={downloadLoading}>
                                            {downloadLoading ? (
                                                <Loader color="#000" size={20} />
                                            ) : (
                                                <SvgIcon className="download-icon" title="Download">
                                                    <path d="M19.35 10.04C18.67 6.59 15.64 4 12 4 9.11 4 6.6 5.64 5.35 8.04 2.34 8.36 0 10.91 0 14c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96zM17 13l-5 5-5-5h3V9h4v4h3z"></path>
                                                </SvgIcon>
                                            )}
                                        </IconButton>
                                    </InputAdornment>
                                </>
                            )}
                            {uploadDeposit && (
                                <Tooltip title="Add Deposit">
                                    <IconButton onClick={openDeposit}>
                                        <AddIcon />
                                    </IconButton>
                                </Tooltip>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </Toolbar>
    );
};

export default withStyles(styles)(TableToolbar);
