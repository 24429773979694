import React from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { Fade } from '@material-ui/core';
import CustomModal from '../Modal';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #7904d7',
    boxShadow: 24,
    p: 4,
    borderRadius: '10px',
};

const LandSummaryModal = ({ open, handleClose, data }) => {
    const keysArray = [];
    const array = !!data?.length ? data : [];

    array?.forEach((obj) => {
        const keys = Object.keys(obj);
        keysArray.push(keys[0]);
    });

    return (
        <div>
            <CustomModal open={open} onClose={handleClose}>
                <Fade in={open}>
                    <Box sx={style}>
                        <Typography variant="h6" component="h3" sx={{ textAlign: 'center' }} align="center">
                            Land Summary
                        </Typography>
                        <Box sx={{ height: '350px', overflow: 'auto', px: '10px' }}>
                            {keysArray.map((item, index) => (
                                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Typography
                                        id="spring-modal-title"
                                        variant="h6"
                                        component="h4"
                                        sx={{ fontWeight: '500' }}
                                    >
                                        {item}
                                    </Typography>
                                    <Typography id="spring-modal-title" variant="h6" component="h4">
                                        {array[index][item]}
                                    </Typography>
                                </Box>
                            ))}
                        </Box>

                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                            <Button variant="contained" color="primary" onClick={handleClose}>
                                Close
                            </Button>
                        </Box>
                    </Box>
                </Fade>
            </CustomModal>
        </div>
    );
};

export default LandSummaryModal;
