import React from 'react';
import './Footer.scss';
import ErrorImage from '../../../assets/images/error.jpeg';
import ButtonGroup from '../ButtonGroup/ButtonGroup';

const ErrorBoundryScreen = () => {
    const goToHome = () => {
        window.location.href = '/';
    };

    return (
        <div className="ErrorBoundryScreen-container">
            <div>
                <img src={ErrorImage} alt="errorImage" className="ErrorBoundryScreen-error-image" />
            </div>
            <div>
                <p className="ErrorBoundryScreen-text-1">Opps, Somthing went wrong</p>
                <p className="ErrorBoundryScreen-text-2">Have you tried turning off and on again?</p>
            </div>

            <ButtonGroup
                type="button"
                value="Go to home"
                variant="contained"
                color="primary"
                isLoad={false}
                disabled={false}
                onClick={() => {
                    goToHome();
                }}
            />
        </div>
    );
};

export default ErrorBoundryScreen;
