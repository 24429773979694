export const tabName = [
    {
        path: '/compaign',
        title: 'Compaign',
    },
    {
        path: '/app',
        title: 'Dashboard',
    },
    {
        path: '/daily-stats',
        title: 'Daily-Stats',
    },
    {
        path: '/data-tables',
        title: 'Data-Tables',
    },
    {
        path: '/bulk-upload',
        title: 'Bulk-Upload',
    },
    {
        route: '/daily-stats/supervisor-team',
        title: 'Supervisor-Team',
    },
];
