import React, { useEffect, useRef, useState } from 'react';
import './style.scss';
import Heading from 'app/helpers/Heading';
import { Checkbox, CircularProgress, Grid, Paper } from '@material-ui/core';
import ButtonGroup from 'app/components/ButtonGroup/ButtonGroup';
import ReuseableSelect from 'app/components/Select/Select';
import Input from 'app/components/Input/Input';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { setAlert } from 'app/redux/actions/alertAction';
import { useDispatch } from 'react-redux';
import Camera from 'app/components/ReactCamera';
import { axiosInstance, dataURItoBlob, errorHandler } from 'app/helpers';
import { useHistory } from 'react-router-dom';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import LandModal from 'app/components/LandModal/LandModal';
import SearchIcon from '@material-ui/icons/Search';
import { MultiLandComponent } from 'app/components';
import { CheckBox } from '@material-ui/icons';

const validationSchema = Yup.object().shape({
    name: Yup.string().required('required'),
    NicNo: Yup.string().required('required'),
    contactNo: Yup.string().required('required'),
    numberOfCows: Yup.string().required('required'),
    numberOfCamels: Yup.string().required('required'),
    numberOfGoats: Yup.string().required('required'),
    drum: Yup.string().required('required'),
    land_booked: Yup.boolean(),
    lands: Yup.array().test(
        'required-lands',
        'At least one land must be selected when Land Booked is checked',
        function(value) {
            if (this.parent.land_booked) {
                return true;
            }
            return value && value.length > 0;
        }
    ),
    image: Yup.string().required('required'),
    vehicles: Yup.array().required('required'),
});

const DataEntry = () => {
    const fileInputRef = useRef(null);
    const dispatch = useDispatch();
    const [isCameraOpen, setIsCameraOpen] = useState(false);
    const [dataUri, setDataUri] = useState('');
    const [clickedPicture, setClickedPicture] = useState('');
    const [getAllStatesAndLands, setGetAllStatesAndLands] = useState([]);
    const [isLoad, setIsLoad] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [isLandBooker, setIsLandBooker] = useState(false);
    const [searchLoading, setSearchLoading] = useState(false);

    const history = useHistory();

    const formik = useFormik({
        initialValues: {
            name: '',
            marshalling_id: '',
            NicNo: '',
            contactNo: '',
            numberOfCows: 0,
            numberOfCamels: 0,
            numberOfGoats: 0,
            drum: '',
            image: '',
            lands: [],
            vehicles: [],
            state_id: '',
            land_booked: false,
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            setIsLoad(true);
            if (!values.marshalling_id) {
                throw new Error('Marshalling should be done first');
            }
            if (!clickedPicture) {
                throw new Error('Take the picture first');
            }

            const finalLands = values.lands?.map((item) => {
                if (!item.hasOwnProperty('customer_type') && item?.customer_type !== 'land_booker') {
                    return {
                        land_id: item?.id,
                        customer_type: item?.customer_type,
                    };
                } else return item;
            });
            const formData = new FormData();
            formData.append('name', values.name);
            formData.append('marshalling_id', values.marshalling_id);
            formData.append('cnic', values.NicNo);
            formData.append('contactNo', values.contactNo);
            formData.append('noOfCows', values.numberOfCows);
            formData.append('noOfCamels', values.numberOfCamels);
            formData.append('noOfGoats', values.numberOfGoats);
            formData.append('stringifiedLands', !!values.land_booked ? [] : JSON.stringify(finalLands));
            formData.append('image', dataURItoBlob(clickedPicture));

            formData.append('drum_count', values.drum);
            try {
                const { data } = await axiosInstance.post('/cattleMandi/create-automation', formData);
                setIsLoad(false);
                formik.resetForm();

                history.push(`/view-reciept/${data?.data?.data.id}`);
            } catch (error) {
                dispatch(
                    setAlert({
                        isOpen: true,
                        type: 'error',
                        message: errorHandler(error),
                    })
                );
                setTimeout(() => {
                    dispatch(setAlert({}));
                }, 5000);
                setIsLoad(false);
            } finally {
                setIsLoad(false);
            }
        },
    });

    const handleNumberKeyDown = (e) => {
        if (e.key === 'e' || e.key === '-') {
            e.preventDefault();
        }
    };

    // func
    const formatNIC = (value) => {
        if (value && value.length === 13) {
            return `${value.substring(0, 5)}-${value.substring(5, 12)}-${value.charAt(12)}`;
        }
        return value;
    };

    const handleFileInputChange = (e) => {
        const selectedFile = e.target.files[0];
        console.log({ selectedFile });

        if (selectedFile && selectedFile.type.startsWith('image/')) {
            formik.setFieldValue('image', selectedFile);
        } else {
            dispatch(
                setAlert({
                    isOpen: true,
                    type: 'error',
                    message: 'Only image can selected',
                })
            );
            setTimeout(() => {
                dispatch(setAlert({}));
            }, 5000);
        }
    };

    const openFileLocation = () => {
        setIsCameraOpen(true);
    };

    function handleTakePhoto(dataUri) {
        console.log('takePhoto');
    }

    function handleTakePhotoAnimationDone(dataUri) {
        // Do stuff with the photo...
        setDataUri(dataUri);
        console.log('takePhoto');
    }

    function handleCameraError(error) {
        console.log('handleCameraError', error);
    }

    function handleCameraStart(stream) {
        console.log('handleCameraStart');
    }

    function handleCameraStop() {
        console.log('handleCameraStop');
    }

    function cancelImageCapture() {
        setDataUri('');
        setIsCameraOpen(false);
    }

    function continueImageCapture() {
        setClickedPicture(dataUri);
        formik.setFieldValue('image', 'image/jpeg');
        setDataUri('');
        setIsCameraOpen(false);
    }

    const getStatesLands = async () => {
        try {
            const { data } = await axiosInstance.get('/cattleMandi/get-state-lands');
            setGetAllStatesAndLands(data?.data?.filter((Ele) => Ele.price == 0));
        } catch (error) {
            dispatch(
                setAlert({
                    isOpen: true,
                    type: 'error',
                    message: errorHandler(error),
                })
            );
            setTimeout(() => {
                dispatch(setAlert({}));
            }, 5000);
        }
    };

    const searchRecord = async () => {
        try {
            setSearchLoading(true);
            const { data } = await axiosInstance.get(`/cattleMandi/search-marshalling-or-land`, {
                params: {
                    query: formik.values.NicNo,
                },
            });
            if (!data?.data?.marshallingRecord) {
                throw new Error('Marshalling Entry is pending. Ask the customer to do it before continuing.');
            }

            if (data?.data?.landBookerRecord && data?.data?.landBookerRecord?.paymentStatus === 'unpaid') {
                throw new Error('Land payment is pending. Ask the customer to clear it before continuing.');
            }
            console.log(data?.data?.landBookerRecord?.paymentStatus);
            setSearchLoading(false);
            setIsLandBooker(!!data?.data?.landBookerRecord);

            formik.setFieldValue('marshalling_id', data?.data?.marshallingRecord?.id);
            formik.setFieldValue('numberOfCows', data?.data?.marshallingRecord?.no_of_cows);
            formik.setFieldValue('numberOfCamels', data?.data?.marshallingRecord?.no_of_camels);
            formik.setFieldValue('numberOfGoats', data?.data?.marshallingRecord?.no_of_goats);
            formik.setFieldValue(
                'name',
                data?.data?.marshallingRecord?.name_of_owner || data?.data?.landBookerRecord?.name
            );
            formik.setFieldValue('contactNo', data?.data?.landBookerRecord?.contact_number || '');
            formik.setFieldValue('land_block_id', data?.data?.landBookerRecord?.land_block_id || '');
            formik.setFieldValue('lands', data?.data?.landBookerRecord?.lands || []);
            formik.setFieldValue('vehicles', data?.data?.marshallingRecord?.vehicles || []);
        } catch (error) {
            setSearchLoading(false);
            dispatch(
                setAlert({
                    isOpen: true,
                    type: 'error',
                    message: errorHandler(error),
                })
            );
            setTimeout(() => {
                dispatch(setAlert({}));
            }, 5000);
        }
    };
    const handleClose = (land) => {
        const landsSelected = formik.values.lands;
        const filteredLands = landsSelected.filter((item) => {
            return item?.id !== land?.id;
        });
        formik.setFieldValue('lands', filteredLands);
    };
    useEffect(() => {
        getStatesLands();
    }, []);

    useEffect(() => {
        if (!!formik.values.state_id && formik.values.state_id !== 'Select lands') {
            setIsOpen(true);
        }
    }, [formik.values.state_id]);

    return (
        <div className="EntryGateMarshalling_Container">
            {!isCameraOpen && <Heading heading="Data Entry Form Automation Cabin" />}
            {!isCameraOpen && (
                <div className="EntryGateMarshalling_Form">
                    <Paper className="EntryGateMarshalling_Card">
                        <form
                            onSubmit={(e) => {
                                e.preventDefault();
                                formik.submitForm();
                            }}
                        >
                            <div className="TextInput_Container">
                                <Grid container spacing={1}>
                                    <Grid xl={6} lg={6} md={6} xs={12} sm={12}>
                                        <div>
                                            <p>Bio Data</p>
                                        </div>
                                        <div className="TextInput_Main">
                                            <label htmlFor="name">CNIC No.</label>
                                            <div className="input_cnic_search">
                                                <Input
                                                    id="NicNo"
                                                    type="text"
                                                    onKeyDown={handleNumberKeyDown}
                                                    inputProps={{
                                                        inputMode: 'numeric',
                                                    }}
                                                    placeholder="Type Here"
                                                    name="NicNo"
                                                    value={formik.values.NicNo}
                                                    onChange={formik.handleChange}
                                                    className="TextInput"
                                                    error={formik.touched.NicNo && formik.errors.NicNo}
                                                />
                                                <div className="search_icon_div">
                                                    {searchLoading ? (
                                                        <CircularProgress className="search_icon" size={20} />
                                                    ) : (
                                                        <SearchIcon className="search_icon" onClick={searchRecord} />
                                                    )}
                                                </div>
                                            </div>
                                            {formik.touched.NicNo && formik.errors.NicNo && (
                                                <div className="error">{formik.errors.NicNo}</div>
                                            )}
                                        </div>
                                        <div className="TextInput_Main">
                                            <label htmlFor="name">Name</label>
                                            <Input
                                                disabled={true}
                                                id="name"
                                                type="text"
                                                placeholder="Type Here"
                                                name="name"
                                                value={formik.values.name}
                                                onChange={formik.handleChange}
                                                className="TextInput"
                                                error={formik.touched.name && formik.errors.name}
                                            />
                                            {formik.touched.name && formik.errors.name && (
                                                <div className="error">{formik.errors.name}</div>
                                            )}
                                        </div>
                                        <div className="TextInput_Main">
                                            <label htmlFor="name">Contact No.</label>
                                            <Input
                                                id="contactNo"
                                                type="number"
                                                onKeyDown={handleNumberKeyDown}
                                                inputProps={{
                                                    inputMode: 'numeric',
                                                }}
                                                placeholder="Type Here"
                                                name="contactNo"
                                                value={formik.values.contactNo}
                                                onChange={formik.handleChange}
                                                className="TextInput"
                                                error={formik.touched.contactNo && formik.errors.contactNo}
                                            />
                                            {formik.touched.contactNo && formik.errors.contactNo && (
                                                <div className="error">{formik.errors.contactNo}</div>
                                            )}
                                        </div>
                                        <div className="TextInput_Main">
                                            <label htmlFor="name">Picture</label>
                                            <div className="EntryGateMarshalling_Camera_Box" onClick={openFileLocation}>
                                                {!clickedPicture && (
                                                    <>
                                                        <AddAPhotoIcon className="EntryGateMarshalling_Camera_Icon" />
                                                        <p className="EntryGateMarshalling_Click">
                                                            Click here to take image
                                                        </p>
                                                    </>
                                                )}

                                                {clickedPicture && (
                                                    <img
                                                        src={clickedPicture}
                                                        alt="camera-img"
                                                        className="EntryGateMarshalling_Camera_Image"
                                                    />
                                                )}
                                            </div>
                                            <input
                                                type="file"
                                                accept="image/*"
                                                ref={fileInputRef}
                                                style={{ display: 'none' }}
                                                onChange={handleFileInputChange}
                                            />
                                        </div>
                                    </Grid>
                                    <Grid
                                        xl={6}
                                        lg={6}
                                        md={6}
                                        xs={12}
                                        sm={12}
                                        className="EntryGateMarshalling_Form_Two_Container"
                                    >
                                        <div style={{ width: '100%' }}>
                                            <p>Field Selection</p>
                                        </div>
                                        <div className="TextInput_Main">
                                            <label htmlFor="name">No. Of Cows</label>

                                            <Input
                                                type="number"
                                                name="numberOfCows"
                                                value={formik.values.numberOfCows}
                                                onChange={formik.handleChange}
                                                disabled={true}
                                                className="TextInput"
                                                error={formik.touched.numberOfCows && formik.errors.numberOfCows}
                                                placeholder="Type Here"
                                                onKeyDown={handleNumberKeyDown}
                                                inputProps={{
                                                    inputMode: 'numeric',
                                                }}
                                            />

                                            {formik.touched.gender && formik.errors.gender && (
                                                <div className="error">{formik.errors.gender}</div>
                                            )}
                                        </div>
                                        <div className="TextInput_Main">
                                            <label htmlFor="name">No. Of Camels</label>
                                            <Input
                                                type="number"
                                                name="numberOfCamels"
                                                value={formik.values.numberOfCamels}
                                                onChange={formik.handleChange}
                                                disabled={true}
                                                className="TextInput"
                                                error={formik.touched.numberOfCamels && formik.errors.numberOfCamels}
                                                placeholder="Type Here"
                                                onKeyDown={handleNumberKeyDown}
                                                inputProps={{
                                                    inputMode: 'numeric',
                                                }}
                                            />

                                            {formik.touched.numberOfCamels && formik.errors.numberOfCamels && (
                                                <div className="error">{formik.errors.numberOfCamels}</div>
                                            )}
                                        </div>
                                        <div className="TextInput_Main">
                                            <label htmlFor="name">No. Of Goats</label>
                                            <Input
                                                type="number"
                                                name="numberOfGoats"
                                                value={formik.values.numberOfGoats}
                                                onChange={formik.handleChange}
                                                disabled={true}
                                                className="TextInput"
                                                error={formik.touched.numberOfGoats && formik.errors.numberOfGoats}
                                                placeholder="Type Here"
                                                onKeyDown={handleNumberKeyDown}
                                                inputProps={{
                                                    inputMode: 'numeric',
                                                }}
                                            />
                                            {formik.touched.numberOfGoats && formik.errors.numberOfGoats && (
                                                <div className="error">{formik.errors.numberOfGoats}</div>
                                            )}
                                        </div>
                                        <div className="TextInput_Main">
                                            <label htmlFor="name">Vehicle</label>
                                            <Input
                                                type="text"
                                                name="vehicle"
                                                value={
                                                    formik?.values?.vehicles?.length !== 0
                                                        ? formik.values.vehicles
                                                              .map((item) => item?.tblVehicleCategory?.category)
                                                              .join(', ')
                                                        : ''
                                                }
                                                onChange={formik.handleChange}
                                                disabled={true}
                                                className="TextInput"
                                                // error={formik.touched.vehicles && formik.errors.vehicles}
                                                placeholder="Type Here"
                                                onKeyDown={handleNumberKeyDown}
                                                inputProps={{
                                                    inputMode: 'numeric',
                                                }}
                                            />
                                            {formik.touched.vehicles && formik.errors.vehicles && (
                                                <div className="error">{formik.errors.vehicles}</div>
                                            )}
                                        </div>
                                        <div className="TextInput_Main">
                                            <label htmlFor="name">Drum</label>
                                            <Input
                                                type="number"
                                                name="drum"
                                                value={formik.values.drum}
                                                onChange={formik.handleChange}
                                                className="TextInput"
                                                error={formik.touched.drum && formik.errors.drum}
                                                placeholder="Type Here"
                                                onKeyDown={handleNumberKeyDown}
                                                inputProps={{
                                                    inputMode: 'numeric',
                                                }}
                                            />
                                            {formik.touched.drum && formik.errors.drum && (
                                                <div className="error">{formik.errors.drum}</div>
                                            )}
                                        </div>
                                        <div className="TextInput_Container_CheckBox">
                                            <label>Land Booked</label>
                                            <Checkbox
                                                checked={formik.values.land_booked}
                                                onChange={(event) => {
                                                    formik.handleChange(event);
                                                    formik.setFieldValue('land_booked', event.target.checked);
                                                }}
                                                value={formik.values.land_booked}
                                                color="default"
                                            />
                                        </div>
                                        <div className="TextInput_Main">
                                            <label htmlFor="name">Select Lands</label>
                                            {(!Boolean(formik?.values?.lands.length) ||
                                                (Boolean(formik?.values?.lands.length) &&
                                                    formik?.values?.lands[0]?.customer_type !== 'land_booker')) && (
                                                <>
                                                    <ReuseableSelect
                                                        style={{ width: '100%' }}
                                                        name="state_id"
                                                        formControlClassName="demo-customized-select"
                                                        className={`TextInput select_error`}
                                                        formik={formik}
                                                        value={formik.values.state_id}
                                                        onChange={formik.handleChange}
                                                        error={formik.touched.state_id && formik.errors.state_id}
                                                        optionList={getAllStatesAndLands}
                                                        showOption={'Select lands'}
                                                    />
                                                    {formik.touched.lands && formik.errors.lands && (
                                                        <div className="error">{formik.errors.lands}</div>
                                                    )}
                                                </>
                                            )}

                                            {Boolean(formik?.values?.lands.length) && (
                                                <MultiLandComponent
                                                    lands={formik.values.lands}
                                                    isLandBooker={isLandBooker}
                                                    showCloseButton={!isLandBooker}
                                                    onClose={handleClose}
                                                />
                                            )}
                                        </div>
                                    </Grid>
                                    <Grid item className="Btn_Container" xl={12} md={12} xs={12} sm={12}>
                                        <div className="Btn_Container_Two">
                                            <ButtonGroup
                                                value="Submit"
                                                type="submit"
                                                variant="contained"
                                                customBtnBGColor="#7904d7"
                                                textColor="#fff"
                                                disabled={isLoad}
                                                isLoad={isLoad}
                                            />
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                        </form>
                    </Paper>
                </div>
            )}
            {isCameraOpen && (
                <Camera
                    handleTakePhoto={handleTakePhoto}
                    handleTakePhotoAnimationDone={handleTakePhotoAnimationDone}
                    handleCameraError={handleCameraError}
                    handleCameraStart={handleCameraStart}
                    handleCameraStop={handleCameraStop}
                    dataUri={dataUri}
                    setDataUri={setDataUri}
                    cancelImageCapture={cancelImageCapture}
                    continueImageCapture={continueImageCapture}
                />
            )}
            <LandModal
                open={isOpen}
                setIsOpen={setIsOpen}
                stateLandData={[]}
                id={formik.values.state_id || ''}
                data={getAllStatesAndLands}
                formik={formik}
                // getLand={getLand}
            />
        </div>
    );
};

export default DataEntry;
