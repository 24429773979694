import { fromJS } from 'immutable';
import { COUNTER, LOGOUT } from '../actions/actionTypes';

const initialState = {
    counter: 0,
};

const initialImmutableState = fromJS(initialState);

export default function reducer(state = initialImmutableState, action = {}) {
    switch (action.type) {
        case COUNTER:
            return state.update('counter', (counter) => counter + 1);
        case LOGOUT:
            return state.withMutations((mutableState) => {
                mutableState.set('counter', 0);
            });
        default:
            return state;
    }
}
