import React from 'react';
import { NavLink } from 'react-router-dom';
import { ListItem, ListItemText } from '@material-ui/core';
import OtherMenuContent from 'app/utils/otherMenu';
import './index.scss';

export const OtherMenu = ({ toggleDrawerOpen, windowWidth }) =>
    OtherMenuContent.map((item, index) => (
        <div key={index.toString()}>
            <ListItem
                button
                component={NavLink}
                to={item.link}
                onClick={() => {
                    if (windowWidth <= 1279) {
                        toggleDrawerOpen();
                    }
                }}
            >
                <ListItemText className="list_item" secondary={item.name} />
            </ListItem>
        </div>
    ));
