import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import { Alert } from '@material-ui/lab';
import { useDispatch, useSelector } from 'react-redux';
import { removeAlert } from '../../redux/actions/removeAlert';
import './Notification.scss';

export const Notification = () => {
    const dispatch = useDispatch();
    const alerts = useSelector((state) => state.getIn(['alert', 'alerts']));

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        dispatch(removeAlert());
    };

    return (
        <Snackbar
            className="MuiAlert-root"
            open={alerts.isOpen}
            autoHideDuration={3000}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
            <Alert className="MuiName" severity={alerts.type} onClose={handleClose}>
                {alerts.message}
            </Alert>
        </Snackbar>
    );
};
