import { Box, CircularProgress } from '@material-ui/core';
import React from 'react';
import './loader.scss';

const Loader = ({ height, color, item, size }) => {
    return (
        <Box className="loader" sx={{ height: height, justifyContent: item }}>
            <CircularProgress size={size} style={{ color: color }} />
        </Box>
    );
};

export default Loader;
