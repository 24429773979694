import React from 'react';
import TextField from '@material-ui/core/TextField';
import { FormDialog } from 'app/components/UiElements';
import { Table, TableBody, TableCell, TableRow, TableHead } from '@material-ui/core';
import { SnakeCaseToTiltleCase } from 'app/utils/conversions';

export const EditRecord = ({
    open,
    handleClose,
    data = [],
    editableFields,
    handleChangeEditUser,
    handleUpdateUser,
    editUserIndex,
}) => {
    let indexClone = [];

    const dataIndex = data?.find((idx) => idx?.id === editUserIndex);

    indexClone?.push(dataIndex);

    console.log({ indexClone });
    return (
        <FormDialog
            open={open}
            handleClose={handleClose}
            title="Edit User"
            actionName="Update"
            actionHandler={handleUpdateUser}
        >
            <Table>
                <TableHead>
                    <TableRow>
                        {Object.entries(data[0])?.map(
                            ([heading]) =>
                                editableFields.includes(heading) && (
                                    <TableCell numeric>{SnakeCaseToTiltleCase(heading)}</TableCell>
                                )
                        )}
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        {Object?.entries(indexClone[0])?.map(
                            ([key, value]) =>
                                editableFields.includes(key) && (
                                    <TableCell>
                                        <TextField
                                            autoFocus
                                            margin="dense"
                                            id={value}
                                            type={key === 'phone_number' ? 'number' : 'text'}
                                            value={value}
                                            name={key}
                                            onChange={handleChangeEditUser}
                                            disabled={!editableFields?.includes(key)}
                                        />
                                    </TableCell>
                                )
                        )}
                    </TableRow>
                </TableBody>
            </Table>
        </FormDialog>
    );
};
