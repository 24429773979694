import React, { useState, useEffect } from 'react';
import ButtonGroup from 'app/components/ButtonGroup/ButtonGroup';
import { useHistory } from 'react-router-dom';
import ScanImg from 'assets/images/cattle-mandi/scanning.gif';

const BarcodeScanner = ({ setOpen, isSwitchToGeneral }) => {
    const [barcode, setBarcode] = useState('');
    const history = useHistory();

    useEffect(() => {
        const handleKeyDown = (evt) => {
            if (evt.code === 'Enter') {
                if (barcode) {
                    handleBarcode(barcode);
                    setBarcode('');
                }
            } else {
                if (evt.key !== 'Shift') {
                    setBarcode((prevBarcode) => prevBarcode + evt.key);
                }
            }
        };

        const interval = setInterval(() => {
            setBarcode('');
        }, 20);

        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
            clearInterval(interval);
        };
    }, [barcode]);

    const handleBarcode = (scannedBarcode) => {
        const url = `/payment-collection?id=${scannedBarcode}`;
        const urlTwo = `/pay-booker?id=${scannedBarcode}`;
        history.push(isSwitchToGeneral ? urlTwo : url);
    };

    console.log({ isSwitchToGeneral });
    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
            <img src={ScanImg} alt="scan" style={{ width: 200, height: 200, textAlign: 'center' }} />
            <p style={{ fontFamily: 'Poppins', fontSize: '20px' }}>Waiting for barcode...</p>

            <ButtonGroup
                value="back to Scan Receipt"
                onClick={() => {
                    setOpen(false);
                }}
                variant="contained"
                customBtnBGColor="#7904d7"
                textColor="#fff"
            />
        </div>
    );
};

export default BarcodeScanner;
