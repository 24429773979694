import React from 'react';

import { Route } from 'react-router-dom';
import { ErrorWrap } from '../../components';

export const NotFound = () => (
    <Route
        render={({ staticContext }) => {
            if (staticContext) {
                staticContext.status = 404; // eslint-disable-line
            }
            return (
                <div>
                    <ErrorWrap title="404" desc="Oops, Page Not Found :(" />
                </div>
            );
        }}
    />
);
