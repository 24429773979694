import React from 'react';
import Camera, { FACING_MODES, IMAGE_TYPES } from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';
import CameraImagePreview from './CameraImagePreview';

function ReactCamera({
    handleTakePhoto,
    handleTakePhotoAnimationDone,
    handleCameraError,
    handleCameraStart,
    handleCameraStop,
    dataUri,
    cancelImageCapture,
    continueImageCapture,
}) {
    return (
        <div>
            {dataUri ? (
                <CameraImagePreview
                    dataUri={dataUri}
                    isFullscreen={false}
                    cancelImageCapture={cancelImageCapture}
                    continueImageCapture={continueImageCapture}
                />
            ) : (
                <Camera
                    onTakePhoto={(dataUri) => {
                        handleTakePhoto(dataUri);
                    }}
                    onTakePhotoAnimationDone={(dataUri) => {
                        handleTakePhotoAnimationDone(dataUri);
                    }}
                    onCameraError={(error) => {
                        handleCameraError(error);
                    }}
                    idealFacingMode={FACING_MODES.ENVIRONMENT}
                    idealResolution={{ width: 1040, height: 400 }}
                    imageType={IMAGE_TYPES.JPG}
                    imageCompression={0.97}
                    isMaxResolution={true}
                    isImageMirror={false}
                    isSilentMode={false}
                    isDisplayStartCameraError={true}
                    isFullscreen={false}
                    sizeFactor={1}
                    onCameraStart={(stream) => {
                        handleCameraStart(stream);
                    }}
                    onCameraStop={() => {
                        handleCameraStop();
                    }}
                />
            )}
        </div>
    );
}

export default ReactCamera;
