import { store } from 'app/redux/store';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './app/routes';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import './index.scss';
import { BrowserRouter } from 'react-router-dom';

ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </Provider>,
    document.getElementById('root')
);

// api key of google  AIzaSyDIIl_jefcLkdHUSEdfi8hK4fCBot7wu94

serviceWorkerRegistration.register();
