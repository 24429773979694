import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

export const PrivateRoute = ({ component: Component, ...rest }) => {
    const authenticated = useSelector((state) => state.getIn(['login', 'authenticated']));

    return (
        <Route {...rest} component={(props) => (authenticated ? <Component {...props} /> : <Redirect to={'/'} />)} />
    );
};
