import React from 'react';
import './style.scss';
import Heading from 'app/helpers/Heading';
import { Paper } from '@material-ui/core';
import { brand } from 'app/utils/brand';
export const Help = () => {
    const title = brand.name + ' Help and Support';
    return (
        <div>
            <Heading heading={title} />

            <div className="helpAndSupport_Content_Container">
                <Paper className="helpAndSupport_Content">
                    <p className="text">For any kind of help and query kindly contact at</p>
                    <h2 className="text">+92 310 2472589 &nbsp; (Asra Soft Owner & CEO) - (Muhammad Umer) </h2>
                    <h2 className="text">Developed by (Syed Huzaifa Aslam) & (Muhammad Kashif) & (Ubaid Raza) </h2>
                    <h2 className="text">Project Coordinator (Syed Muhammad Arsalan) </h2>
                </Paper>
            </div>
        </div>
    );
};
