import React, { useState } from 'react';
import classNames from 'classnames';
import { IconButton, InputAdornment, TextField, Button, CircularProgress } from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import ArrowForward from '@material-ui/icons/ArrowForward';
import ASLogo from 'assets/images/asraLogo3.png';
// import NFLLOGO from 'assets/images/nflLogo.png';
import NFLLOGO from 'assets/images/cattle-mandi/mandiLogoMain.webp';
import './LoginForm.scss';
import { Paper } from '@material-ui/core';
import { showNflBrand } from 'app/helpers';

// validation functions
const required = (value) => (value == null ? 'Required' : undefined);

const LoginForm = ({ onSubmit, valueForm, onChange, error, loading }) => {
    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    return (
        <div className="LoginForm__formWrap">
            <img src={showNflBrand ? NFLLOGO : ASLogo} className={'LoginForm__brandImage'} alt="brand" />
            <Paper className="LoginForm__formContainer">
                <h1 className="LoginForm__mainHeading"> Login</h1>
                {!!error && <p style={{ color: 'red' }}>{error}</p>}
                <form>
                    <TextField
                        name="id"
                        placeholder="Login ID"
                        label="Login ID"
                        required
                        validate={[required]}
                        className={'LoginForm__field'}
                        value={valueForm.id}
                        autoComplete={false}
                        onChange={onChange}
                    />
                    <TextField
                        name="password"
                        type={showPassword ? 'text' : 'password'}
                        label="Password"
                        value={valueForm.password}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="Toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                    >
                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                        required
                        validate={required}
                        className={'LoginForm__field'}
                        onChange={onChange}
                    />

                    <div className={'LoginForm__btnArea'}>
                        <Button variant="contained" color="primary" onClick={onSubmit} disabled={loading}>
                            Continue &nbsp;
                            {loading ? (
                                <CircularProgress className="form_loader" size={20} />
                            ) : (
                                <ArrowForward className={classNames('rightIcon', 'iconSmall')} />
                            )}
                        </Button>
                    </div>
                    <br />
                </form>
            </Paper>
        </div>
    );
};

export default LoginForm;
