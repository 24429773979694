import React, { useEffect, useState } from 'react';
import Heading from 'app/helpers/Heading';
import { Grid, Paper, Divider, Typography, Box } from '@material-ui/core';
import './styles.scss';
import ButtonGroup from 'app/components/ButtonGroup/ButtonGroup';
import { useDispatch, useSelector } from 'react-redux';
import { setAlert } from 'app/redux/actions/alertAction';
import { axiosInstance, errorHandler } from 'app/helpers';
import Loader from 'app/components/loader/Loader';
import { useHistory } from 'react-router-dom';
import ConfirmationModal from 'app/components/ConfirmationModal/ConfirmationModal';

const amount_obj = {
    100: 60000,
    120: 65000,
    150: 70000,
    200: 75000,
    250: 80000,
    300: 85000,
    350: 90000,
    1000: 250000,
    500: 150000,
    1000: 250000,
    500: 150000,
    80: 50000,
    50: 0,
};

const vehicle_name = {
    'Pick up': 1500,
    '4-6 Wheelers': 2200,
    '8-16 Wheelers': 3500,
    '18-22 Wheelers': 4500,
};

const PaymentCollection = () => {
    const [isLoad, setIsLoad] = useState();
    const [data, setData] = useState({});
    const [total, setTotal] = useState(0);
    const dispatch = useDispatch();
    const location = window.location.search;
    const params = new URLSearchParams(location);
    const id = params.get('id');
    const history = useHistory();

    const userRole = useSelector((state) => state.getIn(['login', 'data', 'role']));

    const [open, setOpen] = useState(false);
    const [openDeclineModal, setOpenDeclineModal] = useState(false);
    const [isLoadUpdate, setIsLoadUpdate] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleDeclineModalOpen = () => setOpenDeclineModal(true);
    const handleDeclineModalClose = () => setOpenDeclineModal(false);

    useEffect(() => {
        if (data && data?.lands && data?.tblmarshalling) {
            // const areaAmount = amount_obj[data.tblLand.area] || 0;

            const landPrice = data?.lands?.reduce((prev, curr) => {
                if (curr?.status === 'on-hold') {
                    return prev + Number(curr.tblLand?.tblstate?.price);
                }
                return prev + 0;
            }, 0);

            const vehiclePrice = data?.tblmarshalling?.vehicles?.reduce((prev, curr) => {
                if (curr?.paymentStatus === 'unpaid') {
                    return prev + curr.tblVehicleCategory?.price;
                }
                return prev + 0;
            }, 0);

            const total =
                (data.noOfCamels - (data?.paidAutomations?.noOfCamels || 0) || 0) * 4250 +
                (data.noOfCows - (data?.paidAutomations?.noOfCows || 0) || 0) * 4250 +
                (data.noOfGoats - (data?.paidAutomations?.noOfGoats || 0) || 0) * 2000 +
                (data.drum_count - (data?.paidAutomations?.drum_count || 0) || 0) * 2500 +
                (vehiclePrice || 0) +
                (data?.lands[0]?.customer_type !== 'land_booker' ? landPrice : 0);

            setTotal(total);
        }
    }, [data, amount_obj, vehicle_name]);

    const dataOfSlip = [
        {
            id: 1,
            rows: (
                <>
                    <Grid item xl={6} lg={6} md={6} xs={6} sm={6} className="PaymentCollection_paddingTop">
                        <p className="PaymentCollection_Card_Box_Left_Text">Name</p>
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} xs={6} sm={6} className="PaymentCollection_Card_EndItem">
                        <p className="PaymentCollection_Card_Box_Right_Text">{data?.name}</p>
                    </Grid>
                </>
            ),
        },
        {
            id: 2,
            rows: (
                <>
                    <Grid item xl={6} lg={6} md={6} xs={6} sm={6} className="PaymentCollection_paddingTop">
                        <p className="PaymentCollection_Card_Box_Left_Text">Number</p>
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} xs={6} sm={6} className="PaymentCollection_Card_EndItem">
                        <p className="PaymentCollection_Card_Box_Right_Text">{data?.contactNo}</p>
                    </Grid>
                </>
            ),
        },
        {
            id: 3,
            rows: (
                <>
                    <Grid item xl={6} lg={6} md={6} xs={6} sm={6} className="PaymentCollection_paddingTop">
                        <p className="PaymentCollection_Card_Box_Left_Text">N.I.C</p>
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} xs={6} sm={6} className="PaymentCollection_Card_EndItem">
                        <p className="PaymentCollection_Card_Box_Right_Text">{data?.cnic}</p>
                    </Grid>
                </>
            ),
        },
        {
            id: 4,
            rows: (
                <>
                    <Grid item xl={6} lg={6} md={6} xs={6} sm={6} className="PaymentCollection_paddingTop">
                        <p className="PaymentCollection_Card_Box_Left_Text">Animals</p>
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} xs={6} sm={6} className="PaymentCollection_Card_EndItem">
                        <p className="PaymentCollection_Card_Box_Right_Text">
                            {data?.noOfCamels + data?.noOfCows + data?.noOfGoats}
                        </p>
                    </Grid>
                </>
            ),
        },
        {
            id: 5,
            rows: (
                <>
                    <Grid item xl={6} lg={6} md={6} xs={6} sm={6} className="PaymentCollection_paddingTop">
                        <p className="PaymentCollection_Card_Box_Left_Text">Drum</p>
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} xs={6} sm={6} className="PaymentCollection_Card_EndItem">
                        <p className="PaymentCollection_Card_Box_Right_Text">{data?.drum_count || 0}</p>
                    </Grid>
                </>
            ),
        },
        {
            id: 6,
            rows: (
                <>
                    <Grid item xl={6} lg={6} md={6} xs={6} sm={6} className="PaymentCollection_paddingTop">
                        <p className="PaymentCollection_Card_Box_Left_Text">Land</p>
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} xs={6} sm={6} className="PaymentCollection_Card_EndItem">
                        <p className="PaymentCollection_Card_Box_Right_Text">
                            {data?.lands ? data?.lands.map((item) => item?.tblLand?.block).join(', ') : ''}
                        </p>
                    </Grid>
                </>
            ),
        },
        {
            id: 7,
            rows: (
                <>
                    <Divider style={{ width: '100%', marginTop: '10px' }} />
                </>
            ),
        },
        {
            id: 8,
            rows: (
                <>
                    <Grid item xl={6} lg={6} md={6} xs={6} sm={6} className="PaymentCollection_paddingTop_max">
                        <p className="PaymentCollection_Card_Box_Left_Text">Total</p>
                    </Grid>
                    <Grid
                        item
                        xl={6}
                        lg={6}
                        md={6}
                        xs={6}
                        sm={6}
                        className="PaymentCollection_Card_EndItem PaymentCollection_paddingTop_max"
                    >
                        <p className="PaymentCollection_Card_Box_Right_Text">{total}</p>
                    </Grid>
                </>
            ),
        },
    ];

    const getSlipData = async () => {
        try {
            setIsLoad(true);
            const { data } = await axiosInstance.get(`/cattleMandi/get-automation?id=${id}`);
            setData(data?.data);
        } catch (error) {
            dispatch(
                setAlert({
                    isOpen: true,
                    type: 'error',
                    message: errorHandler(error),
                })
            );
            setTimeout(() => {
                dispatch(setAlert({}));
            }, 5000);
        } finally {
            setIsLoad(false);
        }
    };

    const updatePaymentSlipHandler = async () => {
        try {
            setIsLoadUpdate(true);
            const { data } = await axiosInstance.put('/cattleMandi/update-automation-status', {
                id: id,
            });
            dispatch(
                setAlert({
                    isOpen: true,
                    type: 'success',
                    message: data?.data.message,
                })
            );
            setTimeout(() => {
                dispatch(setAlert({}));
            }, 1000);
        } catch (error) {
            dispatch(
                setAlert({
                    isOpen: true,
                    type: 'error',
                    message: errorHandler(error),
                })
            );
            setTimeout(() => {
                dispatch(setAlert({}));
            }, 1000);
        } finally {
            setIsLoadUpdate(false);
            handleClose();
            getSlipData();
        }
    };

    useEffect(() => {
        getSlipData();
    }, [location]);

    const roles = ['cattleAdmin', 'automation'];
    const hideButton = roles.includes(userRole);

    return (
        <div className="PaymentCollection_Container">
            <Heading heading="Payment Collection" />

            {isLoad ? (
                <Loader color="#000" height="65vh" />
            ) : (
                <div className="PaymentCollection_Main_Container">
                    {data && (
                        <Paper className="PaymentCollection_Card">
                            <div className="PaymentCollection_Card_Content">
                                <div>
                                    <p className="Slip_Heading">Slip</p>
                                </div>

                                <div className="PaymentCollection_Grid_Container">
                                    <Grid container>
                                        {dataOfSlip.map((item, index) => {
                                            return item.rows;
                                        })}
                                    </Grid>
                                </div>
                            </div>

                            <div className="PaymentCollection_Status">
                                <p>
                                    Payment Status:{' '}
                                    <span style={{ color: data?.paymentStatus === 'unpaid' ? 'red' : 'green' }}>
                                        {' '}
                                        {data && data?.paymentStatus}{' '}
                                    </span>
                                </p>
                            </div>

                            {data?.paymentStatus === 'unpaid' && userRole !== 'automation' && (
                                <Grid container className="PaymentCollection_Card_EndItem_Button">
                                    <Grid xl={5} lg={5} md={5} xs={12} sm={12}>
                                        <ButtonGroup
                                            value={'Verify'}
                                            type="submit"
                                            variant="contained"
                                            customBtnBGColor="#7904d7"
                                            textColor="#fff"
                                            onClick={() => {
                                                handleOpen();
                                            }}
                                        />
                                    </Grid>
                                    <Grid
                                        xl={5}
                                        lg={5}
                                        md={5}
                                        xs={12}
                                        sm={12}
                                        className="PaymentCollection_Card_EndItem_Button_Two"
                                    >
                                        <ButtonGroup
                                            value={'Decline'}
                                            type="submit"
                                            variant="contained"
                                            customBtnBGColor="#FF5259"
                                            textColor="#fff"
                                            onClick={() => {
                                                handleDeclineModalOpen();
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            )}

                            <ConfirmationModal
                                open={open}
                                handleOpen={handleOpen}
                                handleClose={handleClose}
                                yesButtonOnClick={updatePaymentSlipHandler}
                                isLoad={isLoadUpdate}
                                labelOne="Confirm Payslip Verification"
                                labelTwo="Are you sure you want to mark this payslip as paid?"
                            />

                            <ConfirmationModal
                                open={openDeclineModal}
                                handleOpen={handleDeclineModalOpen}
                                handleClose={handleDeclineModalClose}
                                yesButtonOnClick={() => {
                                    history.push('/scan-receipt');
                                }}
                                // isLoad={isLoadUpdate}
                                labelOne="Decline Payslip Verification"
                                labelTwo="Are you sure you want to decline this payslip verification?"
                            />

                            {data?.paymentStatus === 'paid' && !hideButton && (
                                <Grid container className="PaymentCollection_Card_Generate_Button">
                                    <Grid xl={5} lg={5} md={5} xs={12} sm={12}>
                                        <ButtonGroup
                                            value="Scan Other Receipt"
                                            type="submit"
                                            variant="contained"
                                            customBtnBGColor="#7904d7"
                                            textColor="#fff"
                                            onClick={() => {
                                                history.push('/scan-receipt');
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            )}

                            {data?.paymentStatus === 'paid' && hideButton && (
                                <Grid container className="PaymentCollection_Card_EndItem_Button">
                                    <Grid xl={5} lg={5} md={5} xs={12} sm={12}>
                                        <ButtonGroup
                                            value={'Generate PVC'}
                                            type="submit"
                                            variant="contained"
                                            customBtnBGColor="#7904d7"
                                            textColor="#fff"
                                            onClick={() => {
                                                history.push(`/view-pvc/${id}`);
                                            }}
                                        />
                                    </Grid>
                                    <Grid
                                        xl={5}
                                        lg={5}
                                        md={5}
                                        xs={12}
                                        sm={12}
                                        className="PaymentCollection_Card_EndItem_Button_Two"
                                    >
                                        <ButtonGroup
                                            value={'Scan Other Receipt'}
                                            type="submit"
                                            variant="contained"
                                            customBtnBGColor="#FF5259"
                                            textColor="#fff"
                                            onClick={() => {
                                                history.push('/scan-receipt');
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            )}
                        </Paper>
                    )}

                    {!data && (
                        <Paper className="PaymentCollection_Card">
                            <div className="PaymentCollection_Card_Content">
                                <div>
                                    <p className="Slip_Heading">Slip</p>
                                </div>

                                <div className="PaymentCollection_NotFound_Container">
                                    <Typography variant="h4">No Entry Found</Typography>

                                    <Box sx={{ mt: 1 }}>
                                        <ButtonGroup
                                            value="Scan Other Receipt"
                                            type="submit"
                                            variant="contained"
                                            customBtnBGColor="#7904d7"
                                            textColor="#fff"
                                            onClick={() => {
                                                history.push('/scan-receipt');
                                            }}
                                        />
                                    </Box>
                                </div>
                            </div>
                        </Paper>
                    )}
                </div>
            )}
        </div>
    );
};

export default PaymentCollection;
