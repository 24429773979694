const drawerWidth = 240;
const styles = (theme) => ({
    user: {
        justifyContent: 'center',
    },
    drawerPaper: {
        position: 'relative',
        height: '100%',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.default,
        border: 'none',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    swipeDrawerPaper: {
        width: drawerWidth,
    },
    opened: {
        background: theme.palette.grey[200],
        '& $primary, & $icon': {
            color: theme.palette.secondary.dark,
        },
    },
    drawerPaperClose: {
        width: 66,
        position: 'absolute',
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        '& $user': {
            justifyContent: 'flex-start',
        },
        '& $bigAvatar': {
            width: 40,
            height: 40,
        },
        '& li ul': {
            display: 'none',
        },
        '& $menuContainer': {
            paddingLeft: theme.spacing.unit * 1.5,
            paddingRight: theme.spacing.unit * 1.5,
        },
    },
    drawerInner: {
        // Make the items inside not wrap when transitioning:
        width: drawerWidth,
        height: '100%',
    },
    drawerHeader: {
        alignItems: 'center',
        background: '#e0dcfe',
        color: theme.palette.primary.contrastText,
        ...theme.mixins.toolbar,
        '& h3': {
            color: theme.palette.primary.contrastText,
        },
        padding: 10,
        height: 184,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    heading: {
        display: 'flex',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        width: '100%',
        height: 0,
    },
    menuClosed: {
        display: 'flex',
        width: '30%',
        height: 0,
        justifyContent: 'center',
        alignItems: 'center',
    },
    headingText: {
        fontSize: '10px',
        fontFamily: 'Poppins',
    },
    drawerHeader_two: {
        background: '#e0dcfe',
        color: theme.palette.primary.contrastText,
        ...theme.mixins.toolbar,
        '& h3': {
            color: theme.palette.primary.contrastText,
        },
        padding: 10,
        height: 184,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    avatar: {
        margin: 10,
    },
    bigAvatar: {
        width: 80,
        height: 80,
    },
    brandBar: {
        transition: theme.transitions.create(['width', 'margin', 'background'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        '&:after': {
            transition: theme.transitions.create(['box-shadow'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
    },
    nested: {
        paddingLeft: theme.spacing.unit * 2,
        paddingTop: theme.spacing.unit * 1.5,
        paddingBottom: theme.spacing.unit * 1.5,
    },
    child: {
        '& a': {
            paddingLeft: theme.spacing.unit * 6,
        },
    },
    dense: {
        marginLeft: -15,
    },

    // active: {
    //   // ckgroundColor: theme.palette.primary.light,
    //   //"& $root, & $root,& $primary, & $icon": {
    //   "& $root $root $nested , & $root,& $primary": {
    //     color: theme.palette.secondary.dark,
    //   },
    //   "&:hover": {
    //     backgroundColor: theme.palette.primary.light,
    //   },
    // },
    nolist: {
        listStyle: 'none',
    },
    primary: {
        padding: 0,
        fontFamily: 'Poppins',
    },
    icon: {
        marginRight: 0,
        color: '#7904D7',
    },
    head: {
        paddingLeft: 16,
    },
    brand: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '10px 10px 5px',
        height: 64,
        position: 'relative',
        '& img': {
            width: 20,
        },
        '& h3': {
            fontSize: 16,
            margin: 0,
            paddingLeft: 10,
            fontWeight: 500,
        },
    },
    profile: {
        height: 120,
        display: 'flex',
        fontSize: 14,
        padding: 10,
        alignItems: 'center',
        '& h4': {
            fontSize: 18,
            marginBottom: 0,
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            width: 110,
        },
        '& span': {
            fontSize: 12,
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            width: 110,
            display: 'block',
            overflow: 'hidden',
        },
    },
    menuContainer: {
        padding: theme.spacing.unit * 2,
        paddingRight: theme.spacing.unit,
        paddingBottom: 0,
        overflow: 'auto',
        height: 'calc(100% - 237px)',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        // backgroundColor: theme.palette.primary.light,

        // "& primary": {
        //   "&:active": {
        //     color: theme.palette.secondary.dark,
        //   },
        // },
        // // "&:hover": {
        // //   backgroundColor: theme.palette.primary.light,
        // // },
    },

    divider: {
        marginTop: theme.spacing.unit,
    },
    activeMediaLogo: {
        width: 130,
        mixBlendMode: 'multiply',
        // height: 44,
    },
    activeMediaLogo_two: {
        width: 50,
        mixBlendMode: 'multiply',
        // height: 44,
    },
    brandName: {
        color: '#fff',
        fontSize: 30,
        marginBottom: 27,
    },
    brandContainer: {
        display: 'flex',
        alignItems: 'center',
        columnGap: 10,
    },
    brandImage: {
        width: 107,
        height: 64,
    },
});

export default styles;
