import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Icon from '@material-ui/core/Icon';
import MenuContent from 'app/utils/menu';
import styles from './sidebar-jss';
import { toggleAction } from 'app/redux/actions/UiActions';

class MainMenu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            windowWidth: window.innerWidth,
        };
    }

    componentDidMount() {
        window.addEventListener('resize', this.handleResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize = () => {
        this.setState({
            windowWidth: window.innerWidth,
        });
    };

    render(props) {
        const { classes, toggleDrawer } = this.props;

        const MenuContentMain = MenuContent.filter((item, i) => item.showRoute === true);

        return MenuContentMain.map((item, index) => (
            <ListItem
                key={index?.toString()}
                button
                exact
                className={classes?.nested}
                // className={classes.active}
                activeClassName={classes?.active}
                component={NavLink}
                to={item?.link}
            >
                {item.icon && (
                    <ListItemIcon>
                        <Icon className={classes?.icon}>{item?.icon}</Icon>
                    </ListItemIcon>
                )}
                {item.name && (
                    <ListItemText
                        classes={{ primary: classes?.primary }}
                        inset
                        primary={item?.name}
                        style={{ padding: 0 }}
                        onClick={() => this.state.windowWidth <= 1279 && toggleDrawer()}
                    />
                )}
            </ListItem>
        ));
    }
}

const openAction = (key, keyParent) => ({
    type: 'OPEN_SUBMENU',
    key,
    keyParent,
});
const reducer = 'ui';

const mapStateToProps = (state) => ({
    force: state, // force active class for sidebar menu
    open: state.getIn([reducer, 'subMenuOpen']),
});

const mapDispatchToProps = (dispatch) => ({
    openSubMenu: bindActionCreators(openAction, dispatch),
    toggleDrawer: () => dispatch(toggleAction),
});

const MainMenuMapped = connect(mapStateToProps, mapDispatchToProps)(MainMenu);

export default withStyles(styles)(MainMenuMapped);
