import React from 'react';
import PropTypes from 'prop-types';

import './imagePreview.scss';
import ButtonGroup from '../ButtonGroup/ButtonGroup';
import { Grid } from '@material-ui/core';

export const CameraImagePreview = ({ dataUri, isFullscreen, cancelImageCapture, continueImageCapture }) => {
    let classNameFullscreen = isFullscreen ? 'demo-image-preview-fullscreen' : '';

    return (
        <div className={'demo-image-preview ' + classNameFullscreen}>
            <img src={dataUri} />

            <div>
                <Grid container style={{ display: 'flex', justifyContent: 'flex-end', paddingRight: '2px' }}>
                    <Grid xl={2} lg={2} md={2} xs={12} sm={12} style={{ paddingRight: '5px' }}>
                        <ButtonGroup
                            onClick={() => continueImageCapture()}
                            value="Continue"
                            variant="contained"
                            customBtnBGColor="#7904d7"
                            textColor="#fff"
                        />
                    </Grid>
                    <Grid xl={2} lg={2} md={2} xs={12} sm={12} style={{ paddingRight: '5px' }}>
                        <ButtonGroup
                            onClick={() => cancelImageCapture()}
                            value="Cancel"
                            variant="contained"
                            customBtnBGColor="#FF5259"
                            textColor="#fff"
                        />
                    </Grid>
                </Grid>
            </div>
        </div>
    );
};

CameraImagePreview.propTypes = {
    dataUri: PropTypes.string,
    isFullscreen: PropTypes.bool,
};

export default CameraImagePreview;
