import React, { useEffect, useRef, useState } from 'react';
import './style.scss';
import Heading from 'app/helpers/Heading';
import { Paper } from '@material-ui/core';
import ButtonGroup from 'app/components/ButtonGroup/ButtonGroup';
// import Quagga from 'quagga';
import BarcodeScanner from './BarcodeScanner';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';

const Home = () => {
    const [open, setOpen] = useState(false);
    const [alignment, setAlignment] = useState('left');

    const handleChange = (ev, alignment) => {
        setAlignment(alignment);
    };

    const control = {
        value: alignment,
        onChange: handleChange,
        exclusive: true,
    };

    return (
        <div className="CattleMandiHome_Container">
            <Heading heading="Scan Receipt" />

            <div className="CattleMandiHome_Main_Container">
                <Paper className="CattleMandiHome_Card">
                    <div id="your-scanner-container"></div>
                    <div
                        style={{
                            height: '50vh',
                            minHeight: '50vh',
                            display: !open ? 'none' : 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <BarcodeScanner setOpen={setOpen} isSwitchToGeneral={alignment === 'right' ? true : false} />
                    </div>
                    <div style={{ padding: '30px', paddingBottom: 0, display: open ? 'none' : 'flex' }}>
                        <ToggleButtonGroup {...control} style={{ marginRight: '10px' }}>
                            <ToggleButton value="left" key="left">
                                <p>Switch to General</p>
                            </ToggleButton>
                        </ToggleButtonGroup>
                        <ToggleButtonGroup {...control}>
                            <ToggleButton value="right" key="right">
                                <p>Switch to Land booker</p>
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </div>
                    <div
                        style={{
                            height: '30vh',
                            minHeight: '30vh',
                            display: open ? 'none' : 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <ButtonGroup
                            value="Start Scanning"
                            onClick={() => setOpen(true)}
                            variant="contained"
                            customBtnBGColor="#7904d7"
                            textColor="#fff"
                        />
                    </div>
                </Paper>
            </div>
        </div>
    );
};

export default Home;
