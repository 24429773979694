import React, { useEffect, useState } from 'react';
import { Dashboard,  Notification, PrivateRoute } from './../components';
import LoginDedicated from './Pages/Standalone/LoginDedicated';
import { Help } from './Help';
import { Switch, Route, useLocation } from 'react-router-dom';
import { NotFound } from './NotFound';
import { axiosInstance, userRoles } from 'app/helpers';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from 'app/redux/actions/loginAction';
import { setAlert } from 'app/redux/actions/alertAction';
import { tabName } from 'app/helpers/tabName';
import ErrorBoundary from 'app/helpers/ErrorBoundry';
import MainPageComponents from 'app/components/mainPageComponents/MainPageComponents';
import PaymentCollection from './PaymentCollection';
import CattleMandiHome from './Home';
import DataEntry from './DataEntry';
import UpdateDataEntry from './Update/DataEntry';
import ExitRecordForm from './ExitRecordForm';
import EntryGateMarshalling from './EntryGateMarshalling';
import UpdateEntryGateMarshalling from './Update/EntryGateMarshalling';
import CattleDashboard from './Dashboard';
import CattleMandiTables from './MarshallingRecord/MarshallingRecord';
import ViewReceipt from './ViewReceipt';
import AutomationRecord from './AutomationRecord/AutomationRecord';
import BankRecord from './BankRecord/BankRecord';
import ViewPvc from './ViewPvc';
import LandBooker from './LandBooker/LandBooker';
import LandBlookerSlip from './LandBlookerSlip/LandBlookerSlip';
import ViewLandReceipt from './ViewLandReceipt';
import LandBookerRecord from './LandBookerRecord/LandBookerRecord' ;
import UpdateLandBooker from './Update/LandBooker/LandBooker';
const config = require('../config/config.json');
const routesName = config.app;

export default function Routes() {
    const userRole = useSelector((state) => state.getIn(['login', 'data', 'role']));
    const [loading, setLoading] = useState(true);
    const dispatch = useDispatch();
    const location = useLocation();
    const getTitle = (pathName) => {
        const route = tabName?.find((Ele) => Ele.path === pathName);
        return route ? `${route.title} | DDS Asra Soft` : 'DDS Asra Soft';
    };

    useEffect(() => {
        document.title = getTitle(location.pathname);
    }, [location.pathname]);

    axiosInstance.interceptors.response.use(undefined, (err) => {
        if (err?.response?.status === 401) {
            localStorage.clear();
            dispatch(logout());
            return dispatch(
                setAlert({
                    isOpen: true,
                    type: 'error',
                    message: 'Session Expired!',
                })
            );
        }
        return Promise.reject(err);
    });

    const componentMap = {
        paymentCollection: PaymentCollection,
        entryGateMarshalling: EntryGateMarshalling,
        dataEntry: DataEntry,
        exitRecordForm: ExitRecordForm,
        dashboard: CattleDashboard,
        cattleMandiTables: CattleMandiTables,
        automationRecord: AutomationRecord,
        bankRecord: BankRecord,
        viewReciept: ViewReceipt,
        viewPvc: ViewPvc,
        home: CattleMandiHome,
        updateDataEntry: UpdateDataEntry,
        updateEntryGateMarshalling: UpdateEntryGateMarshalling,
        landBooker: LandBooker,
        payBooker: LandBlookerSlip,
        viewLandReceipt: ViewLandReceipt,
        landBookerRecord: LandBookerRecord,
        updateLandBooker:UpdateLandBooker
    };


    const renderUserRoutes = (userRole) => {
        const allowedRoutes = routesName[userRole] || [];

        const routes = allowedRoutes.filter((item, i) => item.showRoute === true);
        return (
            <Switch>
                {routes.map((data, i) => {
                    return <Route key={i} exact path={data?.route} component={componentMap[data?.component]} />;
                })}
                <PrivateRoute path="/help" exact component={Help} />
                <PrivateRoute path="*" component={NotFound} />
                {/* <Redirect to="/unauthorized" /> */}
            </Switch>
        );
    };

    useEffect(() => {
        setTimeout(() => setLoading(false), 1000);
    }, []);

    if (loading) return <MainPageComponents />;

    return (
        <>
            <Notification />
            <ErrorBoundary>
                <Switch>
                    <Route path="/" exact component={LoginDedicated} />
                    {/* {userRole !== userRoles.supervisor && (
                    <PrivateRoute path="/compaign" exact component={CompaignSelection} />
                    )} */}
                    {userRoles?.[userRole] && (
                        <Dashboard>
                            <Switch>{renderUserRoutes(userRole)}</Switch>
                        </Dashboard>
                    )}
                    <PrivateRoute path="*" component={NotFound} />
                </Switch>
            </ErrorBoundary>
        </>
    );
}
