import React from 'react';
import './style.scss';

const Heading = ({ heading }) => {
    return (
        <div className="heading_container">
            <h1 className="heading">{heading}</h1>
        </div>
    );
};

export default Heading;
