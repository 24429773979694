import React, { useEffect, useRef, useState } from 'react';
import './style.scss';
import Heading from 'app/helpers/Heading';
import { Grid, Paper, Typography } from '@material-ui/core';
import ButtonGroup from 'app/components/ButtonGroup/ButtonGroup';
import ReuseableSelect from 'app/components/Select/Select';
import Input from 'app/components/Input/Input';
import { brand } from 'app/utils/brand';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { setAlert } from 'app/redux/actions/alertAction';
import { useDispatch } from 'react-redux';
import Camera from 'app/components/ReactCamera';
import { axiosInstance, dataURItoBlob, errorHandler } from 'app/helpers';
import { useHistory, useParams } from 'react-router-dom';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import Loader from 'app/components/loader/Loader';
import LandModal from 'app/components/LandModal/LandModal';
import CloseIcon from '@material-ui/icons/Close';
import { MultiLandComponent } from 'app/components';

const LandNamesData = [
    {
        id: 1,
        name: 'Punjab',
        value: 'punjab',
    },
    {
        id: 2,
        name: 'Sindh',
        value: 'sindh',
    },
    {
        id: 3,
        name: 'Balouchistan',
        value: 'balouchistan',
    },
    {
        id: 4,
        name: 'Azad Kashmir',
        value: 'azad kashmir',
    },
    {
        id: 5,
        name: 'Gilgit Baltistan',
        value: 'gilgit baltistan',
    },
];

const validationSchema = Yup.object().shape({
    name: Yup.string().required('required'),
    NicNo: Yup.string().required('required'),
    contactNo: Yup.string().required('required'),
    numberOfCows: Yup.string().required('required'),
    numberOfCamels: Yup.string().required('required'),
    numberOfGoats: Yup.string().required('required'),
    drum: Yup.string().required('required'),
    image: Yup.string().required('required'),
    lands: Yup.array().required('required'),
});

const DataEntry = () => {
    const { id } = useParams();
    const fileInputRef = useRef(null);
    const dispatch = useDispatch();
    const [isCameraOpen, setIsCameraOpen] = useState(false);
    const [dataUri, setDataUri] = useState('');
    const [clickedPicture, setClickedPicture] = useState('');

    const [getAllStatesAndLands, setGetAllStatesAndLands] = useState([]);
    const [isLoad, setIsLoad] = useState(false);
    const [isGettingLoad, setIsGettingLoad] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const history = useHistory();

    const formik = useFormik({
        initialValues: {
            name: '',
            marshalling_id: '',
            NicNo: '',
            contactNo: '',
            numberOfCows: 0,
            numberOfCamels: 0,
            numberOfGoats: 0,
            drum: '',
            image: '',
            lands: [],
            vehicles: [],
            state_id: '',
            deleteLand: [],
            land_ids: [],
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            setIsLoad(true);
            const filteredArray = values.lands.filter((obj) => !values.land_ids.includes(obj.land_id));
            console.log({ filteredArray });
            const Lands = filteredArray?.map((Ele) => {
                return {
                    land_id: Ele?.id,
                    automation_id: id,
                };
            });
            const formData = new FormData();
            formData.append('id', id);
            formData.append('name', values.name);
            formData.append('cnic', values.NicNo);
            formData.append('contactNo', values.contactNo);
            formData.append('noOfCows', values.numberOfCows);
            formData.append('noOfCamels', values.numberOfCamels);
            formData.append('noOfGoats', values.numberOfGoats);
            formData.append('image', clickedPicture ? dataURItoBlob(clickedPicture) : values.image);
            formData.append('drum_count', formik.values.drum);

            formData.append('stringifiedLandsAdded', !!Lands?.length ? JSON.stringify(Lands) : '[]');
            formData.append('stringifiedLandsDeleted', JSON.stringify(values.deleteLand) || '[]');

            // formData.append('stringifiedLands', JSON.stringify(finalLands));
            try {
                await axiosInstance.put('/cattleMandi/update-automation', formData);
                setIsLoad(false);
                formik.resetForm();
                history.push(`/view-reciept/${id}`);
            } catch (error) {
                console.log({ error });
                dispatch(
                    setAlert({
                        isOpen: true,
                        type: 'error',
                        message: errorHandler(error),
                    })
                );
                setTimeout(() => {
                    dispatch(setAlert({}));
                }, 5000);
                setIsLoad(false);
                // API error
            } finally {
                setIsLoad(false);
            }
        },
    });

    const handleNumberKeyDown = (e) => {
        if (e.key === 'e' || e.key === '-') {
            e.preventDefault();
        }
    };

    const handleFileInputChange = (e) => {
        const selectedFile = e.target.files[0];
        console.log({ selectedFile });

        if (selectedFile && selectedFile.type.startsWith('image/')) {
            formik.setFieldValue('image', selectedFile);
        } else {
            dispatch(
                setAlert({
                    isOpen: true,
                    type: 'error',
                    message: 'Only image can selected',
                })
            );
            setTimeout(() => {
                dispatch(setAlert({}));
            }, 5000);
        }
    };

    const openFileLocation = () => {
        setIsCameraOpen(true);
    };

    function handleTakePhoto(dataUri) {
        // Do stuff with the photo...
        console.log('takePhoto');
    }

    function handleTakePhotoAnimationDone(dataUri) {
        // Do stuff with the photo...
        setDataUri(dataUri);
        console.log('takePhoto');
    }

    function handleCameraError(error) {
        console.log('handleCameraError', error);
    }

    function handleCameraStart(stream) {
        console.log('handleCameraStart');
    }

    function handleCameraStop() {
        console.log('handleCameraStop');
    }

    function cancelImageCapture() {
        setDataUri('');
        setIsCameraOpen(false);
    }

    function continueImageCapture() {
        setClickedPicture(dataUri);
        formik.setFieldValue('image', 'image/jpeg');
        setDataUri('');
        setIsCameraOpen(false);
    }

    const getStatesLands = async () => {
        try {
            const { data } = await axiosInstance.get('/cattleMandi/get-state-lands');
            setGetAllStatesAndLands(data?.data?.filter((Ele) => Ele.price == 0));
        } catch (error) {
            dispatch(
                setAlert({
                    isOpen: true,
                    type: 'error',
                    message: errorHandler(error),
                })
            );
            setTimeout(() => {
                dispatch(setAlert({}));
            }, 5000);
        }
    };

    const getAllAutomation = async () => {
        try {
            setIsGettingLoad(true);
            const { data } = await axiosInstance.get('/cattleMandi/get-automation', { params: { id } });
            if (data) {
                formik.setFieldValue('name', data?.data?.name);
                formik.setFieldValue('NicNo', data?.data?.cnic);
                formik.setFieldValue('contactNo', data?.data?.contactNo);
                formik.setFieldValue('numberOfCows', data?.data?.noOfCows);
                formik.setFieldValue('numberOfCamels', data?.data?.noOfCamels);
                formik.setFieldValue('numberOfGoats', data?.data?.noOfGoats);
                formik.setFieldValue('image', data?.data?.picture);
                formik.setFieldValue('lands', data?.data?.lands);
                formik.setFieldValue('drum', data?.data?.drum_count);
                formik.setFieldValue('vehicles', data?.data?.tblmarshalling?.vehicles || []);
                const ids = data?.data?.lands.map((ele) => ele?.land_id);
                formik.setFieldValue('land_ids', ids);
            }
        } catch (error) {
            dispatch(
                setAlert({
                    isOpen: true,
                    type: 'error',
                    message: errorHandler(error),
                })
            );
            setTimeout(() => {
                dispatch(setAlert({}));
            }, 5000);
        } finally {
            setIsGettingLoad(false);
        }
    };

    const handleCloseSpan = (land) => {
        const landsSelected = formik.values.lands;
        const filteredLands = landsSelected.filter((item) => {
            return item?.id !== land?.id;
        });
        formik.setFieldValue('lands', filteredLands);
    };

    const handleDelete = (land) => {
        const landsSelected = formik.values.lands;
        const filteredLands = landsSelected.filter((item) => {
            return item?.id !== land?.id;
        });
        formik.setFieldValue('lands', filteredLands);
        console.log({ a: land?.id });
        const updatedDeleteLand = [...formik.values.deleteLand, Number(land?.id)];
        formik.setFieldValue('deleteLand', updatedDeleteLand);
    };

    useEffect(() => {
        getAllAutomation();
        getStatesLands();
    }, []);

    useEffect(() => {
        if (!!formik.values.state_id && formik.values.state_id !== 'Select lands') {
            setIsOpen(true);
        }
    }, [formik.values.state_id]);

    return (
        <div className="EntryGateMarshalling_Container">
            {!isCameraOpen && <Heading heading="Data Entry Form Automation Cabin" />}
            {!isCameraOpen && (
                <div className="EntryGateMarshalling_Form">
                    <Paper className="EntryGateMarshalling_Card">
                        {isGettingLoad ? (
                            <Loader color="#000" height="100vh" />
                        ) : (
                            <form
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    formik.submitForm();
                                }}
                            >
                                <div className="TextInput_Container">
                                    <Grid container spacing={1}>
                                        <Grid xl={6} lg={6} md={6} xs={12} sm={12}>
                                            <div>
                                                <p>Bio Data</p>
                                            </div>
                                            <div className="TextInput_Main">
                                                <label htmlFor="name">Name</label>
                                                <Input
                                                    id="name"
                                                    type="text"
                                                    placeholder="Type Here"
                                                    name="name"
                                                    value={formik.values.name}
                                                    onChange={formik.handleChange}
                                                    className="TextInput"
                                                    error={formik.touched.name && formik.errors.name}
                                                />
                                                {formik.touched.name && formik.errors.name && (
                                                    <div className="error">{formik.errors.name}</div>
                                                )}
                                            </div>
                                            <div className="TextInput_Main">
                                                <label htmlFor="name">CNIC No.</label>
                                                <Input
                                                    id="NicNo"
                                                    type="text"
                                                    inputProps={{
                                                        inputMode: 'numeric',
                                                    }}
                                                    placeholder="Type Here"
                                                    name="NicNo"
                                                    value={formik.values.NicNo}
                                                    onChange={formik.handleChange}
                                                    className="TextInput"
                                                    error={formik.touched.NicNo && formik.errors.NicNo}
                                                />
                                                {formik.touched.NicNo && formik.errors.NicNo && (
                                                    <div className="error">{formik.errors.NicNo}</div>
                                                )}
                                            </div>
                                            <div className="TextInput_Main">
                                                <label htmlFor="name">Contact No.</label>
                                                <Input
                                                    id="contactNo"
                                                    type="number"
                                                    inputProps={{
                                                        inputMode: 'numeric',
                                                    }}
                                                    placeholder="Type Here"
                                                    name="contactNo"
                                                    value={formik.values.contactNo}
                                                    onChange={formik.handleChange}
                                                    className="TextInput"
                                                    error={formik.touched.contactNo && formik.errors.contactNo}
                                                />
                                                {formik.touched.contactNo && formik.errors.contactNo && (
                                                    <div className="error">{formik.errors.contactNo}</div>
                                                )}
                                            </div>
                                            <div className="TextInput_Main">
                                                <label htmlFor="name">Picture</label>

                                                <div
                                                    className="EntryGateMarshalling_Camera_Box"
                                                    onClick={openFileLocation}
                                                >
                                                    {!clickedPicture ||
                                                        (!formik.values.image && (
                                                            <>
                                                                <AddAPhotoIcon className="EntryGateMarshalling_Camera_Icon" />
                                                                <p className="EntryGateMarshalling_Click">
                                                                    Click here to take image
                                                                </p>
                                                            </>
                                                        ))}
                                                    {/* clickedPicture */}
                                                    {(formik.values.image || clickedPicture) && (
                                                        <img
                                                            src={clickedPicture || formik.values.image}
                                                            alt="camera-img"
                                                            className="EntryGateMarshalling_Camera_Image"
                                                        />
                                                    )}
                                                </div>
                                                <input
                                                    type="file"
                                                    accept="image/*"
                                                    ref={fileInputRef}
                                                    style={{ display: 'none' }}
                                                    onChange={handleFileInputChange}
                                                />
                                            </div>
                                        </Grid>
                                        <Grid
                                            xl={6}
                                            lg={6}
                                            md={6}
                                            xs={12}
                                            sm={12}
                                            className="EntryGateMarshalling_Form_Two_Container"
                                        >
                                            <div style={{ width: '100%' }}>
                                                <p>Field Selection</p>
                                            </div>
                                            <div className="TextInput_Main">
                                                <label htmlFor="name">No. Of Cows</label>
                                                <Input
                                                    type="number"
                                                    name="numberOfCows"
                                                    value={formik.values.numberOfCows}
                                                    onChange={formik.handleChange}
                                                    className="TextInput"
                                                    error={formik.touched.numberOfCows && formik.errors.numberOfCows}
                                                    placeholder="Type Here"
                                                    onKeyDown={handleNumberKeyDown}
                                                    inputProps={{
                                                        inputMode: 'numeric',
                                                    }}
                                                />
                                                {formik.touched.gender && formik.errors.gender && (
                                                    <div className="error">{formik.errors.gender}</div>
                                                )}
                                            </div>
                                            <div className="TextInput_Main">
                                                <label htmlFor="name">No. Of Camels</label>
                                                <Input
                                                    type="number"
                                                    name="numberOfCamels"
                                                    value={formik.values.numberOfCamels}
                                                    onChange={formik.handleChange}
                                                    className="TextInput"
                                                    error={
                                                        formik.touched.numberOfCamels && formik.errors.numberOfCamels
                                                    }
                                                    placeholder="Type Here"
                                                    onKeyDown={handleNumberKeyDown}
                                                    inputProps={{
                                                        inputMode: 'numeric',
                                                    }}
                                                />
                                                {formik.touched.numberOfCamels && formik.errors.numberOfCamels && (
                                                    <div className="error">{formik.errors.numberOfCamels}</div>
                                                )}
                                            </div>
                                            <div className="TextInput_Main">
                                                <label htmlFor="name">No. Of Goats</label>
                                                <Input
                                                    type="number"
                                                    name="numberOfGoats"
                                                    value={formik.values.numberOfGoats}
                                                    onChange={formik.handleChange}
                                                    className="TextInput"
                                                    error={formik.touched.numberOfGoats && formik.errors.numberOfGoats}
                                                    placeholder="Type Here"
                                                    onKeyDown={handleNumberKeyDown}
                                                    inputProps={{
                                                        inputMode: 'numeric',
                                                    }}
                                                />
                                                {formik.touched.numberOfGoats && formik.errors.numberOfGoats && (
                                                    <div className="error">{formik.errors.numberOfGoats}</div>
                                                )}
                                            </div>
                                            <div className="TextInput_Main">
                                                <label htmlFor="name">Drum</label>
                                                <Input
                                                    type="number"
                                                    name="drum"
                                                    value={formik.values.drum}
                                                    onChange={formik.handleChange}
                                                    className="TextInput"
                                                    error={formik.touched.drum && formik.errors.drum}
                                                    placeholder="Type Here"
                                                    onKeyDown={handleNumberKeyDown}
                                                    inputProps={{
                                                        inputMode: 'numeric',
                                                    }}
                                                />
                                                {formik.touched.drum && formik.errors.drum && (
                                                    <div className="error">{formik.errors.drum}</div>
                                                )}
                                            </div>
                                            <div className="TextInput_Main">
                                                <label htmlFor="name">Vehicle</label>
                                                <Input
                                                    type="text"
                                                    name="vehicle"
                                                    value={
                                                        formik?.values?.vehicles?.length !== 0
                                                            ? formik.values.vehicles
                                                                  .map((item) => item?.tblVehicleCategory?.category)
                                                                  .join(', ')
                                                            : ''
                                                    }
                                                    onChange={formik.handleChange}
                                                    disabled={true}
                                                    className="TextInput"
                                                    // error={formik.touched.vehicles && formik.errors.vehicles}
                                                    placeholder="Type Here"
                                                    onKeyDown={handleNumberKeyDown}
                                                    inputProps={{
                                                        inputMode: 'numeric',
                                                    }}
                                                />
                                                {formik.touched.vehicles && formik.errors.vehicles && (
                                                    <div className="error">{formik.errors.vehicles}</div>
                                                )}
                                            </div>
                                            <div className="TextInput_Main">
                                                <label htmlFor="name">Select Lands</label>
                                                {(!Boolean(formik?.values?.lands.length) ||
                                                    Boolean(formik?.values?.lands.length)) && (
                                                    <>
                                                        <ReuseableSelect
                                                            style={{ width: '100%' }}
                                                            name="state_id"
                                                            formControlClassName="demo-customized-select"
                                                            className={`TextInput select_error`}
                                                            formik={formik}
                                                            value={formik.values.state_id}
                                                            onChange={formik.handleChange}
                                                            error={formik.touched.state_id && formik.errors.state_id}
                                                            optionList={getAllStatesAndLands}
                                                            showOption={'Select lands'}
                                                        />
                                                        {formik.touched.state_id && formik.errors.state_id && (
                                                            <div className="error">{formik.errors.state_id}</div>
                                                        )}
                                                    </>
                                                )}

                                                <MultiLandComponent
                                                    lands={formik.values.lands}
                                                    isLandBooker={true}
                                                    showCloseButton={true}
                                                    onClose={handleCloseSpan}
                                                    showDeleteButton={true}
                                                    onDelete={handleDelete}
                                                />
                                            </div>
                                        </Grid>
                                        <Grid item className="Btn_Container" xl={12} md={12} xs={12} sm={12}>
                                            <div className="Btn_Container_Two">
                                                <ButtonGroup
                                                    value="Update"
                                                    type="submit"
                                                    variant="contained"
                                                    customBtnBGColor="#7904d7"
                                                    textColor="#fff"
                                                    disabled={isLoad}
                                                    isLoad={isLoad}
                                                />
                                            </div>
                                        </Grid>
                                    </Grid>
                                </div>
                            </form>
                        )}
                    </Paper>
                </div>
            )}
            {isCameraOpen && (
                <Camera
                    handleTakePhoto={handleTakePhoto}
                    handleTakePhotoAnimationDone={handleTakePhotoAnimationDone}
                    handleCameraError={handleCameraError}
                    handleCameraStart={handleCameraStart}
                    handleCameraStop={handleCameraStop}
                    dataUri={dataUri}
                    setDataUri={setDataUri}
                    cancelImageCapture={cancelImageCapture}
                    continueImageCapture={continueImageCapture}
                />
            )}
            <LandModal
                open={isOpen}
                setIsOpen={setIsOpen}
                stateLandData={[]}
                id={formik.values.state_id || ''}
                data={getAllStatesAndLands}
                formik={formik}
            />
        </div>
    );
};

export default DataEntry;
