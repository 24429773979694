import React from 'react';
import { FormDialog } from '../UiElements';
import { Table, TableBody, TableCell, TableRow, TableHead, IconButton, DialogTitle } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { FinanceData } from 'dummyData/financeData';
import { viewDetailTableHead } from './helper';
import Loader from '../loader/Loader';
import ImagePreview from '../ImagePreview/ImagePreview';
import styles from './tableStyle-jss';
import { CloseOutlined } from '@material-ui/icons';

const ViewMonthlyRecord = ({ open, handleClose, viewDetail, viewLoading, classes }) => {
    return (
        <FormDialog open={open} handleClose={handleClose} title="View Details">
            <Table>
                <TableHead>
                    <TableRow>
                        {viewDetailTableHead?.map((Ele) => {
                            return <TableCell>{Ele}</TableCell>;
                        })}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {viewLoading ? (
                        <TableRow>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell>
                                <Loader color="#000" item="flex-start" />
                            </TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    ) : !!viewDetail?.length ? (
                        viewDetail?.map((Ele) => {
                            return (
                                <TableRow>
                                    <TableCell>{Ele?.date?.split('T')?.[0]}</TableCell>
                                    <TableCell>{Ele?.deposited_amount}</TableCell>
                                    <TableCell>{Ele?.deposited_by}</TableCell>
                                    <TableCell>
                                        <ImagePreview imageUrl={Ele?.invoice} />
                                    </TableCell>
                                    <TableCell>{Ele?.ref_number}</TableCell>
                                </TableRow>
                            );
                        })
                    ) : (
                        <TableRow>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell>
                                <div className={classes.noRecordContainer}>
                                    <h3 style={{ marginBottom: 0 }}>No Record Exists</h3>
                                </div>
                            </TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    )}
                </TableBody>
                {/* {viewLoading === false && viewDetail?.length === 0 && (
                    <div className={classes.noRecordContainer}>
                        <h3 style={{ marginBottom: 0 }}>No Record Exists</h3>
                    </div>
                )} */}
            </Table>
        </FormDialog>
    );
};

export default withStyles(styles)(ViewMonthlyRecord);
