import React, { useState } from 'react';
import CustomModal from '../Modal';
import { Box, Fade, Grid, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import '../../styles/components/LandModal.scss';

const style = {
    position: 'absolute',
    overflowY: 'scroll',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    height: '50%',
    bgcolor: 'background.paper',
    border: '2px solid #7904d7',
    boxShadow: 24,
    borderRadius: '10px',
    p: 2,
};

const LandModal = ({ open, setIsOpen, id, data, formik }) => {
    const filteredData = data?.filter((item) => item.id === +id);
    const landsData = filteredData?.[0]?.lands || [];

    const handleBoxClick = (land) => {
        const isSelected = formik.values.lands.some((box) => box.id === land.id);
        if (isSelected) {
            const filteredLands = formik.values.lands.filter((box) => box.id !== land.id);

            formik.setFieldValue('lands', filteredLands);
        } else {
            formik.setFieldValue('lands', [...formik.values.lands, land]);
        }
    };

    return (
        <CustomModal open={open} handleClose={() => setIsOpen(false)}>
            <Fade in={open}>
                <Box sx={style} className="land_modal_main">
                    <Box className="land_modal_close">
                        <CloseIcon
                            className="land_modal_close_icon"
                            onClick={() => {
                                setIsOpen(false);
                                formik.setFieldValue('state_id', '');
                            }}
                        />
                    </Box>
                    <Box className="land_modal_content">
                        <h2>{filteredData?.[0]?.name}</h2>
                        <Grid container spacing={2}>
                            {landsData.map((land) => (
                                <Grid key={land.id} item xl={4} lg={4} md={6} sm={12} xs={12}>
                                    <Box
                                        onClick={() => handleBoxClick(land)}
                                        className={`land_modal_content_box ${
                                            formik.values.lands.some((box) => box.id === land.id) ? 'selected' : ''
                                        }`}
                                    >
                                        <Typography>{land.block}</Typography>
                                    </Box>
                                </Grid>
                            ))}
                        </Grid>
                    </Box>
                </Box>
            </Fade>
        </CustomModal>
    );
};

export default LandModal;
