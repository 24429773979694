import React from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { CircularProgress, Fade } from '@material-ui/core';
import CustomModal from '../Modal';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #7904d7',
    boxShadow: 24,
    p: 4,
    borderRadius: '10px',
};

const ConfirmationModal = ({ open, handleClose, yesButtonOnClick, isLoad, labelOne, labelTwo }) => {
    return (
        <div>
            <CustomModal open={open} onClose={handleClose}>
                <Fade in={open}>
                    <Box sx={style}>
                        <Typography id="spring-modal-title" variant="h6" component="h2">
                            {labelOne}
                        </Typography>
                        <Typography id="spring-modal-description" sx={{ mt: 2, fontWeight: 300 }}>
                            {labelTwo}
                        </Typography>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                            <Button variant="contained" color="primary" onClick={yesButtonOnClick} disabled={isLoad}>
                                Yes {isLoad && <CircularProgress color="inherit" size={20} />}
                            </Button>
                            <Box sx={{ marginLeft: 10 }}>
                                <Button variant="contained" color="secondary" onClick={handleClose}>
                                    No
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </Fade>
            </CustomModal>
        </div>
    );
};

export default ConfirmationModal;
