import React, { useEffect, useState } from 'react';
import './styles.scss';
import Heading from 'app/helpers/Heading';
import { Grid, Paper, Divider, Typography, Box } from '@material-ui/core';
import './styles.scss';
import ButtonGroup from 'app/components/ButtonGroup/ButtonGroup';
import { useDispatch, useSelector } from 'react-redux';
import { setAlert } from 'app/redux/actions/alertAction';
import { amount_obj, axiosInstance, errorHandler } from 'app/helpers';
import Loader from 'app/components/loader/Loader';
import { useHistory } from 'react-router-dom';
import ConfirmationModal from 'app/components/ConfirmationModal/ConfirmationModal';

const LandBlookerSlip = () => {
    const [isLoad, setIsLoad] = useState();
    const [data, setData] = useState({});
    const [drumCount, setDrumCount] = useState(0);
    const dispatch = useDispatch();
    const location = window.location.search;
    const params = new URLSearchParams(location);
    const id = params.get('id');
    const history = useHistory();

    const userRole = useSelector((state) => state.getIn(['login', 'data', 'role']));

    const [open, setOpen] = useState(false);
    const [openDeclineModal, setOpenDeclineModal] = useState(false);
    const [isLoadUpdate, setIsLoadUpdate] = useState(false);

    const amount_obj = {
        100: 60000,
        120: 65000,
        150: 70000,
        200: 75000,
        250: 80000,
        300: 85000,
        350: 90000,
        1000: 250000,
        500: 150000,
        1000: 250000,
        500: 150000,
        80: 50000,
    };
    const amount = amount_obj[data?.tblLand?.area];

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleDeclineModalOpen = () => setOpenDeclineModal(true);
    const handleDeclineModalClose = () => setOpenDeclineModal(false);
    const landPrice = data?.lands?.reduce((prev, curr) => {
        if (curr?.status === 'on-hold') {
            return prev + Number(curr.tblLand?.tblstate?.price);
        }
        return prev + 0;
    }, 0);
    // drumCount * 8000;
    const dataOfSlip = [
        {
            id: 1,
            rows: (
                <>
                    <Grid item xl={6} lg={6} md={6} xs={6} sm={6} className="LandBlookerSlip_paddingTop">
                        <p className="LandBlookerSlip_Card_Box_Left_Text">Name</p>
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} xs={6} sm={6} className="LandBlookerSlip_Card_EndItem">
                        <p className="LandBlookerSlip_Card_Box_Right_Text">{data?.name}</p>
                    </Grid>
                </>
            ),
        },
        {
            id: 2,
            rows: (
                <>
                    <Grid item xl={6} lg={6} md={6} xs={6} sm={6} className="LandBlookerSlip_paddingTop">
                        <p className="LandBlookerSlip_Card_Box_Left_Text">Number</p>
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} xs={6} sm={6} className="LandBlookerSlip_Card_EndItem">
                        <p className="LandBlookerSlip_Card_Box_Right_Text">{data?.contact_number}</p>
                    </Grid>
                </>
            ),
        },
        {
            id: 3,
            rows: (
                <>
                    <Grid item xl={6} lg={6} md={6} xs={6} sm={6} className="LandBlookerSlip_paddingTop">
                        <p className="LandBlookerSlip_Card_Box_Left_Text">N.I.C</p>
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} xs={6} sm={6} className="LandBlookerSlip_Card_EndItem">
                        <p className="LandBlookerSlip_Card_Box_Right_Text">{data?.cnic}</p>
                    </Grid>
                </>
            ),
        },
        {
            id: 6,
            rows: (
                <>
                    <Grid item xl={6} lg={6} md={6} xs={6} sm={6} className="LandBlookerSlip_paddingTop">
                        <p className="LandBlookerSlip_Card_Box_Left_Text">Block(s)</p>
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} xs={6} sm={6} className="LandBlookerSlip_Card_EndItem">
                        <p className="LandBlookerSlip_Card_Box_Right_Text">
                            {data?.lands ? data?.lands.map((item) => item?.tblLand?.block).join(', ') : ''}
                        </p>
                    </Grid>
                </>
            ),
        },
        {
            id: 9,
            rows: (
                <>
                    <Divider style={{ width: '100%', marginTop: '10px' }} />
                </>
            ),
        },
        {
            id: 10,
            rows: (
                <>
                    <Grid item xl={6} lg={6} md={6} xs={6} sm={6} className="LandBlookerSlip_paddingTop_max">
                        <p className="LandBlookerSlip_Card_Box_Left_Text">Total</p>
                    </Grid>
                    <Grid
                        item
                        xl={6}
                        lg={6}
                        md={6}
                        xs={6}
                        sm={6}
                        className="LandBlookerSlip_Card_EndItem LandBlookerSlip_paddingTop_max"
                    >
                        <p className="LandBlookerSlip_Card_Box_Right_Text">{landPrice}</p>
                    </Grid>
                </>
            ),
        },
    ];

    const getSlipData = async () => {
        try {
            setIsLoad(true);
            const { data } = await axiosInstance.get(`/cattleMandi/get-land-booker?id=${id}`);
            setData(data?.data);
        } catch (error) {
            dispatch(
                setAlert({
                    isOpen: true,
                    type: 'error',
                    message: errorHandler(error),
                })
            );
            setTimeout(() => {
                dispatch(setAlert({}));
            }, 5000);
        } finally {
            setIsLoad(false);
        }
    };

    const updatePaymentSlipHandler = async () => {
        try {
            setIsLoadUpdate(true);
            const { data } = await axiosInstance.put(`/cattleMandi/update-land-booker-status?id=${id}`);
            dispatch(
                setAlert({
                    isOpen: true,
                    type: 'success',
                    message: data?.data.message,
                })
            );
            setTimeout(() => {
                dispatch(setAlert({}));
            }, 1000);
        } catch (error) {
            dispatch(
                setAlert({
                    isOpen: true,
                    type: 'error',
                    message: errorHandler(error),
                })
            );
            setTimeout(() => {
                dispatch(setAlert({}));
            }, 1000);
        } finally {
            setIsLoadUpdate(false);
            handleClose();
            getSlipData();
        }
    };

    useEffect(() => {
        getSlipData();
    }, [location]);
    useEffect(() => {
        const allCount = (data?.noOfCamels || 0) + (data?.noOfCows || 0) + (data?.noOfGoats || 0);
        const divide = allCount / 10;
        const decimal = Math.floor(divide);
        if (divide > decimal) {
            setDrumCount(decimal + 1);
        } else {
            setDrumCount(decimal);
        }
    }, [data]);
    const hideButton = userRole === 'cattleAdmin' || 'automation';

    return (
        <div className="LandBlookerSlip_Container">
            <Heading heading="Land Allotment Payment" />

            {isLoad ? (
                <Loader color="#000" height="65vh" />
            ) : (
                <div className="LandBlookerSlip_Main_Container">
                    {data && (
                        <Paper className="LandBlookerSlip_Card">
                            <div className="LandBlookerSlip_Card_Content">
                                <div>
                                    <p className="Slip_Heading">Slip</p>
                                </div>

                                <div className="LandBlookerSlip_Grid_Container">
                                    <Grid container>
                                        {dataOfSlip.map((item, index) => {
                                            return item.rows;
                                        })}
                                    </Grid>
                                </div>
                            </div>

                            <div className="LandBlookerSlip_Status">
                                <p>
                                    Payment Status:{' '}
                                    <span style={{ color: data?.paymentStatus === 'unpaid' ? 'red' : 'green' }}>
                                        {' '}
                                        {data && data?.paymentStatus}{' '}
                                    </span>
                                </p>
                            </div>

                            {data?.paymentStatus === 'unpaid' && (
                                <Grid container className="LandBlookerSlip_Card_EndItem_Button">
                                    <Grid xl={5} lg={5} md={5} xs={12} sm={12}>
                                        <ButtonGroup
                                            value={'Verify'}
                                            type="submit"
                                            variant="contained"
                                            customBtnBGColor="#7904d7"
                                            textColor="#fff"
                                            onClick={() => {
                                                handleOpen();
                                            }}
                                        />
                                    </Grid>
                                    <Grid
                                        xl={5}
                                        lg={5}
                                        md={5}
                                        xs={12}
                                        sm={12}
                                        className="LandBlookerSlip_Card_EndItem_Button_Two"
                                    >
                                        <ButtonGroup
                                            value={'Decline'}
                                            type="submit"
                                            variant="contained"
                                            customBtnBGColor="#FF5259"
                                            textColor="#fff"
                                            onClick={() => {
                                                handleDeclineModalOpen();
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            )}

                            <ConfirmationModal
                                open={open}
                                handleOpen={handleOpen}
                                handleClose={handleClose}
                                yesButtonOnClick={updatePaymentSlipHandler}
                                isLoad={isLoadUpdate}
                                labelOne="Confirm Payslip Verification"
                                labelTwo="Are you sure you want to mark this payslip as paid?"
                            />

                            <ConfirmationModal
                                open={openDeclineModal}
                                handleOpen={handleDeclineModalOpen}
                                handleClose={handleDeclineModalClose}
                                yesButtonOnClick={() => {
                                    history.push('/scan-receipt');
                                }}
                                // isLoad={isLoadUpdate}
                                labelOne="Decline Payslip Verification"
                                labelTwo="Are you sure you want to decline this payslip verification?"
                            />

                            {data?.paymentStatus === 'paid' && !hideButton && (
                                <Grid container className="LandBlookerSlip_Card_Generate_Button">
                                    <Grid xl={5} lg={5} md={5} xs={12} sm={12}>
                                        <ButtonGroup
                                            value="Scan Other Receipt"
                                            type="submit"
                                            variant="contained"
                                            customBtnBGColor="#7904d7"
                                            textColor="#fff"
                                            onClick={() => {
                                                history.push('/scan-receipt');
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            )}

                            {data?.paymentStatus === 'paid' && hideButton && (
                                <Grid container className="LandBlookerSlip_Card_EndItem_Button">
                                    <Grid
                                        xl={5}
                                        lg={5}
                                        md={5}
                                        xs={12}
                                        sm={12}
                                        className="LandBlookerSlip_Card_EndItem_Button_Two"
                                    >
                                        <ButtonGroup
                                            value={'Scan Other Receipt'}
                                            type="submit"
                                            variant="contained"
                                            customBtnBGColor="#FF5259"
                                            textColor="#fff"
                                            onClick={() => {
                                                history.push('/scan-receipt');
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            )}
                        </Paper>
                    )}

                    {!data && (
                        <Paper className="LandBlookerSlip_Card">
                            <div className="LandBlookerSlip_Card_Content">
                                <div>
                                    <p className="Slip_Heading">Slip</p>
                                </div>

                                <div className="LandBlookerSlip_NotFound_Container">
                                    <Typography variant="h4">No Entry Found</Typography>

                                    <Box sx={{ mt: 1 }}>
                                        <ButtonGroup
                                            value="Scan Other Receipt"
                                            type="submit"
                                            variant="contained"
                                            customBtnBGColor="#7904d7"
                                            textColor="#fff"
                                            onClick={() => {
                                                history.push('/scan-receipt');
                                            }}
                                        />
                                    </Box>
                                </div>
                            </div>
                        </Paper>
                    )}
                </div>
            )}
        </div>
    );
};

export default LandBlookerSlip;
