/* eslint-disable no-undef */
import React from "react";
import { compose, lifecycle } from "recompose";
import { withScriptjs, withGoogleMap, GoogleMap, DirectionsRenderer } from "react-google-maps";

const MapWithADirectionsRenderer = compose(
  withScriptjs,
  withGoogleMap,
  lifecycle({
    componentDidMount() {
      const DirectionsService = new window.google.maps.DirectionsService();

      DirectionsService.route(
        {
          origin: new window.google.maps.LatLng(41.85073, -87.65126),
          destination: new window.google.maps.LatLng(41.85258, -87.65141),
          travelMode: google.maps.TravelMode.DRIVING,
        },
        (result, status) => {
          if (status === google.maps.DirectionsStatus.OK) {
            this.setState({
              directions: result,
            });
          } else {
            console.error(`error fetching directions ${result}`);
          }
        }
      );
    },
  })
)((props) => (
  <GoogleMap defaultZoom={8} defaultCenter={new window.google.maps.LatLng(41.85073, -87.65126)}>
    {props.directions && <DirectionsRenderer directions={props.directions} />}
  </GoogleMap>
));

class Direction extends React.Component {
  render() {
    return (
      <MapWithADirectionsRenderer
        googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places"
        loadingElement={<div style={{ height: "100%" }} />}
        containerElement={<div style={{ height: "400px" }} />}
        mapElement={<div style={{ height: "100%" }} />}
      />
    );
  }
}

export default Direction;
