import React, { useEffect, useState } from 'react';
import Heading from 'app/helpers/Heading';
import './style.scss';
import { axiosInstance, errorHandler } from 'app/helpers';
import CattleMandiTable from 'app/components/Tables/CattleMandiTable';
import { cattleMandiBankTableHead } from 'app/components/Tables/CattleMandiTable/helper';
import { useDispatch } from 'react-redux';
import { setAlert } from 'app/redux/actions/alertAction';
import Loader from 'app/components/loader/Loader';
import { TableCell } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { reversedAndFormatedDate } from 'app/helpers/convertDate';

const BankRecord = () => {
    const [automationData, setAutomationData] = useState([]);
    const [landBookerData, setLandBookerData] = useState([]);
    const [searchData, setSearchData] = useState([]);
    const [searchAutomationData, setSearchAutomationData] = useState([]);
    const [isLoad, setIsLoad] = useState(false);
    const [isLoadLandBooker, setIsLoadLandBooker] = useState(false);
    const [dataCount, setDataCount] = useState(0);
    const [landBookerDataCount, setLandBookerDataCount] = useState(0);
    const dispatch = useDispatch();
    const [getId, setGetid] = useState('');
    const [getAutomationId, setGetAutomationid] = useState('');

    // unpaid automation states
    const [unpaidAutomationData, setUnpaidAutomationData] = useState([]);
    const [isLoadUnpaid, setIsLoadUnpaid] = useState(false);
    const [searchDataUnpaid, setSearchDataUnpaid] = useState([]);
    const [unpaidGetId, setUnpaidGetid] = useState('');
    const [unpaidDataCount, setUnpaidDataCount] = useState(0);
    //   --- end

    // unpaid land booker states
    const [unpaidMarshallingDataLandBooker, setUnpaidMarshallingDataLandBooker] = useState([]);
    const [unpaidSearchDataLandBooker, setUnpaidSearchDataLandBooker] = useState([]);
    const [isLoadUnpaidLandBooker, setIsLoadUnpaidLandBooker] = useState(false);
    const [unpaidDataCountLandBooker, setUnpaidDataCountLandBooker] = useState(0);
    const [unpaidGetIdLandBooker, setUnpaidGetidLandBooker] = useState('');
    //   --- end

    const userData = useSelector((state) => state.getIn(['login', 'data']));
    const userRole = userData.get('role');

    const getAllAutomation = async (offset) => {
        if (offset === undefined) setIsLoad(true);
        try {
            const { data } = await axiosInstance.get('/cattleMandi/get-all-automation', {
                params: { offset: offset, filter: 'paid' },
            });
            const resData = data?.data;
            if (resData !== undefined) setAutomationData(data?.data.data);
            setDataCount(data?.data?.count);
            return resData?.data;
        } catch (error) {
            dispatch(
                setAlert({
                    isOpen: true,
                    type: 'error',
                    message: errorHandler(error),
                })
            );
            setTimeout(() => {
                dispatch(setAlert({}));
            }, 5000);
        } finally {
            setIsLoad(false);
        }
    };

    const getLandBookers = async (offset) => {
        if (offset === undefined) setIsLoadLandBooker(true);
        try {
            const { data } = await axiosInstance.get('/cattleMandi/get-land-bookers', {
                params: { offset: offset, filter: 'paid' },
            });
            const resData = data?.data;
            console.log({ resData });
            if (resData !== undefined) {
                setLandBookerData(resData.data);
            }
            setLandBookerDataCount(resData.count);
            return resData?.data;
        } catch (error) {
            dispatch(
                setAlert({
                    isOpen: true,
                    type: 'error',
                    message: errorHandler(error),
                })
            );
            setTimeout(() => {
                dispatch(setAlert({}));
            }, 5000);
        } finally {
            setIsLoadLandBooker(false);
        }
    };

    const allGetAPIs = () => {
        getAllAutomation();
        getLandBookers();
    };

    const customerSearch = async () => {
        if (getId) {
            try {
                setIsLoadLandBooker(true);
                const { data } = await axiosInstance.get(`/cattleMandi/search-land-booker`, {
                    params: {
                        query: getId.trim(),
                        filter: 'paid',
                    },
                });
                console.log('121211',getId)
                if (data?.data) {
                    setSearchData([data?.data]);
                    setLandBookerDataCount(data?.data?.length);
                } else {
                    setSearchData([]);
                    dispatch(
                        setAlert({
                            isOpen: true,
                            type: 'error',
                            message: 'ddd',
                        })
                    );
                    setTimeout(() => {
                        dispatch(setAlert({}));
                    }, 5000);
                }
            } catch (error) {
                setGetid('');
                dispatch(
                    setAlert({
                        isOpen: true,
                        type: 'error',
                        message: errorHandler(error?.response?.data?.error),
                    })
                );
                setTimeout(() => {
                    dispatch(setAlert({}));
                }, 5000);
                setSearchData([]);
                getLandBookers();
            } finally {
                setIsLoadLandBooker(false);
            }
        }
    };
    
    const clearSearch = () => {
        setSearchData([]);
        getLandBookers();
        setGetid('');
    };

    const automationCustomerSearch = async () => {
        if (getAutomationId) {
            try {
                setIsLoad(true);
                const { data } = await axiosInstance.get(`/cattleMandi/search-automation`, {
                    params: {
                        query: getAutomationId.trim(),
                        filter: 'paid',
                    },
                });

                if (data?.data) {
                    setSearchAutomationData([data?.data]);
                    setDataCount(data?.data?.length);
                } else {
                    setGetAutomationid('');
                    dispatch(
                        setAlert({
                            isOpen: true,
                            type: 'error',
                            message: 'Record not exists',
                        })
                    );
                    setTimeout(() => {
                        dispatch(setAlert({}));
                    }, 5000);
                    setSearchAutomationData([]);
                    getAllAutomation();
                }
            } catch (error) {
                setSearchAutomationData([]);
                dispatch(
                    setAlert({
                        isOpen: true,
                        type: 'error',
                        message: errorHandler(error),
                    })
                );
                setTimeout(() => {
                    dispatch(setAlert({}));
                }, 5000);
            } finally {
                setIsLoad(false);
            }
        }
    };

    const automationClearSearch = () => {
        setSearchAutomationData([]);
        getAllAutomation();
        setGetAutomationid('');
    };

    // unpaid automation functions
    const getAllUnpaidAutomation = async (offset) => {
        if (offset === undefined) setIsLoadUnpaid(true);
        try {
            const { data } = await axiosInstance.get('/cattleMandi/get-all-automation', {
                params: { offset: offset || 0, filter: 'unpaid' },
            });
            const resData = data?.data;
            if (resData !== undefined) setUnpaidAutomationData(data?.data.data);
            setUnpaidDataCount(data?.data?.count);
            return resData?.data;
        } catch (error) {
            dispatch(
                setAlert({
                    isOpen: true,
                    type: 'error',
                    message: errorHandler(error),
                })
            );
            setTimeout(() => {
                dispatch(setAlert({}));
            }, 5000);
        } finally {
            setIsLoadUnpaid(false);
        }
    };

    const unpaidCustomerSearch = async () => {
        if (unpaidGetId) {
            try {
                setIsLoadUnpaid(true);
                const { data } = await axiosInstance.get(`/cattleMandi/search-automation`, {
                    params: {
                        query: unpaidGetId.trim(),
                        filter: 'unpaid',
                    },
                });

                if (data?.data) {
                    setSearchDataUnpaid([data?.data]);
                    setUnpaidDataCount(data?.data?.length);
                } else {
                    setUnpaidGetid('');
                    dispatch(
                        setAlert({
                            isOpen: true,
                            type: 'error',
                            message: 'Record not exists',
                        })
                    );
                    setTimeout(() => {
                        dispatch(setAlert({}));
                    }, 5000);
                    setSearchDataUnpaid([]);
                    getAllUnpaidAutomation();
                }
            } catch (error) {
                setSearchDataUnpaid([]);
                dispatch(
                    setAlert({
                        isOpen: true,
                        type: 'error',
                        message: errorHandler(error),
                    })
                );
                setTimeout(() => {
                    dispatch(setAlert({}));
                }, 5000);
            } finally {
                setIsLoadUnpaid(false);
            }
        }
    };

    const unpaidClearSearch = () => {
        setSearchDataUnpaid([]);
        getAllUnpaidAutomation();
        setUnpaidGetid('');
    };
    // -- end

    // unpaid land booker functions
    const getAllLandBookerUnpaid = async (offset) => {
        if (offset === undefined) setIsLoadUnpaidLandBooker(true);
        try {
            const { data } = await axiosInstance.get('/cattleMandi/get-land-bookers', {
                params: { offset: offset, filter: 'unpaid' },
            });
            const resData = data?.data;
            console.log({ resData });
            if (resData !== undefined) {
                setUnpaidMarshallingDataLandBooker(resData.data);
            }
            setUnpaidDataCountLandBooker(resData.count);
            return resData?.data;
        } catch (error) {
            dispatch(
                setAlert({
                    isOpen: true,
                    type: 'error',
                    message: errorHandler(error),
                })
            );
            setTimeout(() => {
                dispatch(setAlert({}));
            }, 5000);
        } finally {
            setIsLoadUnpaidLandBooker(false);
        }
    };

    const customerSearchUnpaid = async () => {
        if (unpaidGetIdLandBooker) {
            try {
                setIsLoadUnpaidLandBooker(true);
                const { data } = await axiosInstance.get(`/cattleMandi/search-land-booker`, {
                    params: {
                        query: unpaidGetIdLandBooker.trim(),
                        filter: 'unpaid',
                    },
                });

                setUnpaidSearchDataLandBooker([data?.data]);
                setUnpaidDataCountLandBooker(data?.data?.length);
            } catch (error) {
                setUnpaidGetidLandBooker('');
                dispatch(
                    setAlert({
                        isOpen: true,
                        type: 'error',
                        message: 'Record not exists',
                    })
                );
                setTimeout(() => {
                    dispatch(setAlert({}));
                }, 5000);
                setUnpaidSearchDataLandBooker([]);
                getAllLandBookerUnpaid();
            } finally {
                setIsLoadUnpaidLandBooker(false);
            }
        }
    };

    const clearSearchUnpaid = () => {
        setUnpaidSearchDataLandBooker([]);
        getAllLandBookerUnpaid();
        setUnpaidGetid('');
        setUnpaidGetidLandBooker('');
    };
    // -- end --

    const renderAutomationTableCell = (value) => {
        const renderCell = (content, defaultValue = '--') => {
            const cellColor = content === 'paid' ? 'green' : content === 'unpaid' && 'red';
            return <TableCell style={{ color: cellColor }}>{content ? content : ''}</TableCell>;
        };

        return (
            <>
                {renderCell(value.id)}
                {renderCell(value.name)}
                {renderCell(value.contactNo || value.contact_number)}
                {renderCell(value.cnic)}
                {renderCell(reversedAndFormatedDate(value?.createdAt))}
                {renderCell(value.paymentStatus)}
            </>
        );
    };

    useEffect(() => {
        allGetAPIs();
        getAllUnpaidAutomation();
        setSearchData([]);
        setSearchDataUnpaid([]);
        getAllLandBookerUnpaid();
        setUnpaidSearchDataLandBooker([]);
    }, []);

    const dataRes = !!searchData?.length ? searchData : landBookerData;
    const automationDataRes = !!searchAutomationData?.length ? searchAutomationData : automationData;
    const unpaidAutomationDataRes = !!searchDataUnpaid?.length ? searchDataUnpaid : unpaidAutomationData;
    const paidLandBookerDataRes = !!unpaidSearchDataLandBooker?.length
        ? unpaidSearchDataLandBooker
        : unpaidMarshallingDataLandBooker;

    return (
        <div className="CattleMandiTables_Container">
            <Heading heading="Bank Records" />

            <div className="CattleMandiTables_content">
                {isLoad ? (
                    <Loader color="#000" height="65vh" />
                ) : (
                    <>
                        {!!automationData?.length || !!searchAutomationData?.length ? (
                            <div className="CattleMandiTables_Item">
                                <CattleMandiTable
                                    dataCount={dataCount}
                                    page={1}
                                    rowsPerPage={5}
                                    defaultPerPage={5}
                                    data={automationDataRes}
                                    checkBox
                                    // mtCell
                                    fetchDDSDetails={getAllAutomation}
                                    CheckBox
                                    CheckBoxShow
                                    name="Paid Marshalling Records Table"
                                    cattleMandiTableHead={cattleMandiBankTableHead}
                                    isLoad={isLoad}
                                    renderCell={renderAutomationTableCell}
                                    showAutmationButton={true}
                                    showCollapse={true}
                                    showSearch={true}
                                    getId={getAutomationId}
                                    setGetid={setGetAutomationid}
                                    customerSearch={automationCustomerSearch}
                                    clearSearch={automationClearSearch}
                                    showClearIcon={!!searchAutomationData?.length}
                                />
                            </div>
                        ) : (
                            <></>
                        )}
                    </>
                )}

                {isLoadUnpaid ? (
                    <Loader color="#000" height="65vh" />
                ) : (
                    <>
                        {!!automationData?.length || !!searchAutomationData?.length ? (
                            <div className="CattleMandiTables_Item">
                                <CattleMandiTable
                                    dataCount={unpaidDataCount}
                                    page={1}
                                    rowsPerPage={5}
                                    defaultPerPage={5}
                                    data={unpaidAutomationDataRes}
                                    checkBox
                                    // mtCell
                                    fetchDDSDetails={getAllUnpaidAutomation}
                                    CheckBox
                                    CheckBoxShow
                                    name="Unpaid Marshalling Records Table"
                                    cattleMandiTableHead={cattleMandiBankTableHead}
                                    isLoad={isLoadUnpaid}
                                    renderCell={renderAutomationTableCell}
                                    showAutmationButton={true}
                                    showCollapse={true}
                                    showSearch={true}
                                    getId={unpaidGetId}
                                    setGetid={setUnpaidGetid}
                                    customerSearch={unpaidCustomerSearch}
                                    clearSearch={unpaidClearSearch}
                                    showClearIcon={!!searchDataUnpaid?.length}
                                />
                            </div>
                        ) : (
                            <></>
                        )}
                    </>
                )}

                {isLoadLandBooker ? (
                    <Loader color="#000" height="65vh" />
                ) : (
                    <>
                        {!!landBookerData?.length || !!searchData?.length ? (
                            <div className="CattleMandiTables_Item">
                                <CattleMandiTable
                                    page={1}
                                    rowsPerPage={5}
                                    defaultPerPage={5}
                                    data={dataRes}
                                    checkBox
                                    // mtCell
                                    dataCount={landBookerDataCount}
                                    CheckBox
                                    CheckBoxShow
                                    name="Paid Land Booker Record Table"
                                    cattleMandiTableHead={cattleMandiBankTableHead}
                                    isLoad={isLoadLandBooker}
                                    showLandBookerViewButton={true}
                                    renderCell={renderAutomationTableCell}
                                    actionCell={false}
                                    showSearch={true}
                                    getId={getId}
                                    fetchDDSDetails={getLandBookers}
                                    setGetid={setGetid}
                                    customerSearch={customerSearch}
                                    clearSearch={clearSearch}
                                    showClearIcon={!!searchData?.length}
                                    showCollapse={true}
                                />
                            </div>
                        ) : null}
                    </>
                )}

                {isLoadUnpaidLandBooker ? (
                    <Loader color="#000" height="65vh" />
                ) : (
                    <>
                        {!!unpaidMarshallingDataLandBooker?.length || !!unpaidSearchDataLandBooker?.length ? (
                            <div className="CattleMandiTables_Item">
                                <CattleMandiTable
                                    page={1}
                                    rowsPerPage={5}
                                    defaultPerPage={5}
                                    data={paidLandBookerDataRes}
                                    checkBox
                                    // mtCell
                                    dataCount={unpaidDataCountLandBooker}
                                    CheckBox
                                    CheckBoxShow
                                    name="Unpaid Land Booker Record Table"
                                    cattleMandiTableHead={cattleMandiBankTableHead}
                                    isLoad={isLoadUnpaidLandBooker}
                                    showLandBookerViewButton={true}
                                    renderCell={renderAutomationTableCell}
                                    actionCell={false}
                                    showSearch={true}
                                    getId={unpaidGetIdLandBooker}
                                    fetchDDSDetails={getAllLandBookerUnpaid}
                                    setGetid={setUnpaidGetidLandBooker}
                                    customerSearch={customerSearchUnpaid}
                                    clearSearch={clearSearchUnpaid}
                                    showClearIcon={!!unpaidSearchDataLandBooker?.length}
                                    showCollapse={true}
                                />
                            </div>
                        ) : null}
                    </>
                )}
            </div>
        </div>
    );
};

export default BankRecord;
