import { createStore, applyMiddleware, compose } from 'redux';
import createReducer from './reducer';
import thunk from 'redux-thunk';
import { fromJS, List, Map } from 'immutable';

const composeEnhancers =
    (global.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ && global.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})) || compose;

const reducer = createReducer();

const saveToLocalStorage = (state) => {
    try {
        const serilazedState = JSON.stringify(state);
        window.localStorage.setItem('state', serilazedState);
    } catch (error) {
        console.log(error);
    }
};

const loadFromLocalStorage = () => {
    try {
        const serilazedState = JSON.parse(window.localStorage.getItem('state'));
        if (serilazedState === null) return undefined;
        return serilazedState;
    } catch (error) {
        console.log(error);
        return undefined;
    }
};

const presistedState = fromJS(loadFromLocalStorage());

export const store = createStore(reducer, presistedState, composeEnhancers(applyMiddleware(thunk)));

store.subscribe(() => saveToLocalStorage(store.getState()));
