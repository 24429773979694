import React, { useEffect, useState } from 'react';
import './style.scss';
import Heading from 'app/helpers/Heading';
import { Grid } from '@material-ui/core';
import ReuseableSelect from 'app/components/Select/Select';
import Input from 'app/components/Input/Input';
import ButtonGroup from 'app/components/ButtonGroup/ButtonGroup';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { axiosInstance, errorHandler } from 'app/helpers';
import { setAlert } from 'app/redux/actions/alertAction';
import { useDispatch } from 'react-redux';
import LandModal from 'app/components/LandModal/LandModal';
import { MultiLandComponent } from 'app/components';
import { useHistory, useParams } from 'react-router-dom';

const validationSchema = Yup.object().shape({
    name: Yup.string().required('required'),
    NicNo: Yup.string().required('required'),
    contactNo: Yup.string().required('required'),
});

const LandBooker = () => {
    const { id } = useParams();
    const [isLoad, setIsLoad] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [getAllStatesAndLands, setGetAllStatesAndLands] = useState([]);
    const dispatch = useDispatch();

    const formik = useFormik({
        initialValues: {
            name: '',
            NicNo: '',
            contactNo: '',
            state_id: '',
            lands: [],
            deleteLand: [],
            land_ids: [],
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            setIsLoad(true);

            const filteredArray = values.lands.filter((obj) => !values.land_ids.includes(obj.land_id));
            const Lands = filteredArray?.map((Ele) => {
                return {
                    land_id: Ele?.id,
                    land_booker_id: id,
                };
            });
            try {
                await axiosInstance.put(`/cattleMandi/update-land-booker?id=${id}`, {
                    name: values.name,
                    cnic: values.NicNo,
                    contact_number: values.contactNo,
                    addedLands: Lands,
                    deletedLands: values.deleteLand,
                });
                setIsLoad(false);
                dispatch(
                    setAlert({
                        isOpen: true,
                        type: 'success',
                        message: 'Land record update',
                    })
                );
                setTimeout(() => {
                    dispatch(setAlert({}));
                }, 5000);
            } catch (error) {
                console.log({ error });
                dispatch(
                    setAlert({
                        isOpen: true,
                        type: 'error',
                        message: errorHandler(error),
                    })
                );
                setTimeout(() => {
                    dispatch(setAlert({}));
                }, 5000);
                setIsLoad(false);
                // API error
            } finally {
                setIsLoad(false);
            }
        },
    });

    const handleNumberKeyDown = (e) => {
        if (e.key === 'e' || e.key === '-') {
            e.preventDefault();
        }
    };

    const handleClose = (land) => {
        const landsSelected = formik.values.lands;
        const filteredLands = landsSelected.filter((item) => {
            return item?.id !== land?.id;
        });
        formik.setFieldValue('lands', filteredLands);
    };

    const handleDelete = (land) => {
        const landsSelected = formik.values.lands;
        const filteredLands = landsSelected.filter((item) => {
            return item?.id !== land?.id;
        });
        formik.setFieldValue('lands', filteredLands);
        const updatedDeleteLand = [...formik.values.deleteLand, land?.id];
        formik.setFieldValue('deleteLand', updatedDeleteLand);
    };

    const getStatesLands = async () => {
        try {
            const { data } = await axiosInstance.get('/cattleMandi/get-state-lands');
            setGetAllStatesAndLands(data?.data?.filter((Ele) => Ele.price !== 0));
        } catch (error) {
            dispatch(
                setAlert({
                    isOpen: true,
                    type: 'error',
                    message: errorHandler(error),
                })
            );
            setTimeout(() => {
                dispatch(setAlert({}));
            }, 5000);
        }
    };

    useEffect(() => {
        (async () => {
            try {
                const { data } = await axiosInstance.get('/cattleMandi/get-land-booker', { params: { id: id } });
                formik.setFieldValue('name', data?.data?.name);
                formik.setFieldValue('NicNo', data?.data?.cnic);
                formik.setFieldValue('contactNo', data?.data?.contact_number);
                formik.setFieldValue('lands', data?.data?.lands);
                const ids = data?.data?.lands.map((ele) => ele?.land_id);
                formik.setFieldValue('land_ids', ids);
            } catch (error) {
                dispatch(
                    setAlert({
                        isOpen: true,
                        type: 'error',
                        message: errorHandler(error),
                    })
                );
            }
        })();
    }, []);

    useEffect(() => {
        getStatesLands();
    }, []);

    useEffect(() => {
        if (!!formik.values.state_id && formik.values.state_id !== 'Select lands') {
            setIsOpen(true);
        }
    }, [formik.values.state_id]);

    return (
        <div className="LandBooker_Container">
            <Heading heading="Land Booker" />
            <div className="LandBooker_Content">
                <div className="LandBooker_Card">
                    <form
                        onSubmit={(e) => {
                            e.preventDefault();
                            formik.submitForm();
                        }}
                    >
                        <div>
                            <Grid container spacing={3}>
                                <Grid item md={6} xs={12} sm={12} className="TextInput_Main">
                                    <label>Name</label>
                                    <Input
                                        type="text"
                                        name="name"
                                        value={formik.values.name}
                                        onChange={formik.handleChange}
                                        className="TextInput"
                                        error={formik.touched.name && formik.errors.name}
                                        onKeyDown={handleNumberKeyDown}
                                        placeholder="Type Here"
                                    />
                                    {formik.touched.name && formik.errors.name && (
                                        <div className="error">{formik.errors.name}</div>
                                    )}
                                </Grid>

                                <Grid item md={6} xs={12} sm={12} className="TextInput_Main">
                                    <label>Contact No</label>
                                    <Input
                                        type="text"
                                        name="contactNo"
                                        onKeyDown={handleNumberKeyDown}
                                        value={formik.values.contactNo}
                                        onChange={formik.handleChange}
                                        className="TextInput"
                                        error={formik.touched.contactNo && formik.errors.contactNo}
                                        placeholder="Type Here"
                                    />
                                    {formik.touched.contactNo && formik.errors.contactNo && (
                                        <div className="error">{formik.errors.contactNo}</div>
                                    )}
                                </Grid>

                                <Grid item md={6} xs={12} sm={12} className="TextInput_Main">
                                    <label>N.I.C No</label>
                                    <Input
                                        type="text"
                                        name="NicNo"
                                        value={formik.values.NicNo}
                                        onChange={formik.handleChange}
                                        className="TextInput"
                                        onKeyDown={handleNumberKeyDown}
                                        error={formik.touched.NicNo && formik.errors.NicNo}
                                        placeholder="Type Here"
                                    />
                                    {formik.touched.NicNo && formik.errors.NicNo && (
                                        <div className="error">{formik.errors.NicNo}</div>
                                    )}
                                </Grid>

                                <Grid item md={6} xs={12} sm={12} className="TextInput_Main">
                                    <div className="TextInput_Main">
                                        <label htmlFor="name">Select Lands</label>

                                        <>
                                            <ReuseableSelect
                                                style={{ width: '100%' }}
                                                name="state_id"
                                                formControlClassName="demo-customized-select"
                                                className={`TextInput select_error`}
                                                formik={formik}
                                                value={formik.values.state_id}
                                                onChange={formik.handleChange}
                                                error={formik.touched.state_id && formik.errors.state_id}
                                                optionList={getAllStatesAndLands}
                                                showOption={'Select lands'}
                                            />
                                            {formik.touched.state_id && formik.errors.state_id && (
                                                <div className="error">{formik.errors.state_id}</div>
                                            )}
                                        </>
                                        {Boolean(formik?.values?.lands.length) && (
                                            <MultiLandComponent
                                                lands={formik.values.lands}
                                                showCloseButton={true}
                                                showDeleteButton={true}
                                                onClose={handleClose}
                                                onDelete={handleDelete}
                                            />
                                        )}
                                    </div>
                                </Grid>

                                <Grid item md={12} xs={12} sm={12} className="TextInput_Main">
                                    <div className="Btn_Container_Two">
                                        <ButtonGroup
                                            value="update"
                                            type="submit"
                                            variant="contained"
                                            customBtnBGColor="#7904d7"
                                            textColor="#fff"
                                            disabled={isLoad}
                                            isLoad={isLoad}
                                        />
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    </form>
                </div>
            </div>
            <LandModal
                open={isOpen}
                setIsOpen={setIsOpen}
                stateLandData={[]}
                id={formik.values.state_id || ''}
                data={getAllStatesAndLands}
                formik={formik}
                // getLand={getLand}
            />
        </div>
    );
};

export default LandBooker;
