import React from 'react';
import { InputBase, MenuItem, Select, withStyles } from '@material-ui/core';
import 'app/styles/Tables.scss';
import { Pagination } from '@material-ui/lab';

const BootstrapInput = withStyles((theme) => ({
    root: {
        'label + &': {
            marginTop: theme.spacing(3),
        },
    },
    input: {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #ced4da',
        fontSize: 14,
        padding: '6px 10px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
            borderRadius: 4,
            borderColor: '#80bdff',
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
    },
}))(InputBase);

const TablePagination = ({
    getPaginationInfo,
    data,
    rowsPerPageState,
    handleChangeRowsPerPage,
    handleChangePage,
    pageState,
}) => {
    return (
        <div className="pagination_container">
            <span>{getPaginationInfo()}</span>
            <div className="pagination">
                <Pagination
                    count={Math.ceil(data?.length / rowsPerPageState)}
                    page={pageState}
                    onChange={handleChangePage}
                    variant="outlined"
                    shape="rounded"
                    color="primary"
                />

                <div className="pagination_dropdown">
                    <Select
                        labelId="demo-customized-select-label"
                        id="demo-customized-select"
                        value={rowsPerPageState}
                        onChange={handleChangeRowsPerPage}
                        input={<BootstrapInput />}
                    >
                        <MenuItem value={5}>5</MenuItem>
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={20}>20</MenuItem>
                        <MenuItem value={50}>50</MenuItem>
                    </Select>
                </div>
            </div>
        </div>
    );
};

export default TablePagination;
