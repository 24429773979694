import React from 'react';
import { TableCell, Button } from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom';
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import ReceiptLongIcon from '@material-ui/icons/Receipt';

export const Actions = ({
    linkPathname,
    handleClickOpen,
    index,
    showDetails,
    value,
    linkSerachParams,
    editRecord,
    financeAction,
    handleViewLocation,
    depositPerMonth,
    handleViewProfile,
    name,
    showLiveTracking,
    showAutmationButton,
    showAutmationUserButton,
    showAutmationAdminButtons,
    showMarshallingAdminButtons,
    showLandBookerViewButton,
}) => {
    const history = useHistory();
    console.log(value?.paymentStatus)
    return (
        <div>
            <TableCell key={value?.id} style={{ display: 'flex', height: '80px', alignItems: 'center' }}>
                {showDetails && (
                    <Link
                        to={{
                            pathname: linkPathname,
                            search: `?${linkSerachParams}`,
                        }}
                        onClick={(e) => {
                            if (!value?.id) {
                                e.preventDefault(); // Prevent the link from navigating
                            }
                        }}
                        style={{ cursor: value?.id ? 'pointer' : 'default' }}
                    >
                        <Button variant="Success" disabled={!value?.id}>
                            Show Details
                        </Button>
                    </Link>
                )}
                {editRecord && name !== 'Supervisor Details' && (
                    <Button
                        variant="Danger"
                        // onClick={() => handleClickOpen(index, value)}
                        onClick={() => handleViewProfile(value)}
                        disabled={!value?.id}
                    >
                        View Profile
                    </Button>
                )}
                {/* {showAutmationButton && showAutmationUserButton ? (
                    <Button variant="Danger" onClick={() => {}} disabled={!value?.id}>
                        Generate PVC
                    </Button>
                ) : (
                    showAutmationUserButton && '--'
                )} */}
                {showAutmationAdminButtons && (
                    <>
                        <Button
                            variant="Success"
                            onClick={() => {
                                const url = `/payment-collection?id=${value?.id}`;
                                history.push(url);
                            }}
                            disabled={!value?.id}
                        >
                            <VisibilityIcon style={{ color: 'rgb(139 139 139)' }} />
                        </Button>
                        <Button
                            variant="Success"
                            onClick={() => {
                                const url = `/update/data-entry/${value?.id}`;
                                history.push(url);
                            }}
                            disabled={!value?.id}
                        >
                            <EditIcon style={{ color: 'rgb(139 139 139)' }} />
                        </Button>
                    </>
                )}

                {showAutmationAdminButtons || showAutmationUserButton
                    ? value?.paymentStatus !== 'paid' && (
                          <Button
                              variant="Success"
                              onClick={() => {
                                  const url = `/view-reciept/${value?.id}`;
                                  history.push(url);
                              }}
                              disabled={!value?.id}
                          >
                              <ReceiptLongIcon style={{ color: 'rgb(139 139 139)' }} />
                          </Button>
                      )
                    : null}


                {showLandBookerViewButton ? (
                    <>
                        {value?.paymentStatus !== 'paid' && (
                            <Button
                                variant="Success"
                                onClick={() => {
                                    const url = `/view-land-receipt/${value?.id}`;
                                    history.push(url);
                                }}
                                disabled={!value?.id}
                            >
                                <ReceiptLongIcon style={{ color: 'rgb(139 139 139)' }} />
                            </Button>
                        )}
                        <Button
                            variant="Success"
                            onClick={() => {
                                const url = `/update/land-booker/${value?.id}`;
                                history.push(url);
                            }}
                            disabled={!value?.id}
                        >
                            <EditIcon style={{ color: 'rgb(139 139 139)' }} />
                        </Button>
                    </>
                ) : null}
                {showMarshallingAdminButtons && (
                    <Button
                        variant="Success"
                        onClick={() => {
                            const url = `/update/entry-gate/${value?.id}`;
                            history.push(url);
                        }}
                        disabled={!value?.id}
                    >
                        <EditIcon style={{ color: 'rgb(139 139 139)' }} />
                    </Button>
                )}
                {showLiveTracking && (
                    <Button
                        variant="Success"
                        // onClick={() => handleClickOpen(index, value)}
                        onClick={() => handleViewLocation(value)}
                        disabled={!value?.id}
                    >
                        Show Live Location
                    </Button>
                )}
                {financeAction && (
                    <Button variant="Danger" onClick={() => depositPerMonth(value?.month)}>
                        <VisibilityIcon />
                    </Button>
                )}
            </TableCell>
        </div>
    );
};
