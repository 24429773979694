import React, { useState } from 'react';
import { useEffect } from 'react';
import { PDFViewer } from '@react-pdf/renderer';
import { axiosInstance } from 'app/helpers';
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import PvcCard from 'app/components/PvcCard/PvcCard';


const ViewPvc = () => {
    const { id } = useParams();

    const [data, setData] = useState({});

    useEffect(() => {
        (async () => {
            try {
                const { data } = await axiosInstance.get('/cattleMandi/get-automation', {
                    params: { id },
                });
                setData(data.data);
            } catch (error) {
                console.log(error);
            }
        })();
    }, []);

    return (
        <React.Fragment>
            {data !== null ? (
                <PDFViewer style={{ width: '100%', height: '100vh' }}>
                    <PvcCard data={data} id={id} />
                </PDFViewer>
            ) : (
                <h1 style={{ textAlign: 'center' }}>No Entry Found</h1>
            )}
        </React.Fragment>
    );
};

export default ViewPvc;
