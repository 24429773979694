import React, { useEffect, useState } from 'react';
import Heading from 'app/helpers/Heading';
import './style.scss';
import Input from 'app/components/Input/Input';
import ReuseableSelect from 'app/components/Select/Select';
import ButtonGroup from 'app/components/ButtonGroup/ButtonGroup';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Box, Button, Grid, IconButton } from '@material-ui/core';
import { listOfVehicals } from 'app/helpers';
import { axiosInstance, errorHandler } from 'app/helpers';
import { setAlert } from 'app/redux/actions/alertAction';
import { useDispatch, useSelector } from 'react-redux';
import { useParams,useHistory } from 'react-router-dom';
import DeleteIcon from '@material-ui/icons/Delete';
import Loader from 'app/components/loader/Loader';

const EntryGateMarshalling = () => {
    const { id } = useParams();
    const [isLoad, setIsLoad] = useState(false);
    const dispatch = useDispatch();
    const [isGettingLoad, setIsGettingLoad] = useState(false);
    const [totalCountVehicle, setTotalCountVehicle] = useState([]);
    const userData = useSelector((state) => state.getIn(['login', 'data']));
    const userRole = userData.get('role');
    const navigate = useHistory()
    // const totalCountVehicle = Array.from({ length: parseInt(formik.values.vehicleCount) }, (_, index) => index + 1);
    // console.log({ totalCountVehicle });

    const formik = useFormik({
        initialValues: {
            name: '',
            numberOfCows: 0,
            numberOfCamels: 0,
            numberOfGoats: 0,
            categoryOfVehicle: [],
            vehicles: [],
            vehicleCount: 0,
            NoOfVehicle: [],
            NicNo: '',
            addedVehicles: [],
            deletedVehicles: [],
        },
        validationSchema: Yup.object({
            name: Yup.string().required('required'),
            vehicles: Yup.array().required('required'),
            numberOfCows: Yup.string().required('required'),
            numberOfCamels: Yup.string().required('required'),
            numberOfGoats: Yup.string().required('required'),
            addedVehicles: Yup.array().of(
                Yup.object().shape({
                    vehicle_category_id: Yup.string().required('Category of Vehicle is required'),
                    vehicle_number: Yup.string().required('Number of Vehicle is required'),
                })
            ),
            // categoryOfVehicle: Yup.array()
            //     .min(1, 'required')
            //     .required(),
            NicNo: Yup.string().required('required'),
            vehicleCount: Yup.string().required('required'),
            // NoOfVehicle: Yup.array()
            //     .min(1, 'required')
            //     .required(),
        }),
        onSubmit: async (values) => {
            const categoryVehicleArray = totalCountVehicle.map((item, index) => {
                return {
                    id: formik.values.vehicles[index],
                    vehicle_category_id: formik.values.categoryOfVehicle[index] || '',
                    vehicle_number: formik.values.NoOfVehicle[index] || '',
                };
            });
            setIsLoad(true);
            try {
                const { data } = await axiosInstance.put('/cattleMandi/update-marshalling', {
                    id: id,
                    name_of_owner: values.name,
                    vehicles: values?.vehicles,
                    no_of_cows: values.numberOfCows,
                    no_of_camels: values.numberOfCamels,
                    no_of_goats: values.numberOfGoats,
                    vehicles: categoryVehicleArray,
                    cnic: values.NicNo,
                    deletedVehicles: values.deletedVehicles,
                    addedVehicles: values.addedVehicles,
                });

                // formik.setFieldValue('addedVehicles', []);
                setIsLoad(false);
                dispatch(
                    setAlert({
                        isOpen: true,
                        type: 'success',
                        message: data?.data?.message,
                    })
                );
                navigate.push('/marshalling-records')
                setTimeout(() => {
                    dispatch(setAlert({}));
                }, 5000);
            } catch (error) {
                dispatch(
                    setAlert({
                        isOpen: true,
                        type: 'error',
                        message: errorHandler(error),
                    })
                );
                setTimeout(() => {
                    dispatch(setAlert({}));
                }, 5000);
                setIsLoad(false);
            }
        },
    });

    // console.log(first)
    const formatNIC = (value) => {
        if (value && value.length === 13) {
            return `${value.substring(0, 5)}-${value.substring(5, 12)}-${value.charAt(12)}`;
        }
        return value;
    };
    const handleNumberKeyDown = (e) => {
        if (e.key === 'e' || e.key === '-') {
            e.preventDefault();
        }
    };

    const onDelete = (index) => {
        console.log({ index });
        const id = formik.values.vehicles[index];
        const deletedList = [...formik.values.deletedVehicles, id];

        formik.setFieldValue('deletedVehicles', deletedList);
        const updatedNoOfVehicleCount = [...totalCountVehicle];
        updatedNoOfVehicleCount.splice(index, 1);
        setTotalCountVehicle(updatedNoOfVehicleCount);

        const updatedNoOfVehicle = [...formik.values.NoOfVehicle];
        updatedNoOfVehicle.splice(index, 1);
        formik.setFieldValue('NoOfVehicle', updatedNoOfVehicle);

        const updatedNoOfVehicleCategory = [...formik.values.categoryOfVehicle];
        updatedNoOfVehicleCategory.splice(index, 1);
        formik.setFieldValue('categoryOfVehicle', updatedNoOfVehicleCategory);
    };

    const onAddDelete = (index) => {
        const addMoreList = formik.values.addedVehicles;
        const finalList = addMoreList.filter((item, i) => {
            return index !== i;
        });
        formik.setFieldValue('addedVehicles', finalList);
    };

    const handleAddMore = () => {
        const addedField = {
            vehicle_category_id: '',
            vehicle_number: '',
        };
        const updatedAddMoreList = [...formik.values.addedVehicles, addedField];
        formik.setFieldValue('addedVehicles', updatedAddMoreList);
    };

    useEffect(() => {
        console.log('a');
        setTotalCountVehicle(Array.from({ length: parseInt(formik.values.vehicleCount) }, (_, index) => index + 1));
    }, [formik.values.vehicleCount]);

    useEffect(() => {
        (async () => {
            setIsGettingLoad(true);
            try {
                const { data } = await axiosInstance.get('/cattleMandi/get-marshalling', { params: { id } });
                if (data) {
                    formik.setFieldValue('name', data?.data?.name_of_owner);
                    // formik.setFieldValue('categoryOfVehicle', data?.data?.kind_of_vehicle);
                    formik.setFieldValue('numberOfCows', data?.data?.no_of_cows);
                    formik.setFieldValue('numberOfCamels', data?.data?.no_of_camels);
                    formik.setFieldValue('numberOfGoats', data?.data?.no_of_goats);
                    formik.setFieldValue('NicNo', data?.data?.cnic);
                    formik.setFieldValue('vehicleCount', data?.data?.vehicle_count);
                    formik.setFieldValue(
                        'vehicles',
                        data?.data?.vehicles.map((item) => item?.id)
                    );
                    const vehicleCategories = data?.data?.vehicles.map((item) => item?.vehicle_category_id) || [];
                    formik.setFieldValue('categoryOfVehicle', vehicleCategories);
                    const NoOfVehicle = data?.data?.vehicles.map((item) => item?.vehicle_number) || [];
                    formik.setFieldValue('NoOfVehicle', NoOfVehicle);
                }
            } catch (error) {
                dispatch(
                    setAlert({
                        isOpen: true,
                        type: 'error',
                        message: errorHandler(error),
                    })
                );
                setTimeout(() => {
                    dispatch(setAlert({}));
                }, 5000);
                setIsLoad(false);
            } finally {
                setIsGettingLoad(false);
            }
        })();
    }, []);

    console.log('addedVehicles', formik.values.addedVehicles);

    return (
        <div className="DataEntry_Container">
            <Heading heading={'Entry Gate Marshalling'} />

            <div className="DataEntry_Content_Container">
                <div className="DataEntry_Content_card">
                    {isGettingLoad ? (
                        <Loader color="#000" height="45vh" />
                    ) : (
                        <form
                            onSubmit={(e) => {
                                e.preventDefault();
                                formik.submitForm();
                            }}
                        >
                            <div>
                                <Grid container spacing={3}>
                                    <Grid item xl={6} lg={6} md={6} xs={12} sm={12} className="TextInput_Main">
                                        <label>Name</label>
                                        <Input
                                            type="text"
                                            name="name"
                                            value={formik.values.name}
                                            onChange={formik.handleChange}
                                            className="TextInput"
                                            error={formik.touched.name && formik.errors.name}
                                            placeholder="Type Here"
                                        />
                                        {formik.touched.name && formik.errors.name && (
                                            <div className="error">{formik.errors.name}</div>
                                        )}
                                    </Grid>
                                    <Grid item xl={6} lg={6} md={6} xs={12} sm={12} className="TextInput_Main">
                                        <label>CNIC No.</label>
                                        <Input
                                            id="NicNo"
                                            type="text"
                                            inputProps={{
                                                inputMode: 'numeric',
                                            }}
                                            placeholder="Type Here"
                                            name="NicNo"
                                            value={formik.values.NicNo}
                                            onChange={formik.handleChange}
                                            className="TextInput"
                                            error={formik.touched.NicNo && formik.errors.NicNo}
                                        />
                                    </Grid>

                                    <Grid item xl={6} lg={6} md={6} xs={12} sm={12} className="TextInput_Main">
                                        <label>No. Of Cows</label>
                                        <Input
                                            type="number"
                                            name="numberOfCows"
                                            value={formik.values.numberOfCows}
                                            onChange={formik.handleChange}
                                            className="TextInput"
                                            error={formik.touched.numberOfCows && formik.errors.numberOfCows}
                                            placeholder="Type Here"
                                            onKeyDown={handleNumberKeyDown}
                                            inputProps={{
                                                inputMode: 'numeric',
                                            }}
                                        />

                                        {formik.touched.numberOfCows && formik.errors.numberOfCows && (
                                            <div className="error">{formik.errors.numberOfCows}</div>
                                        )}
                                    </Grid>

                                    <Grid item xl={6} lg={6} md={6} xs={12} sm={12} className="TextInput_Main">
                                        <label>No. Of Camels</label>
                                        <Input
                                            type="number"
                                            name="numberOfCamels"
                                            value={formik.values.numberOfCamels}
                                            onChange={formik.handleChange}
                                            className="TextInput"
                                            error={formik.touched.numberOfCamels && formik.errors.numberOfCamels}
                                            placeholder="Type Here"
                                            onKeyDown={handleNumberKeyDown}
                                            inputProps={{
                                                inputMode: 'numeric',
                                            }}
                                        />
                                        {formik.touched.numberOfCamels && formik.errors.numberOfCamels && (
                                            <div className="error">{formik.errors.numberOfCamels}</div>
                                        )}
                                    </Grid>

                                    <Grid item xl={6} lg={6} md={4} xs={12} sm={12} className="TextInput_Main">
                                        <label>Vehicle Count</label>
                                        <Input
                                            type="number"
                                            name="vehicleCount"
                                            value={totalCountVehicle.length + formik.values.addedVehicles.length}
                                            className="TextInput"
                                            placeholder="Type Here"
                                            disabled={true}
                                            onKeyDown={handleNumberKeyDown}
                                            inputProps={{
                                                inputMode: 'numeric',
                                            }}
                                        />
                                    </Grid>

                                    <Grid item xl={6} lg={6} md={6} xs={12} sm={12} className="TextInput_Main">
                                        <label>No. Of Goats</label>
                                        <Input
                                            type="number"
                                            name="numberOfGoats"
                                            value={formik.values.numberOfGoats}
                                            onChange={formik.handleChange}
                                            className="TextInput"
                                            error={formik.touched.numberOfGoats && formik.errors.numberOfGoats}
                                            placeholder="Type Here"
                                            onKeyDown={handleNumberKeyDown}
                                            inputProps={{
                                                inputMode: 'numeric',
                                            }}
                                        />
                                        {formik.touched.numberOfGoats && formik.errors.numberOfGoats && (
                                            <div className="error">{formik.errors.numberOfGoats}</div>
                                        )}
                                    </Grid>

                                    {totalCountVehicle.map((item, index) => {
                                        return (
                                            <>
                                                <Grid
                                                    key={index}
                                                    item
                                                    xl={6}
                                                    lg={6}
                                                    md={4}
                                                    xs={12}
                                                    sm={12}
                                                    className="TextInput_Main"
                                                >
                                                    <label>Category of Vehicle</label>
                                                    <ReuseableSelect
                                                        key={index}
                                                        style={{ width: '100%' }}
                                                        name="categoryOfVehicle"
                                                        formControlClassName="demo-customized-select"
                                                        className={`TextInput select_error`}
                                                        // formik={formik}
                                                        id={index}
                                                        disabled={userRole === 'marshalling'}
                                                        value={formik.values.categoryOfVehicle[index]}
                                                        onChange={(e) => {
                                                            const newValue = e.target.value;
                                                            const newCategoryOfVehicle = [
                                                                ...formik.values.categoryOfVehicle,
                                                            ];
                                                            newCategoryOfVehicle[index] = newValue;
                                                            console.log({ newCategoryOfVehicle });
                                                            formik.setFieldValue(
                                                                'categoryOfVehicle',
                                                                newCategoryOfVehicle
                                                            );
                                                        }}
                                                        error={
                                                            formik.touched.categoryOfVehicle &&
                                                            formik.errors.categoryOfVehicle
                                                        }
                                                        optionList={listOfVehicals}
                                                    />
                                                    {formik.touched.categoryOfVehicle &&
                                                        formik.errors.categoryOfVehicle && (
                                                            <div className="error">
                                                                {formik.errors.categoryOfVehicle}
                                                            </div>
                                                        )}
                                                </Grid>

                                                <Grid
                                                    key={index}
                                                    item
                                                    xl={6}
                                                    lg={6}
                                                    md={4}
                                                    xs={12}
                                                    sm={12}
                                                    className="TextInput_Main"
                                                >
                                                    <label>No. Of Vehicle</label>
                                                    <Box alignItems="center" display="flex" gridGap={9}>
                                                        <Input
                                                            type="text"
                                                            key={index}
                                                            id={index}
                                                            name="NoOfVehicle"
                                                            value={formik.values.NoOfVehicle[index]}
                                                            onChange={(e) => {
                                                                const newValue = e.target.value;
                                                                const currentIndex = index;
                                                                let newNoOfVehicle = formik.values.NoOfVehicle || [];
                                                                newNoOfVehicle[currentIndex] = newValue;
                                                                console.log({ newNoOfVehicle });
                                                                formik.setFieldValue('NoOfVehicle', newNoOfVehicle);
                                                            }}
                                                            disabled={userRole === 'marshalling'}
                                                            className="TextInput"
                                                            error={
                                                                formik.touched.NoOfVehicle && formik.errors.NoOfVehicle
                                                            }
                                                            placeholder="Type Here"
                                                            onKeyDown={handleNumberKeyDown}
                                                            inputProps={{
                                                                inputMode: 'numeric',
                                                            }}
                                                        />
                                                        <IconButton onClick={() => onDelete(index)}>
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </Box>

                                                    {formik.touched.NoOfVehicle && formik.errors.NoOfVehicle && (
                                                        <div className="error">{formik.errors.NoOfVehicle}</div>
                                                    )}
                                                </Grid>
                                            </>
                                        );
                                    })}

                                    {formik.values.addedVehicles.map((item, index) => {
                                        return (
                                            <>
                                                <Grid
                                                    key={index}
                                                    item
                                                    xl={6}
                                                    lg={6}
                                                    md={4}
                                                    xs={12}
                                                    sm={12}
                                                    className="TextInput_Main"
                                                >
                                                    <label>Category of Vehicle</label>
                                                    <ReuseableSelect
                                                        key={index}
                                                        style={{ width: '100%' }}
                                                        name=""
                                                        formControlClassName="demo-customized-select"
                                                        className={`TextInput select_error`}
                                                        // formik={formik}
                                                        id={index}
                                                        disabled={userRole === 'marshalling'}
                                                        value={
                                                            formik.values.addedVehicles[index]?.vehicle_category_id ||
                                                            ''
                                                        }
                                                        error={
                                                            formik.touched.addedVehicles?.[index]
                                                                ?.vehicle_category_id &&
                                                            formik.errors.addedVehicles?.[index]?.vehicle_category_id
                                                        }
                                                        onChange={(e) => {
                                                            const newValue = e.target.value;
                                                            const updatedAddedVehicles = [
                                                                ...formik.values.addedVehicles,
                                                            ];
                                                            updatedAddedVehicles[index] = {
                                                                ...updatedAddedVehicles[index],
                                                                vehicle_category_id: newValue,
                                                            };
                                                            formik.setFieldValue('addedVehicles', updatedAddedVehicles);
                                                        }}
                                                        optionList={listOfVehicals}
                                                    />
                                                    {formik.touched.addedVehicles?.[index]?.vehicle_category_id &&
                                                        formik.errors.addedVehicles?.[index]?.vehicle_category_id && (
                                                            <div className="error">
                                                                {
                                                                    formik.errors.addedVehicles[index]
                                                                        ?.vehicle_category_id
                                                                }
                                                            </div>
                                                        )}
                                                </Grid>
                                                <Grid
                                                    key={index}
                                                    item
                                                    xl={6}
                                                    lg={6}
                                                    md={4}
                                                    xs={12}
                                                    sm={12}
                                                    className="TextInput_Main"
                                                >
                                                    <label>No. Of Vehicle</label>
                                                    <Box alignItems="center" display="flex" gridGap={9}>
                                                        <Input
                                                            type="text"
                                                            key={index}
                                                            id={index}
                                                            name=""
                                                            value={
                                                                formik.values.addedVehicles[index]?.vehicle_number || ''
                                                            }
                                                            onChange={(e) => {
                                                                const newValue = e.target.value;
                                                                const updatedAddedVehicles = [
                                                                    ...formik.values.addedVehicles,
                                                                ];
                                                                updatedAddedVehicles[index] = {
                                                                    ...updatedAddedVehicles[index],
                                                                    vehicle_number: newValue,
                                                                };
                                                                formik.setFieldValue(
                                                                    'addedVehicles',
                                                                    updatedAddedVehicles
                                                                );
                                                            }}
                                                            disabled={userRole === 'marshalling'}
                                                            className="TextInput"
                                                            error={
                                                                formik.touched.addedVehicles?.[index]?.vehicle_number &&
                                                                formik.errors.addedVehicles?.[index]?.vehicle_number
                                                            }
                                                            placeholder="Type Here"
                                                            onKeyDown={handleNumberKeyDown}
                                                            inputProps={{
                                                                inputMode: 'numeric',
                                                            }}
                                                        />
                                                        <IconButton onClick={() => onAddDelete(index)}>
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </Box>
                                                    {formik.touched.addedVehicles?.[index]?.vehicle_number &&
                                                        formik.errors.addedVehicles?.[index]?.vehicle_number && (
                                                            <div className="error">
                                                                {formik.errors.addedVehicles[index]?.vehicle_number}
                                                            </div>
                                                        )}
                                                </Grid>
                                            </>
                                        );
                                    })}

                                    <Box width="100%" display="flex" alignItems="center" justifyContent="center">
                                        <ButtonGroup variant="contained" value="Add More" onClick={handleAddMore} />
                                    </Box>

                                    <Grid item md={12} xs={12} sm={12} className="TextInput_Main">
                                        <div className="submit-btn">
                                            <ButtonGroup
                                                isLoad={isLoad}
                                                disabled={isLoad}
                                                value="Update"
                                                type="submit"
                                                variant="contained"
                                                customBtnBGColor="#7904d7"
                                                textColor="#fff"
                                            />
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                        </form>
                    )}
                </div>
            </div>
        </div>
    );
};

export default EntryGateMarshalling;
