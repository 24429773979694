import { fromJS } from 'immutable';
import { ALERT, REMOVE_ALERT, LOGOUT } from '../actions/actionTypes';

const initialState = {
    alerts: {
        isOpen: false,
        type: '',
        message: '',
    },
};

const initialImmutableState = fromJS(initialState);
export default function reducer(state = initialImmutableState, action = {}) {
    switch (action.type) {
        case ALERT:
            return state.withMutations((mutableState) => {
                mutableState.set('alerts', action.payload);
            });

        case REMOVE_ALERT:
            return state.withMutations((mutableState) => {
                mutableState.set('alerts', initialState.alerts);
            });

        case LOGOUT:
            return state.withMutations((mutableState) => {
                mutableState.set('alerts', initialState.alerts);
            });

        default:
            return state;
    }
}
