import { LOGIN, LOGOUT } from './actionTypes';

export const LoginAction = (data) => {
    return {
        type: LOGIN,
        payload: data,
    };
};

export const logout = (data) => {
    return {
        type: LOGOUT,
        payload: data,
    };
};
