export const convertDate = (date) => {
    try {
        const updated = new Date(date);
        return updated?.toISOString()?.split('T')[0];
    } catch (error) {
        console.log(date);
        console.log(error);
    }
    // return date;
};

export const reversedAndFormatedDate = (value) => {
    const dateStr = new Date(value)?.toISOString()?.split('T')[0];
    if (!dateStr) return null;
    const [year, month, day] = dateStr.split('-');
    return `${day}-${month}-${year}`;
};
