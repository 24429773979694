import React, { useState } from 'react';
import { Checkbox, IconButton, TableCell, TableRow } from '@material-ui/core';
import { Actions } from './Actions';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import AudioController from '../audioController/AudioController';

const RenderTableRow = ({
    index,
    selected,
    showLiveTracking,
    isNotShowCheck,
    // Checkbox,
    value,
    handleCheck,
    name,
    setIsNotShowCheck,
    editUserIndex,
    renderCell,
    supervisorSearchParam,
    link,
    handleClickOpen,
    showDetails,
    editRecord,
    financeAction,
    actionCell,
    handleViewProfile,
    depositPerMonth,
    handleViewLocation,
    checkBoxHandler,
    classes,
    renderCollapse,
    CheckBoxShow,
    checkBox,
    renderCollapsibleTableCell,
    showAutmationButton,
    showAutmationAdminButtons,
    showAutmationUserButton,
    showMarshallingAdminButtons,
    showLandBookerViewButton,
    showCollapse,
}) => {
    const [isOpen, setIsOpen] = useState({ open: false, value: null });

    return (
        <>
            <TableRow
                hover
                key={index?.toString()}
                selected={selected?.includes(value)}
                onClick={(e) => {
                    if (!isNotShowCheck && checkBox) {
                        value?.id && handleCheck(e, value);
                    }
                }}
            >
                <>
                    {!showCollapse && (
                        <TableCell>
                            <IconButton
                                key={index}
                                aria-label="expand row"
                                size="small"
                                className={classes.check}
                                onMouseEnter={() => setIsNotShowCheck(true)}
                                onMouseLeave={() => setIsNotShowCheck(false)}
                                onClick={(e) => setIsOpen({ open: !isOpen?.open, value })}
                            >
                                {isOpen?.open && isOpen?.value?.id === value?.id ? (
                                    <KeyboardArrowUpIcon />
                                ) : (
                                    <KeyboardArrowDownIcon
                                        style={{
                                            display:
                                                !!value?.deals?.length ||
                                                !!value?.vehicles?.length ||
                                                !!value?.lands?.length
                                                    ? 'block'
                                                    : 'none',
                                        }}
                                    />
                                )}
                            </IconButton>
                        </TableCell>
                    )}
                    {name === 'DDS' && checkBox && value?.id && (
                        <TableCell>
                            <Checkbox
                                className={classes.check}
                                color="primary"
                                // style={{
                                //     marginLeft: !!value.deals?.length ? '0px' : '20px',
                                // }}
                                onClick={() => checkBoxHandler(value)}
                                checked={selected?.includes(value)}
                                inputProps={{
                                    'aria-labelledby': `enhanced-table-checkbox-${editUserIndex}`,
                                }}
                            />
                        </TableCell>
                    )}
                </>

                {name === 'DDS' ? (
                    <>
                        <TableCell></TableCell>
                        {renderCollapsibleTableCell(value)}
                    </>
                ) : (
                    renderCell(value)
                )}
                {actionCell && (
                    <Actions
                        value={value}
                        linkPathname={link}
                        linkSerachParams={supervisorSearchParam(value?.id, value?.activity_id, value?.name)}
                        handleClickOpen={handleClickOpen}
                        index={index}
                        showDetails={showDetails}
                        editRecord={editRecord}
                        financeAction={financeAction}
                        depositPerMonth={depositPerMonth}
                        handleViewProfile={handleViewProfile}
                        handleViewLocation={handleViewLocation}
                        showLiveTracking={name === 'BA Users'}
                        showAutmationButton={showAutmationButton}
                        showAutmationUserButton={showAutmationUserButton}
                        showAutmationAdminButtons={showAutmationAdminButtons}
                        showMarshallingAdminButtons={showMarshallingAdminButtons}
                        showLandBookerViewButton={showLandBookerViewButton}
                    />
                )}
            </TableRow>
            {renderCollapse ? renderCollapse(value, isOpen) : null}
        </>
    );
};

export default RenderTableRow;
