const roleWise = localStorage.getItem('role');

export const cattleMandiDashboardTableHead = [
    {
        id: 1,
        name: 'ID',
    },
    {
        id: 2,
        name: 'NAME',
    },
    {
        id: 3,
        name: 'PICTURE',
    },
    {
        id: 4,
        name: 'CONTACT NO.',
    },
    {
        id: 5,
        name: 'ANIMAL KIND',
    },
    {
        id: 6,
        name: 'NO. OF ANIMALS',
    },
    {
        id: 7,
        name: 'DRUM REQUIRED',
    },
];

export const cattleMandiMarshallingTableHead = [
    {
        id: 1,
        name: '',
    },
    {
        id: 2,
        name: 'ID',
    },
    {
        id: 3,
        name: 'Name',
    },
    {
        id: 4,
        name: 'CNIC.No',
    },
    {
        id: 5,
        name: 'Date',
    },
    {
        id: 6,
        name: 'No Of Camels',
    },
    {
        id: 7,
        name: 'No Of Cows',
    },
    {
        id: 8,
        name: 'No Of Goats',
    },
    roleWise === 'cattleAdmin' && {
        id: 9,
        name: 'Actions',
    },
];

export const cattleMandiLandBookerTableHead = [
    {
        id: 1,
        name: '',
    },
    {
        id: 2,
        name: 'ID',
    },
    {
        id: 3,
        name: 'Name',
    },
    {
        id: 4,
        name: 'Phone Number',
    },
    {
        id: 5,
        name: 'N.I.C Number',
    },
    {
        id: 6,
        name: 'Date',
    },
    {
        id: 7,
        name: 'Payment Status',
    },
    {
        id: 8,
        name: 'Actions',
    },
];

export const cattleMandiAutomationTableHead = [
    {
        id: 1,
        name: '',
    },
    {
        id: 2,
        name: 'ID',
    },
    {
        id: 3,
        name: 'Name',
    },
    {
        id: 4,
        name: 'Contact',
    },
    {
        id: 5,
        name: 'N.I.C',
    },
    {
        id: 6,
        name: 'Date',
    },
    {
        id: 7,
        name: 'Land ID',
    },
    {
        id: 8,
        name: 'No Of Goats',
    },
    {
        id: 9,
        name: 'No Of Cows',
    },
    {
        id: 10,
        name: 'No Of Camels',
    },
    {
        id: 11,
        name: 'Payment Status',
    },
    {
        id: 12,
        name: 'Actions',
    },
];

export const cattleMandiBankTableHead = [
    {
        id: 1,
        name: 'ID',
    },
    {
        id: 2,
        name: 'Name',
    },
    {
        id: 3,
        name: 'Contact',
    },
    {
        id: 4,
        name: 'N.I.C',
    },
    {
        id: 5,
        name: 'Date',
    },
    {
        id: 6,
        name: 'Payment Status',
    },
];
